import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { doc, serverTimestamp, getDoc, updateDoc } from 'firebase/firestore';
import JobTabsDetail from './JobTabsDetail';
import Comments from '../Comments/Comments';
import QuoteForm from '../QuoteForm/QuoteForm';
import PropertyDetail from '../PropertyInformation/PropertyDetail';
import PropertyDetailsPanel from '../PropertyDetailsPanel/PropertyDetailsPanel'; 
import LandlordAppointmentForm from '../AppointmentForm/LandlordAppointmentForm';
import { fetchPropertyById, updateJobStatus, fetchUserByEmail } from '../services/dbService';
import { assignJobToContractor, createNotification } from "../services/dbService";
import { markTabAsViewed } from "../services/dbService";
import { notifyLandlord, fetchContractorQuoteIds, renameAndStoreChosenQuote, createStandardQuoteFromAccepted, updateJobWithAcceptedQuoteDetails } from '../services/quoteService';
import { SystemCommenter} from '../Shared/SystemCommenter'
import '../styles/JobTabs.css';

const JobTabsLandlord = ({ jobData, onReturnToDashboard, tab, tabModes, onReturnToNotification}) => {

  const [propertyDetails, setPropertyDetails] = useState('');
  const [isProvidingFeedback, setIsProvidingFeedback] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(tab || "Detail");
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [remainingChars, setRemainingChars] = useState(4000);
  const [contractorQuoteIds, setContractorQuoteIds] = useState([]);
  const [unreadTabs, setUnreadTabs] = useState([]);
  const [activeContractorQuoteId, setActiveContractorQuoteId] = useState(null);
  const [feedbackScreenMessage, setFeedbackScreenMessage] = useState('');
  const [isActiveQuoteRejected, setIsActiveQuoteRejected] = useState(false);
  const visibleTabs = Object.keys(tabModes).filter(key => tabModes[key] !== 'hide');

  // Loads property light and sets using setPropertyDetails
  useEffect(() => {
    const loadProperty = async () => {
      try {
        const propertyData = await fetchPropertyById(jobData.propertyId);
        if (propertyData) {
          setPropertyDetails(propertyData);
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    loadProperty();
  }, [jobData.propertyId]);
  
  useEffect(() => { // Loads the users details
    const loadUserData = async () => {
      try {
        const fetchedUser = await fetchUserByEmail(auth.currentUser.email);
        if (fetchedUser) {
          setUserDetails(fetchedUser);
  
          // Update unread tabs based on fresh user data
          const updatedUnreadTabs = [];
          if (fetchedUser.quoteNotif) updatedUnreadTabs.push("Quote");
          if (fetchedUser.commentNotif) updatedUnreadTabs.push("Comments");
          setUnreadTabs(updatedUnreadTabs);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    loadUserData();
  }, []);
  
  useEffect(() => { // Store the contractor quote Id's
    if (contractorQuoteIds.length > 0 && activeTab?.startsWith("Quote")) {
      const index = parseInt(activeTab.replace("Quote ", "")) - 1;
      setActiveContractorQuoteId(contractorQuoteIds[index] || null);
    } else {
      setActiveContractorQuoteId(null);
    }
  }, [activeTab, contractorQuoteIds]);

  useEffect(() => { // Unread Tabs
    if (!userDetails || unreadTabs.length === 0) return;

    if (unreadTabs.includes(activeTab)) {
      // Mark as viewed immediately in Firestore
      if (activeTab === "Quote" && userDetails.quoteNotif) {
        markTabAsViewed(userDetails.email, "quoteNotif");
      }
      if (activeTab === "Comments" && userDetails.commentNotif) {
        markTabAsViewed(userDetails.email, "commentNotif");
      }

      // Delay the UI update to remove the dot after 1 second
      const timeout = setTimeout(() => {
        setUnreadTabs((prev) => prev.filter((t) => t !== activeTab));
      }, 1000);

      return () => clearTimeout(timeout); // Cleanup in case of quick unmount
    }
  }, [activeTab, userDetails, unreadTabs]);

  useEffect(() => { // Load Contractor Quotes
    const loadContractorQuotes = async () => {
      if (jobData.jobId) {
        const quoteIds = await fetchContractorQuoteIds(jobData.jobId);
        setContractorQuoteIds(quoteIds);
      }
    };
  
    loadContractorQuotes();
  }, [jobData.jobId]);

  // Function to handle tab selection
  const handleTabClick = (tabName) => {
    setActiveTab(tabName || 'Detail');

    // If the tab has an unread flag, update Firestore & remove from `unreadTabs`
    if (unreadTabs.includes(tabName)) {
      setUnreadTabs((prev) => prev.filter((t) => t !== tabName)); // Remove the dot immediately

      if (userDetails) {
        if (tabName === "Quote" && userDetails.quoteNotif) {
          markTabAsViewed(userDetails.email, "quoteNotif", jobData.jobId);
        }
        if (tabName === "Comments" && userDetails.commentNotif) {
          markTabAsViewed(userDetails.email, "commentNotif", jobData.jobId);
        }
      }      
    }
  };
    
  const handleQuoteDetailsChange = (e) => {
      const text = e.target.value;
      setFeedbackDetails(text);
      setRemainingChars(4000 - text.length);
  };
    
  const handleReturn = () => {
    if (onReturnToNotification) {
      onReturnToNotification();
    } else {
      onReturnToDashboard();
    }
  };
  
  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        companyCity: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  const handleAcceptQuote = async () => {
    try {
      // Step 1: Is this a contractor-provided quote?
      if (contractorQuoteIds.length > 0) {
        try {
          const quoteIndex = contractorQuoteTabs.findIndex(tab => tab.name === activeTab);
          if (quoteIndex !== -1) {
            const selectedQuoteId = contractorQuoteIds[quoteIndex];
            // console.log('selectedQuoteId: ' + selectedQuoteId);
            // Step 2: Rename the existing contractor quote doc and store chosenQuoteId
            await renameAndStoreChosenQuote(jobData.jobId, selectedQuoteId);
            // console.log(`Contractor quote ${selectedQuoteId} renamed and stored.`);
      
            // Step 3: Create a standard quote doc (M1020-4)
            await createStandardQuoteFromAccepted(jobData.jobId, selectedQuoteId);
            // console.log(`Standard quote created for job ${jobData.jobId}`);
      
            // Step 4: Fetch contractor details from the selected quote (not M1020-4)
            const selectedQuoteRef = doc(db, "quotes", selectedQuoteId);
            const selectedQuoteSnap = await getDoc(selectedQuoteRef);
      
            if (!selectedQuoteSnap.exists()) {
              throw new Error(`Selected contractor quote ${selectedQuoteId} not found.`);
            }
      
            const selectedQuoteData = selectedQuoteSnap.data();
            if (!selectedQuoteData.contractorId || !selectedQuoteData.companyName) {
              throw new Error(`Missing contractor details in selected quote ${selectedQuoteId}`);
            }
      
            // console.log(`Assigning job to contractor: ${selectedQuoteData.companyName}, ${selectedQuoteData.contractorId}`);
      
            // Step 5: Assign the job to the contractor
            await assignJobToContractor({
              jobId: jobData.jobId,
              contractorName: selectedQuoteData.companyName,
              contractorEmail: selectedQuoteData.contractorId,
            });
      
            // Step 6: Update job record with financial details
            await updateJobWithAcceptedQuoteDetails(jobData.jobId, selectedQuoteId);

            debugger;

            // Step 7: Give screen feedback
            setFeedbackScreenMessage(`The contractors has been informed the quote has been accepted. Thank you!`);
            setTimeout(() => {
              onReturnToDashboard(); 
            }, 3000);
            return;
          }
        } catch (error) {
          console.error("Error processing contractor quote acceptance:", error);
          alert(`Error processing contractor quote: ${error.message}`);
          onReturnToDashboard(); 
          return; 
        }
      }
      
      // Step 2: Update job status to '2' (Accepted) after payment is confirmed
      await updateJobStatus(jobData.jobId, 2); // Status '2' represents an accepted quote.

      // Step 3: Notify the landlord or perform any other relevant actions
      notifyLandlord(jobData.landlordEmail, `Quote for Job ${jobData.jobId} has been accepted.`);
  
      // Step 4: Add system comment
      await SystemCommenter(
        jobData.jobId, 
        'Admin', 
        'The quote has been accepted for this job.'
      );

      // Fetch property details
      const propertyDetails = await fetchPropertyDetails(jobData.propertyId);
      const customMessage = `A quote has been accepted by ${jobData.landlordEmail} for ${jobData.jobId}`;
      const notifAddress = `${propertyDetails.address} ${propertyDetails.companyCity} ${propertyDetails.postcode}`; 
      await createNotification(
        jobData.jobId, 
        "quote_accepted", 
        {
          admin: true, 
          agent: jobData.agentId,
        }, 
        notifAddress, 
        "Quote", 
        customMessage
      );
        
      // Step 5: Provide feedback and close the form
      setFeedbackScreenMessage(`The contractors has been informed the quote has been accepted. Thank you!`);
      setTimeout(() => {
        onReturnToDashboard(); 
      }, 3000);
      return;
 
    } catch (error) {
      console.error('Error accepting the quote:', error);
      alert('Error accepting the quote: ' + error);
    }
  };

  const landlordData = {
    landlordFirstname: jobData.landlordFirstName,
    landlordLastname: jobData.landlordLastName,
    landlordEmail: jobData.landlordEmail,
  };
  
  // Contractor quotes building tabs
  const contractorQuoteTabs = contractorQuoteIds.map((id, index) => ({
    name: `Quote ${index + 1}`,
    component: <QuoteForm jobData={jobData} quoteId={id} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} setIsActiveQuoteRejected={setIsActiveQuoteRejected} quoteNumber={index + 1} />,
  }));
  
  const tabs = [
    { name: 'Detail', component: <JobTabsDetail jobId={jobData.jobId} readOnly={tabModes?.Detail === 'readOnly'} /> },  
    ...(contractorQuoteIds.length > 0 
      ? contractorQuoteTabs // If contractor quotes exist, add them dynamically
      : [{ name: 'Quote', component: <QuoteForm jobData={jobData} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} landlord/> }]
    ),
    { name: 'Comments', component: <Comments jobData={jobData} propertyDetails={propertyDetails} isActive={activeTab === 'Comments'} readOnly={tabModes?.Comments === 'readOnly'} /> },
    { name: 'Appointments', component: <LandlordAppointmentForm jobId={jobData.jobId} readOnly={tabModes?.Appointments === 'readOnly'} landlordData={landlordData} onReturnToDashboard={onReturnToDashboard} propertyId={propertyDetails.propertyId} /> },
    { name: 'Property', component: <PropertyDetail propertyId={jobData.propertyId} readOnly={tabModes?.Property === 'readOnly'} /> },
  ].filter(tab => visibleTabs.includes(tab.name) || contractorQuoteIds.some((_, index) => tab.name === `Quote ${index + 1}`));
  
  const handleLandlordMarkJobComplete = async () => {
    try {
      await updateJobStatus(jobData.jobId, 7);

      // TODO CREATE EMAILS

      // Create Site Notifications
      const customMessage = `Landlord ${jobData.landlordEmail} has marked the job ${jobData.jobId} as signed off complete`;
      const notifAddress = `${propertyDetails.address} ${propertyDetails.companyCity} ${propertyDetails.postcode}`; 
      await createNotification(
        jobData.jobId, 
        "landlord_marked_signedOff", 
        {
          admin: true,
          contractor: jobData.contractorEmail,
          agent: jobData.agentId,
        },
        notifAddress,
        null,
        customMessage,
      );

      // Add Comment
      await SystemCommenter(
        jobData.jobId, 
        'Landlord', 
        'The work for the job is signed off as complete.'
      );

      // Provide feedback and close the form
      setFeedbackScreenMessage(`The work for the job is signed off as complete.`);
      setTimeout(() => {
        onReturnToDashboard(); 
      }, 3000);
      return;

    } catch (error) {
      console.error('Error landlord setting job as complete:', error);
      alert('Failed Landlord to mark job as completed and signed off.' + error);
    }
  };
  
  const handleRejectQuote = async () => {
    // Open the feedback panel
    setIsProvidingFeedback(true);
  };

  const returnToQuote = async () => {
    // Logic to return to the quote view
    // console.log('Returning to quote...');
    setRemainingChars(4000);
    setFeedbackDetails('');
    setIsProvidingFeedback(false);
  };
  
  const ratherNotSay = async () => {
    const defaultMessage = 'The landlord chose not to leave any comments';
  
    if (contractorQuoteIds.length > 0 && activeContractorQuoteId) {
      await updateContractorQuoteToRejected(activeContractorQuoteId, defaultMessage);
      setFeedbackScreenMessage(`The contractor has been informed the quote has not been accepted.`);
      setTimeout(() => {
        setFeedbackScreenMessage('');
      }, 3000);
    } else {
      await updateQuoteWithFeedback(jobData.jobId, defaultMessage);
      await updateJobStatus(jobData.jobId, -1);  
      setFeedbackScreenMessage(`The quote has been set to not accepted.`);
      setTimeout(() => {
        setFeedbackScreenMessage('');
        onReturnToDashboard();
      }, 3000);
    }
  };
  
  const submitFeedback = async () => {
    try {
      if (contractorQuoteIds.length > 0 && activeContractorQuoteId) {
        await updateContractorQuoteToRejected(activeContractorQuoteId, feedbackDetails);
        setFeedbackScreenMessage("Thanks for providing your feedback.");
        setTimeout(() => {
          setFeedbackScreenMessage('');
        }, 3000);
      } else {
        await updateQuoteWithFeedback(jobData.jobId, feedbackDetails);
        await updateJobStatus(jobData.jobId, -1);  
        setFeedbackScreenMessage("Thanks for providing your feedback.");
        setTimeout(() => {
          setFeedbackScreenMessage('');
          onReturnToDashboard();
        }, 3000);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Error: Failed to submit feedback. Please try again.');
    }
  };
  
  return (
    <div className="job-tabs-container">

      <PropertyDetailsPanel propertyDetails={propertyDetails} />

      {/* NAVIGATION BUTTONS */}
      {isProvidingFeedback && (
      <div className='rejected-quote-feedback-panel'>
        <div className="feedback-panel">
          <h2>Please provide a reason why you are rejecting this quote</h2>
          <textarea id="feedback" rows="4" placeholder="Enter your feedback here..." onChange={handleQuoteDetailsChange}></textarea>
          <div className="textareaInfo"><small>{remainingChars} characters remaining</small></div>
          <div className="button-row">
            <button type="button" onClick={() => returnToQuote()}>Return to Quote</button>
            <button type="button" onClick={() => ratherNotSay()}>I'd Rather Not Say</button>
            <button type="button" onClick={() => submitFeedback()}>Submit Feedback</button>
          </div>
        </div>
      </div>
      )}

    {feedbackScreenMessage && (
      <div className='feedback-thanks'>
        <p>{feedbackScreenMessage}</p>
      </div>
      )}

      <div className="job-tabs-button-group">
        <div className="tabs-left">
          {tabs.map(({ name }) => (
            <button key={name} onClick={() => handleTabClick(name)} className={`job-tabs-button ${activeTab === name ? 'job-tabs-button-active' : ''}`}>
              {name}
              {unreadTabs.includes(name) && <span className="unread-dot"></span>}
            </button>
          ))}
        </div>

        <div className="buttons-right">
          <>
          {(jobData.status === 0 || jobData.status === 1) && !isProvidingFeedback &&  !isActiveQuoteRejected && (            
            <>
              <button className="negative-button" onClick={handleRejectQuote}  disabled={contractorQuoteIds.length > 0 && !activeContractorQuoteId} >Reject Quote</button>
              <button className="positive-button" onClick={handleAcceptQuote}  disabled={contractorQuoteIds.length > 0 && !activeContractorQuoteId} >Accept Quote and Pay</button>
            </>
            )}

            {jobData.status === 6 && (
              <button className="negative-button" onClick={handleLandlordMarkJobComplete} style={{ marginLeft: 'auto' }}>Mark Job As Signed Off Complete</button>
            )}

              <button className="negative-button" onClick={handleReturn} style={{ marginLeft: 'auto' }}>
                {onReturnToNotification ? "Return to Notifications" : "Return to Dashboard"}
              </button>
          </>
        </div>
      </div>

      {/* Tab Content */}
      <div className="job-tabs-content">
        {tabs.find(({ name }) => name === activeTab)?.component || <p>Invalid tab selected: {activeTab}</p>}
      </div>
    </div>
  );
};

export default JobTabsLandlord;



export const updateContractorQuoteWithFeedback = async (quoteId, feedbackDetails) => {
  if (!quoteId) throw new Error('Missing quoteId for contractor feedback');

  const quoteRef = doc(db, 'quotes', quoteId);

  await updateDoc(quoteRef, {
    feedbackDetails,
    feedbackDate: serverTimestamp(),
    status: -1,
  });

  const updatesRef = doc(db, "updates", "admin");
  await updateDoc(updatesRef, { lastUpdated: Date.now() });
};


/**
 * Updates a quote document with feedback details and rejection status.
 *
 * @param {string} jobId - The unique identifier for the job/quote.
 * @param {string} feedbackDetails - The feedback provided by the landlord.
 * @returns {Promise<void>} A promise that resolves when the update is complete.
 * @throws Will throw an error if the update operation fails.
 */
export const updateQuoteWithFeedback = async (jobId, feedbackDetails) => {
  if (!jobId) {
    throw new Error('Job ID is required to update the quote.');
  }

  const quoteRef = doc(db, 'quotes', jobId);

  await updateDoc(quoteRef, {
    feedbackDetails: feedbackDetails,
    feedbackDate: serverTimestamp(),
    status: -1, // Assuming -1 indicates a rejected status
  });

  // 🔹 Update admin broadcast
  const updatesRef = doc(db, "updates", "admin");
  await updateDoc(updatesRef, { lastUpdated: Date.now() });

};

export const updateContractorQuoteToRejected = async (quoteId, feedbackDetails) => {
  if (!quoteId) throw new Error("Quote ID is required");

  const quoteRef = doc(db, 'quotes', quoteId);

  await updateDoc(quoteRef, {
    feedbackDetails: feedbackDetails || '',
    feedbackDate: serverTimestamp(),
    isRejected: true // ✅ Custom flag to track this rejection
  });

  // Optional: trigger a contractor notification if needed here
};
