import React, { useState, useEffect } from "react";
import { updateUserProfile, fetchUserByEmail } from "../services/dbService";
import { useUser } from "../context/UserContext";
import styles from "../styles/ProfileEdit.module.css";

const AgentProfileAdminView = ({ userDetails, onUpdate }) => {
  const {setUser } = useUser(); // Get setUser from context
  const [submitMessage, setSubmitMessage] = useState("");

  const [formData, setFormData] = useState({
    allowEmail: userDetails?.allowEmail !== undefined ? userDetails.allowEmail : true,
    allowNotifications: userDetails?.allowNotifications !== undefined ? userDetails.allowNotifications : true,
    enabled: userDetails?.enabled ?? true,
    ...userDetails // Preserve other profile fields
  });
      
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ...userDetails,
    }));
  }, [userDetails]);
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage("");
  
    try {
      await updateUserProfile(formData.email, formData, setUser);

      // Fetch updated user details immediately
      const updatedUser = await fetchUserByEmail(formData.email);
      setFormData(updatedUser);

      setSubmitMessage("Profile updated successfully!");
      setTimeout(() => setSubmitMessage(""), 3000);

    } catch (error) {
      setSubmitMessage("Failed to update profile." + error);
      setTimeout(() => setSubmitMessage(""), 3000);
    }
  };
  
  return (
    <div className={styles.container}>
      <h2>{userDetails.firstName} {userDetails.lastName}'s Profile - {userDetails.role} </h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          {/* Company Information */}
          <fieldset className={styles.profileEditFieldset}>
            <legend className={styles.legend}>Company Information</legend>
            <div className={`${styles.row} ${styles.threeColumns}`}>
              <div>
                <label className={styles.label}>Company Name</label>
                <input type="text" className={styles.profileInput} name="companyName" value={formData.companyName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Company Address</label>
                <input type="text" className={styles.profileInput} name="companyAddress" value={formData.companyAddress || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Town / City</label>
                <input type="text" className={styles.profileInput} name="companyCity" value={formData.companyCity || ""} onChange={handleChange} required />
              </div>
            </div>

            <div className={`${styles.row} ${styles.threeColumns}`}>
              <div>
                <label className={styles.label}>Postcode</label>
                <input type="text" className={styles.profileInput} name="companyPostcode" value={formData.companyPostcode || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Office Phone Number</label>
                <input type="text" className={styles.profileInput} name="officePhoneNumber" value={formData.officePhoneNumber || ""} onChange={handleChange} required />
              </div>
            </div>
          </fieldset>

          {/* Banking Information */}
          <fieldset className={styles.profileEditFieldset}>
            <legend className={styles.legend}>Banking Information</legend>
            <div className={`${styles.row} ${styles.threeColumns}`}>
              <div>
                <label className={styles.label}>Bank Name</label>
                <input type="text" className={styles.profileInput} name="paymentBankName" value={formData.paymentBankName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Account Number</label>
                <input type="text" className={styles.profileInput} name="paymentAccountNumber" value={formData.paymentAccountNumber || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Sort Code</label>
                <input type="text" className={styles.profileInput} name="paymentSortCode" value={formData.paymentSortCode || ""} onChange={handleChange} required />
              </div>
            </div>
          </fieldset>

          {/* Personal Information */}
          <fieldset className={styles.profileEditFieldset}>
            <legend className={styles.legend}>Personal Information</legend>
            <div className={`${styles.row} ${styles.fourColumns}`}>
              <div>
                <label className={styles.label}>Company Contact First Name</label>
                <input type="text" className={styles.profileInput} name="firstName" value={formData.firstName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Company Contact Last Name</label>
                <input type="text" className={styles.profileInput} name="lastName" value={formData.lastName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Email Address</label>
                <input type="email" className={styles.profileInput} name="email" value={formData.email || ""} onChange={handleChange} required />
              </div>
            </div>
          </fieldset>

          {/* Terms and Conditions */}
          <div className={styles.notificationPreferences}>

            {/* Send Email */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowEmail" name="allowEmail" 
                checked={formData.allowEmail !== undefined ? formData.allowEmail : true} onChange={handleChange} />
              <label htmlFor="allowEmail" className={styles.profileEditLabel}>
                Email: <span className={styles.profileEditInfo}>Enable or disable sending email to this user.</span>
              </label>
            </div>

            {/* Allow Notifications */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowNotifications" name="allowNotifications" 
                checked={formData.allowNotifications !== undefined ? formData.allowNotifications : true} onChange={handleChange} />
              <label htmlFor="allowNotifications" className={styles.profileEditLabel}>
                Notifications: <span className={styles.profileEditInfo}>Enable or disable notifications for this user.</span>
              </label>
            </div>

              {/* Allow site Access */}
            <div className={styles.profileEditCheckboxRow}>

            <input type="checkbox" id="enabled" name="enabled" 
              checked={!!formData.enabled} 
              onChange={(e) => {
                const isEnabled = e.target.checked;
                setFormData((prev) => ({
                  ...prev,
                  enabled: isEnabled,
                  allowEmail: isEnabled ? prev.allowEmail : false,
                  allowNotifications: isEnabled ? prev.allowNotifications : false,
                }));
              }} />

              <label htmlFor="enabled" className={styles.profileEditLabel}>
                Account access: <span className={styles.profileEditInfo}>Enable or disable site access for this user.</span>
              </label>
            </div>
            
          </div>

          {/* Submit Button */}
          <div className={styles.profileEditsubmitContainer}>
            {submitMessage && <div className={styles.submitMessage}>{submitMessage}</div>}
            <button type="submit" className={styles.profileEditButton} disabled={false}>
              Save Changes
            </button>
          </div>
        </form>
    </div>
  );
};

export default AgentProfileAdminView;
