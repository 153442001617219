import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { fetchQuote } from "../services/quoteService";
import {fetchPropertyById} from "../services/dbService";
import { db } from "../firebase";
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { createNotification } from "../services/dbService";
import { SystemCommenter } from "../Shared/SystemCommenter";
import styles from "../styles/AppointmentForm.module.css";

const LandlordAppointmentForm = ({ jobId, landlordData, onReturnToDashboard }) => {
  const [jobDetails, setJobDetails] = useState(null);
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [selectedDates, setSelectedDates] = useState({});
  const [multiVisit, setMultiVisit] = useState(false);
  const [suggestedDates, setSuggestedDates] = useState([]);
  const [busyDates, setBusyDates] = useState(new Set());
  const [contractorSubmitted, setContractorSubmitted] = useState(false);
  const [confirmedDates, setConfirmedDates] = useState(new Set());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const readOnlyMode = isSubmitted || confirmedDates.size > 0;

  
  const handleLandlordSubmit = async () => {
    if (isSubmitting) return; // Prevent duplicate submissions
  
    setIsSubmitting(true);
  
    try {
      const appointmentsRef = collection(db, "appointments");
      const q = query(appointmentsRef, where("jobId", "==", jobId));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        throw new Error(`No appointment record found for jobId: ${jobId}`);
      }
  
      const appointmentDoc = querySnapshot.docs[0].ref; // Get the first matching document
  
      // 🔹 Prepare the update payload
      const confirmedDatesArray = Array.from(confirmedDates).map((date) => ({
        date,
        description: selectedDates[date] || "No description provided", // Preserve descriptions
      }));
  
      // 🔹 Save confirmed selections to Firestore
      await updateDoc(appointmentDoc, { landlordConfirmedDates: confirmedDatesArray });
  
      // 🔹 Update the job status to 5
      const jobsRef = collection(db, "jobs");
      const jobQuery = query(jobsRef, where("jobId", "==", jobId));
      const jobSnapshot = await getDocs(jobQuery);

      if (jobSnapshot.empty) {
        throw new Error(`No job record found for jobId: ${jobId}`);
      }

      const jobDoc = jobSnapshot.docs[0].ref;
      await updateDoc(jobDoc, { status: 5 });

      // Provide Comments
      await SystemCommenter(jobId, "Landlord", "I have confirmed i'm happy with the dates suggested");
    
      // TODO? EMAIL CONTRACTOR?

      // Create Site  Notifications
      const customMessage = `Landlord ${propertyDetails.landlordsEmail} has accepted the dates suggested by ${jobDetails.contractorName}`;
      const notifAddress = `${propertyDetails.address} ${propertyDetails.companyCity} ${propertyDetails.postcode}`; 
      await createNotification(
        jobId, 
        "landlord_accepted_dates", 
        {
          admin: true,
          contractor: jobDetails.contractorEmail,
        },
        notifAddress,
        "Appointments",
        customMessage,
      );

      setIsSubmitted(true); // Show success message
    } catch (error) {
      console.error("Error confirming dates:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!jobId) return;
      try {
        const jobsRef = collection(db, "jobs");
        const q = query(jobsRef, where("jobId", "==", jobId));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const jobData = querySnapshot.docs[0].data();
          setJobDetails(jobData);
          const propertyData = await fetchPropertyById(jobData.propertyId)
          setPropertyDetails(propertyData);

          if (Array.isArray(jobData.busyDates)) {
            setBusyDates(new Set(jobData.busyDates)); // 🔹 Match Contractor ReadOnly Logic
          }
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };
  
    fetchJobDetails();
  }, [jobId]);
  
  useEffect(() => {
    const fetchAppointmentData = async () => {
      if (!jobId) return;
      try {
        const appointmentsRef = collection(db, "appointments");
        const q = query(appointmentsRef, where("jobId", "==", jobId));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const appointmentData = querySnapshot.docs[0].data();
  
          if (Array.isArray(appointmentData.dates)) {
            const formattedDates = {};
            let hasMultiVisitEntries = false;
  
            appointmentData.dates.forEach(({ date, description }) => {
              const isoDate = date.seconds
                ? new Date(date.seconds * 1000).toISOString().split("T")[0]
                : date;
              formattedDates[isoDate] = description || "No description provided";
  
              if (description) hasMultiVisitEntries = true;
            });
  
            setSuggestedDates(Object.keys(formattedDates));
            setSelectedDates(formattedDates);
            setMultiVisit(hasMultiVisitEntries);
          }

          if (Array.isArray(appointmentData.landlordConfirmedDates)) {
            const confirmedSet = new Set();
            
            appointmentData.landlordConfirmedDates.forEach(({ date }) => {
              confirmedSet.add(date);
            });
            debugger;

            setConfirmedDates(confirmedSet);
            setIsSubmitted(true); // 🔹 If confirmed dates exist, show success message
          }
  
          setContractorSubmitted(appointmentData.dates.length > 0);
        } else {
          console.warn(`No appointment found for jobId: ${jobId}`);
        }
      } catch (error) {
        console.error("Error fetching appointment data:", error);
      }
    };
  
    fetchAppointmentData();
  }, [jobId]);
  
  useEffect(() => {
    const loadQuoteData = async () => {
      if (jobId) {
        try {
          const quoteData = await fetchQuote(jobId);
          if (quoteData) {
            setQuoteDetails(quoteData);
          }
        } catch (error) {
          console.error("Error fetching quote details:", error);
        }
      }
    };
    loadQuoteData();
  }, [jobId]);

  return (
    <>
      <h2>
        {isSubmitted ? "Appointment Confirmed" : contractorSubmitted ? "Confirm Appointment Date" : "Waiting for Contractor to Propose Dates"}
      </h2>

      {jobDetails ? (
        <div className={styles.jobDetails}>
          <div className={styles.jobDetailRow}>
            <div className={styles.formGroup}>
              <label><strong>Trade:</strong></label>
              <input type="text" value={jobDetails?.tradeName || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Job Description:</strong></label>
              <input type="text" value={jobDetails?.jobDetail || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Further Information:</strong></label>
              <input type="text" value={jobDetails?.furtherDetails || "N/A"} readOnly />
            </div>
          </div>
          <div className={styles.jobDetailRow}>
            <div className={styles.formGroup}>
              <label><strong>Lead Time Required:</strong></label>
              <input type="text" value={quoteDetails?.leadTime || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Est. Duration:</strong></label>
              <input type="text" value={quoteDetails?.duration || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Quote Detail:</strong></label>
              <input type="text" value={quoteDetails?.quoteDetails || "N/A"} readOnly />
            </div>
          </div>
        </div>
      ) : (
        <p>Loading job details...</p>
      )}

      <div className={styles.container}>

        <div className={styles.calendarContainer}>
          
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            selectable={true} // 🔹 Enable clicking on suggested dates
            
            dateClick={(info) => {
              if (readOnlyMode) return; // 🔒 Don't allow clicks if already submitted
              if (!suggestedDates.includes(info.dateStr)) return;
            
              setConfirmedDates((prev) => {
                const updated = new Set(prev);
                if (updated.has(info.dateStr)) {
                  updated.delete(info.dateStr);
                } else {
                  updated.add(info.dateStr);
                }
                return updated;
              });
            }}
                                    
            events={[
              // 🔹 Busy Dates
              ...Array.from(busyDates || []).map((date) => ({
                title: "Busy",
                start: date,
                color: "orange",
                displayOrder: 1, // Busy stays at the top
              })),
            
              // 🔹 Suggested Dates (independent of confirmation)
              ...Array.from(suggestedDates || []).map((date) => ({
                title: "Suggested",
                start: date,
                color: "blue",
                displayOrder: 2, // Suggested before Confirmed
              })),
            
              // 🔹 Confirmed Dates (includes user-selected AND landlord-confirmed)
              ...(Array.isArray(Array.from(confirmedDates))
                ? Array.from(confirmedDates).map((date) => ({
                    title: "Confirmed",
                    start: date,
                    color: "green",
                    displayOrder: 3, // Confirmed after Suggested
                  }))
                : []),
            ]}
            
            eventOrder="displayOrder"  
            height="auto"
            aspectRatio={1.35}
            firstDay={1}
            validRange={{ start: new Date().toISOString().split("T")[0] }}
          />

          <p className={styles.calendarHelperText}>
            {readOnlyMode
              ? "The appointment has been confirmed. No further action is needed."
              : "Click on the suggested day(s) to confirm the work can be undertaken as suggested."}
          </p>

        </div>

        <div className={styles.detailsContainer}>
          {multiVisit && <p className={styles.multiVisitInfo}>This job requires multiple visits, as proposed by the contractor.</p>}

          <h3>The dates that have been suggested for the work</h3>

          <ul className={styles.selectedDatesList}>
            {suggestedDates.length > 0 ? (
              suggestedDates.map((date, index) => {
                const formattedDate = new Date(date).toLocaleDateString("en-GB");
                const isConfirmed = confirmedDates.has(date); // 🔹 Check if confirmed

                return (
                  <li
                    key={index}
                    className={`${styles.selectedDateItem} ${isConfirmed ? styles.confirmedGlow : ""}`}
                  >
                    <strong>{formattedDate}</strong>
                    {selectedDates[date] && <p className={styles.descriptionText}>{selectedDates[date]}</p>}
                  </li>
                );
              })
            ) : (
              <li className={styles.noDatesText}>No dates proposed yet.</li> // 🔹 Handles the edge case
            )}
          </ul>

          {!isSubmitted ? (
            <button
              className={`${styles.submitButton} ${confirmedDates.size === 0 ? styles.disabledButton : ""}`}
              onClick={handleLandlordSubmit}
              disabled={confirmedDates.size === 0 || isSubmitting}
            >
              {isSubmitting ? <span className={styles.spinner}></span> : "Confirm Selected Dates"}
            </button>
          ) : (
            <p className={styles.successMessage}>You have confirmed the dates for work to be undertaken.</p>
          )}

        </div>

      </div>
    </>
  );
};

export default LandlordAppointmentForm;
