import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import GridPanel from '../components/GridPanel';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const JobInProgress = ({ isSingleColumn, onRowSelectAction }) => {
  const [inProgressJobs, setInProgressJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const loadInProgressJobs = useCallback(async () => {
    try {
      const jobs = await fetchInProgressJobsAdmin();
      setInProgressJobs(jobs);
    } catch (error) {
      console.error("Error loading in-progress jobs:", error);
    }
  }, []);

  useEffect(() => {
    const handleRefresh = () => loadInProgressJobs();
    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadInProgressJobs]);

  useEffect(() => {
    loadInProgressJobs();
  }, [loadInProgressJobs]);

  const onRowClickHandler = (event) => {
    const tabModes = {
      Detail: 'readOnly',
      Quote: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'readOnly',
      Property: 'hide',
    };
    onRowSelectAction(event.data, tabModes);
  };

  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 1.0 },
    { 
      headerName: "TRADE",
      field: "tradeName",
      flex: 1.7,
      filter: true,
      cellRenderer: TradeCellRenderer,
      valueGetter: (params) =>
        params.data.tradeName === 'Other (please specify)' ? params.data.instructionNotes || 'N/A' : params.data.tradeName,
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { 
      headerName: "IN PROGRESS SINCE",
      field: "startDate",
      flex: 1.5,
      valueFormatter: (params) => {
        if (!params.value) return "";
        const date = new Date(params.value);
        return date.toLocaleDateString("en-GB");
      }
    }
  ];

  return (
    <GridPanel
      title="Job In Progress [admin]" // 5
      columnDefs={columnDefs}
      rowData={inProgressJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={onRowClickHandler}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobInProgress;


const fetchInProgressJobsAdmin = async () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const jobsQuery = query(
    collection(db, "jobs"),
    where("status", "==", 5)
  );

  const jobsSnapshot = await getDocs(jobsQuery);
  const results = [];

  for (const jobDoc of jobsSnapshot.docs) {
    const jobData = jobDoc.data();
    const jobId = jobDoc.id;

    const apptQuery = query(
      collection(db, "appointments"),
      where("jobId", "==", jobId)
    );
    const apptSnapshot = await getDocs(apptQuery);

    let earliestStartDate = null;

    apptSnapshot.forEach(doc => {
      const data = doc.data();
      const confirmedDates = (data.landlordConfirmedDates || [])
        .map(entry => {
          const raw = entry?.date;
          if (!raw) return null;
          const parsed = new Date(raw.includes("T") ? raw : `${raw}T00:00:00`);
          return isNaN(parsed) ? null : parsed;
        })
        .filter(Boolean);

      if (confirmedDates.length > 0) {
        const min = confirmedDates.reduce((a, b) => a < b ? a : b);
        if (!earliestStartDate || min < earliestStartDate) earliestStartDate = min;
      }
    });

    // Show only jobs that have already started
    if (earliestStartDate && earliestStartDate <= today) {
      results.push({ ...jobData, jobId, startDate: earliestStartDate });
    }
  }

  return results;
};
