import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import GridPanel from '../components/GridPanel';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { useUser } from "../context/UserContext";

const ContractorStartDateOffered = ({ isSingleColumn, onRowSelectAction }) => {
  const [offeredJobs, setOfferedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const userX = useUser();  

  const loadOfferedJobs = useCallback(async () => {
    const contractorEmail = userX?.user?.email;
    if (!contractorEmail) return;

    try {
      const jobs = await fetchContractorOfferedJobs(contractorEmail);
      setOfferedJobs(jobs);
    } catch (error) {
      console.error("Error loading contractor offered jobs:", error);
    }
  }, [userX]);

  useEffect(() => {
    const handleRefresh = () => loadOfferedJobs();
    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadOfferedJobs]);

  useEffect(() => {
    loadOfferedJobs();
  }, [loadOfferedJobs]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'readOnly',
      Property: 'hide',
    };
    onRowSelectAction(jobData, tabModes, "Appointments");
  };

  const columnDefsOfferedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 1.0 },
    {
      headerName: "TRADE", field: "tradeName", flex: 1.7, filter: true,
      cellRenderer: TradeCellRenderer,
      valueGetter: (params) =>
        params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName,
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 2 },
    {
      headerName: "NO. OF DATES OFFERED", field: "offeredCount", flex: 1.5,
      valueFormatter: (params) => params.value || 0
    }
    
  ];

  return (
    <GridPanel
      title="Start Date Offered [contractor]" // 4
      columnDefs={columnDefsOfferedJobs}
      rowData={offeredJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default ContractorStartDateOffered;


const fetchContractorOfferedJobs = async (contractorEmail) => {
  const jobsQuery = query(
    collection(db, "jobs"),
    where("status", "==", 4),
    where("contractorEmail", "==", contractorEmail)
  );

  const jobsSnapshot = await getDocs(jobsQuery);
  const results = [];

  for (const jobDoc of jobsSnapshot.docs) {
    const jobData = jobDoc.data();
    const jobId = jobDoc.id;
  
    const apptQuery = query(
      collection(db, "appointments"),
      where("jobId", "==", jobId)
    );
  
    const apptSnapshot = await getDocs(apptQuery);
  
    let earliestOfferedDate = null;
    let offeredCount = 0; // 🔹 Track total number of offered dates
  
    apptSnapshot.forEach(doc => {
      const apptData = doc.data();
      const offeredDates = (apptData.dates || [])
        .map(entry => {
          const ts = entry?.date;
          if (!ts || typeof ts.toDate !== "function") return null;
          const parsed = ts.toDate();
          return isNaN(parsed.getTime()) ? null : parsed;
        })
        .filter(Boolean);
  
      // 🔸 Tally count
      offeredCount += offeredDates.length;
  
      if (offeredDates.length > 0) {
        const minDate = offeredDates.reduce((a, b) => (a < b ? a : b));
        if (!earliestOfferedDate || minDate < earliestOfferedDate) {
          earliestOfferedDate = minDate;
        }
      }
    });
  
    if (offeredCount > 0) {
      results.push({
        ...jobData,
        jobId,
        startDateOffered: earliestOfferedDate,
        offeredCount
      });
    }
  }
  

  return results;
};
