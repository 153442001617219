import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const QuotedWaitingOnLLAcceptance = ({ isSingleColumn, onRowSelectAction }) => {
  const [quotedJobs, setQuotedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsQuotedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 1.2 },
    { headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 2.0,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "REQUESTED DATE", field: "createdAt", flex: 1.6, valueFormatter: (params) => formatTimestamp(params.value) }
  ];

    const loadQuotedJobs = useCallback(async () => {
      const options = { 
        status: 1,
        isTimeboxed: false, 
      };
      const jobs = await fetchJobsWaitingOnLandlordToAcceptQuotes(options);
      setQuotedJobs(jobs);
    }, []);

    useEffect(() => {
      const handleRefresh = () => {
        loadQuotedJobs();  
      };
  
      window.addEventListener("refreshGrids", handleRefresh);
      return () => window.removeEventListener("refreshGrids", handleRefresh);
    }, [loadQuotedJobs]); 
  
    useEffect(() => {
      loadQuotedJobs(); 
    }, [loadQuotedJobs]); 

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };
    onRowSelectAction(jobData, tabModes, "Detail");
  };

  return (
    <>
      <GridPanel
        title="Jobs Quoted Waiting On Landlord Acceptance [admin]"
        columnDefs={columnDefsQuotedJobs}
        rowData={quotedJobs}
        height={panelHeight}
        onResize={(change) => setPanelHeight(panelHeight + change)}
        isSingleColumn={isSingleColumn}
        toggleLayout={() => setIsCollapsed(false)}
        isCollapsed={isCollapsed}
        toggleCollapse={() => setIsCollapsed(!isCollapsed)}
        onRowClick={handleJobRowClick} // Pass the row click handler
      />
    </>
  );
};

export default QuotedWaitingOnLLAcceptance;


/**
 * Fetches jobs that are awaiting landlord acceptance of quotes.
 * This includes:
 * - Jobs with status = 1 (admin-submitted quotes)
 * - Jobs with status = 0 that have contractorQuoteIds (contractor-submitted quotes)
 *
 * @returns {Promise<Array>} - A list of jobs in landlord decision phase.
 */
export const fetchJobsWaitingOnLandlordToAcceptQuotes = async () => {
  const jobsRef = collection(db, "jobs");

  try {
    // 🔹 First: Get jobs with status === 1 (admin quotes)
    const adminQuotesQuery = query(jobsRef, where("status", "==", 1));
    const adminSnapshot = await getDocs(adminQuotesQuery);
    const adminJobs = adminSnapshot.docs.map(doc => ({ jobId: doc.id, ...doc.data() }));

    // 🔹 Next: Get jobs with status === 0 (still open) that have contractor quotes
    const openJobsQuery = query(jobsRef, where("status", "==", 0));
    const openSnapshot = await getDocs(openJobsQuery);

    const contractorJobs = [];

    for (const jobDoc of openSnapshot.docs) {
      const jobId = jobDoc.id;
      const jobData = { jobId, ...jobDoc.data() };

      const quotesRef = collection(db, "quotes");
      const quoteQuery = query(quotesRef, where("jobId", "==", jobId));
      const quoteSnapshot = await getDocs(quoteQuery);

      const hasContractorQuote = quoteSnapshot.docs.some(doc => {
        const data = doc.data();
        return Array.isArray(data.contractorQuoteIds) && data.contractorQuoteIds.length > 0;
      });

      if (hasContractorQuote) {
        contractorJobs.push(jobData);
      }
    }

    // 🔹 Merge both sources
    return [...adminJobs, ...contractorJobs];
  } catch (error) {
    console.error("Error fetching jobs waiting on landlord acceptance:", error);
    throw error;
  }
};
