import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { formatTimestamp } from '../Utilities/dateUtils'; 
import { generateCommentEmailHTMLTemplate, generateCommentEmailTextTemplate } from '../EmailTemplates/emailTemplates';
import { globalConfig } from '../config';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { createNotification } from "../services/dbService";
import styles from "../styles/Comments.module.css";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ImagePopup from '../ImagePopup/ImagePopup';
import { createRoot } from "react-dom/client";

const storage = getStorage();

const Comments = ({ jobData, propertyDetails, isActive }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [userName, setUserName] = useState('');
  const [landlordName, setLandlordName] = useState('Landlord');
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [lastSentMessage, setLastSentMessage] = useState(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "30px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };
  
  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;
  
    const uploadPromises = files.map(async (file) => {
      if (file.size > 16 * 1024 * 1024) {
        alert("File too large! Maximum size is 16MB.");
        return null;
      }
  
      const storageRef = ref(storage, `attachments/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    });
  
    const uploadedUrls = (await Promise.all(uploadPromises)).filter(Boolean);
    if (uploadedUrls.length) {
      setUploadedAttachments((prev) => [...prev, ...uploadedUrls]); // Store uploaded attachments until message is sent
    }
    
  };
  
  const handleDeleteAttachment = (index) => {
    setUploadedAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleImageClick = (index, images) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const popupWidth = 1100;
    const popupHeight = 800;
    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;
  
    const newWindow = window.open("", "_blank", `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
    newWindow.document.title = "Image Viewer";
  
    const container = newWindow.document.createElement("div");
    newWindow.document.body.appendChild(container);
  
    const root = createRoot(container);
    root.render(
      <ImagePopup images={images} initialIndex={index} onClose={() => newWindow.close()} />
    );
  };
  
    
  const sender =
  userRole === 'Admin' ? 'Admin' : 
  userRole === 'Agent' ? 'Agent' : 
  userRole === 'Landlord' ? 'Landlord' :  
  userRole === 'Contractor' ? 'Contractor' : 'Unknown';

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = auth.currentUser;
      const userDoc = await getDoc(doc(db, 'users', user.email));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserRole(userData.role);
    
        if (userData.role === "Contractor") {
          setUserName(userData.companyName || "Unknown Contractor"); // Use companyName
        } else {
          setUserName(`${userData.firstName} ${userData.lastName}`.trim() || userData.role); // Use name for others
        }
      }
    };    

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (propertyDetails?.user?.firstName && propertyDetails?.user?.lastName) {
      setLandlordName(`${propertyDetails.user.firstName} ${propertyDetails.user.lastName}`);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (!jobData.jobId || !userRole) return;
  
    const commentsRef = doc(db, 'comments', jobData.jobId);
  
    const unsubscribe = onSnapshot(commentsRef, (docSnap) => {
      if (docSnap.exists()) {
        const newMessages = docSnap.data().comments || [];

      // Avoid resetting messages if no new messages exist
      setMessages(newMessages.filter(msg => msg.timestamp !== lastSentMessage?.timestamp));
    }

   });
  
    // Cleanup listener when component unmounts
    return () => unsubscribe(); 
  }, [jobData.jobId, userRole, lastSentMessage?.timestamp]);
  
  useEffect(() => {
    // Scroll to the end whenever messages change
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    // Scroll to the end when the tab becomes active
    if (isActive && endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isActive]);

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() && uploadedAttachments.length === 0) return;

    const messageData = {
      sender: userName || sender, // Now includes `companyName` for Contractors
      senderRole: userRole, // Store role separately for safety
      recipient: selectedTarget || null,
      text: newMessage.trim() || null, 
      attachments: uploadedAttachments, 
      timestamp: serverTimestamp(),
    };
    

    try {
      const jobId = jobData.jobId;
      const commentsRef = doc(db, 'comments', jobData.jobId);
      const commentsSnap = await getDoc(commentsRef);

      if (commentsSnap.exists()) {
        // Document exists, update it
        await updateDoc(commentsRef, {
          comments: arrayUnion({
            ...messageData,
            timestamp: new Date(), // Use local timestamp for optimistic UI update
          }),
        });

        setLastSentMessage(messageData);

      } else {
        // Document does not exist, create it
        await setDoc(commentsRef, {
          jobId,
          comments: [
            {
              ...messageData,
              timestamp: new Date(), // Use local timestamp for optimistic UI update
            },
          ],
        });

        setLastSentMessage(messageData);

      }

      // Update local state optimistically and ensure it's formatted properly
      const newTimestamp = { seconds: Math.floor(Date.now() / 1000), nanoseconds: 0 };
      setMessages((prevMessages) => [...prevMessages, { ...messageData, timestamp: newTimestamp }]);
      
      // Clear ready for next message
      setNewMessage(""); 
      setUploadedAttachments([]);
      setSelectedTarget(null);

      if (textareaRef.current) {
        textareaRef.current.style.height = "30px"; // Reset height to default
      }
      // Fetch property details
      const propertyDetails = await fetchPropertyDetails(jobData.propertyId);

      // Make sure the email is only sent if the commenter is NOT the Landlord
      if (userRole !== "Landlord") {
        // Send email
        const htmlBody = generateCommentEmailHTMLTemplate(jobData.jobId, landlordName, messageData.text);
        const plainTextBody = generateCommentEmailTextTemplate(jobData.jobId, landlordName, messageData.text);

        await sendNotificationEmail({
          to: jobData.landlordEmail,
          subject: `${globalConfig.sitename} New Comment - Job ${jobData.jobId}`,
          text: plainTextBody, 
          html: htmlBody, 
        });
      }

      // Create Site  Notifications
      const customMessage = `The ${sender} has made a comment on  ${jobData.jobId}`;
      const notifAddress = `${propertyDetails.address} ${propertyDetails.city} ${propertyDetails.postcode}`; 
      await createNotification(
        jobData.jobId, 
        "new_comment", 
        Object.fromEntries(
          [
            userRole !== "Admin" && ["admin", true], // ✅ Notify all admins
            userRole !== "Agent" && jobData.agentId && ["agent", jobData.agentId],
            userRole !== "Landlord" && jobData.landlordEmail && ["landlord", jobData.landlordEmail],
            userRole !== "Contractor" && jobData.contractorEmail && ["contractor", jobData.contractorEmail]
          ].filter(Boolean) // Removes undefined/null entries
        ),
        notifAddress,
        "Comments",
        customMessage
      );
      

    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
  
  return (
    <div className={styles.commentsContainer}>
      <div className={styles.chatSidebar}>
        
        <div className={styles.chatInfoPanel}>
          <div className={styles.commentsSideNavHeader}>
            <p>Comments for {jobData.jobId}</p>
            <span>Click on a name below to send a message directly</span>
          </div>
        </div>

        <div className={styles.chatUserList}>
          {[
            { name: 'Admin', description: 'CMH Staff', role: 'admin' },
            { name: `${propertyDetails?.user?.firstName || 'Unknown'} ${propertyDetails?.user?.lastName || ''}`, description: 'Landlord', role: 'landlord' },
            ...(jobData?.contractorName ? [{ name: jobData.contractorName, description: 'Contractor', role: 'contractor' }] : [])
          ]
            .map((user) => ({ ...user, isDisabled: user.role === userRole.toLowerCase() }))
            .map(({ name, description, role }) => (
              <div key={role} className={`${styles.chatUserCard} ${selectedTarget === role ? styles.selected : ''}`} onClick={() => role !== userRole.toLowerCase() && setSelectedTarget(selectedTarget === role ? null : role)}>
                <h4>{name}</h4>
                <p className={styles.role}>{description}</p>
              </div>
            ))}
        </div>


      </div>
  
      <div className={styles.chatMain}>
        <div className={styles.chatMessages}>
          {messages.map((message, index) => (
            <div key={index} className={`${styles.chatMessage} ${message.sender?.toLowerCase().trim() === userName?.toLowerCase().trim() ? styles.chatMessageSent : styles.chatMessageReceived}`}>  
              <p>
                <strong>
                {message.sender?.trim() || message.senderRole?.trim()}
                {message.recipient && <span> to {message.recipient.charAt(0).toUpperCase() + message.recipient.slice(1)}</span>}
                </strong><br />
                
                {message.text ? message.text.split("\n").map((line, index) => (<span key={index}>{line}<br /></span>)) : null}
                
                {message.attachments && message.attachments.length > 0 && (
                  <div className={styles.chatAttachments}>
                    {message.attachments.map((url, index) => (
                      url.endsWith(".pdf") ? (
                        <a key={index} href={url} target="_blank" rel="noopener noreferrer">📄 PDF Attachment</a>
                      ) : (

                        <img key={index} src={url} alt="attachment" className={styles.chatThumbnail} 
                          onClick={() => handleImageClick(index, message.attachments)} 
                        />
                        
                      )
                    ))}
                  </div>
                )}

                <span className={styles.chatMessageTimestamp}>
                  {message.timestamp ? formatTimestamp(message.timestamp) : formatTimestamp(new Date())}
                </span>
              </p>
            </div>
          ))}
          <div ref={endOfMessagesRef} />
          {userRole !== 'Agent' && (
            <div className={styles.chatInputContainer}>
              <button className={styles.chatAttachButton} onClick={() => fileInputRef.current.click()}>+</button>
              <input type="file" ref={fileInputRef} className={styles.hiddenFileInput} onChange={handleFileSelect} accept="image/*,.pdf" multiple />

                {/* Show previews before sending */}
                {uploadedAttachments.length > 0 && (
                <div className={styles.chatAttachments}>
                  {uploadedAttachments.map((url, index) => (
                    <div key={index} className={styles.chatThumbnailContainer}>
                      {url.endsWith(".pdf") ? (
                        <a href={url} target="_blank" rel="noopener noreferrer" className={styles.pdfAttachment}>📄 PDF</a>
                      ) : (
                        <img src={url} alt="attachment" className={styles.chatThumbnail} />
                      )}
                      <button className={styles.deleteAttachment} onClick={() => handleDeleteAttachment(index)}>X</button>
                    </div>
                  ))}
                </div>
              )}

              <textarea 
                ref={textareaRef}
                className={styles.chatInputBox} 
                placeholder={`Send a message to ${selectedTarget ? (selectedTarget === 'landlord' ? landlordName : selectedTarget) : 'everyone'}`} 
                value={newMessage} 
                onChange={(e) => { setNewMessage(e.target.value); adjustTextareaHeight();}}
                onKeyDown={(e) => { if (e.key === "Enter") {e.preventDefault(); setNewMessage((prev) => prev + "\n"); adjustTextareaHeight();}}}              
              />
                {/* <button className={styles.chatSendButton} onClick={handleSendMessage}>Send</button> */}
                <button className={styles.chatSendButton} onClick={handleSendMessage} disabled={!newMessage.trim() && uploadedAttachments.length === 0}>
                  Send
                </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default Comments;
