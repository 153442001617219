// src/Job/JobRequestForm.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import JobRequestFormJobDetails from './JobRequestForm-JobDetails';
import { insertJobWithNumbering } from '../services/jobService';
import { auth } from '../firebase';
import { generateRAQEmailTextTemplate, generateRAQEmailHTMLTemplate } from '../EmailTemplates/emailTemplates';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { globalConfig } from '../config';
import { createNotification } from "../services/dbService";
import '../styles/JobRequestForm.css';

const JobRequestForm = ({ propertyId, agentId, onReturnToDashboard }) => {

  const [formData, setFormData] = useState({});
  const [selectedTrades, setSelectedTrades] = useState([]);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const landlordEmail = auth.currentUser?.email;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const fetchLandlordDetails = async (landlordEmail) => {
    const userDocRef = doc(db, "users", landlordEmail); 
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        firstName: userData.firstName,
        lastName: userData.lastName,
      };
    } else {
      throw new Error("Landlord not found");
    }
  };

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  useEffect(() => {
    const isValid = selectedTrades.length > 0 && selectedTrades.every(trade => trade.jobDetail.trim() !== '');
    setIsFormValid(isValid);
  }, [selectedTrades]);

  const handlePhotoUpload = (newPhotos) => {
    setUploadedPhotos((prev) => [...prev, ...newPhotos]);
  };

  const handleSubmit = async () => {
    if (isFormValid && !isSubmitting) {
      setIsSubmitting(true); // Disable button and show spinner
      setSubmissionStatus("Fetching property details...");

      try {
        // Fetch property details
        const propertyDetails = await fetchPropertyDetails(propertyId);
        
        // Fetch landlord details
        setSubmissionStatus("Fetching landlord details...");
        const landlordDetails = await fetchLandlordDetails(landlordEmail);
        let latestJobId = null; // Use local variable to store last jobId

        for (const trade of selectedTrades) {
          const jobData = {
            ...formData,
            tradeId: trade.tradeId,
            tradeName: trade.tradeName,
            jobDetail: trade.jobDetail,
            instructionNotes: trade.instructionNotes,
            furtherDetails: trade.furtherDetails,
            photos: uploadedPhotos,
            raised: new Date(),
            quoteAmount: '',
            quoteTime: '',
            quoteAccepted: false,
            quoteAcceptedAt: null,
            landlordEmail,
            landlordFirstName: landlordDetails.firstName, 
            landlordLastName: landlordDetails.lastName, 
            propertyId,
            paid: false,
            paymentId: '',
            status: 0,
            isPQW: false,
            agentId,
            busyDates: trade.availableDates || [],
          };
  
          setSubmissionStatus("Generating job reference...");
          const jobId = await insertJobWithNumbering(jobData, propertyId);
          latestJobId = jobId;

          // Ensure a job ID was actually created
          if (!latestJobId) {
            throw new Error("Job ID was not generated.");
          }          
    
          // Inline Comment Logic
          const messageData = {
            sender: 'Admin',
            text: `Quote Requested for ${trade.jobDetail}`,
            timestamp: serverTimestamp(),
          };
  
          const commentsRef = doc(db, 'comments', latestJobId);
          const commentsSnap = await getDoc(commentsRef);
  
          if (commentsSnap.exists()) {
            await updateDoc(commentsRef, {
              comments: arrayUnion({
                ...messageData,
                timestamp: new Date(),
              }),
            });
            // console.log('Comment added to existing document:', messageData);
          } else {
            await setDoc(commentsRef, {
              jobId: latestJobId,
              comments: [
                {
                  ...messageData,
                  timestamp: new Date(),
                },
              ],
            });
            // console.log('Created new document with comment:', messageData);
          }

          setSubmissionStatus("Notifying the management team...");
          const propertyAddress = propertyDetails.address + ", " + propertyDetails.city + ", " + propertyDetails.postcode;

          // Create Site Notifications for Agent
          const customMessage = `Landlord ${landlordEmail} has requested work ${latestJobId}`;
          await createNotification(
            latestJobId, 
            "quote_requested", 
            {
              admin: true,
              agent: agentId,
            },
            propertyAddress,
            "Detail",
            customMessage,
          );
        }  
        
      // Use the latest jobId from the loop
      if (!latestJobId) {
        throw new Error("Job ID was not generated.");
      }
      // Send email notification to the landlord
        setSubmissionStatus("Sending confirmation email...");
        const plainTextBody = generateRAQEmailTextTemplate(latestJobId, landlordDetails.firstName);
        const htmlBody = generateRAQEmailHTMLTemplate(latestJobId, landlordDetails.firstName);
  
        await sendNotificationEmail({
          to: landlordEmail,
          subject: `${globalConfig.sitename} quote - Job ${latestJobId}`,
          text: plainTextBody,
          html: htmlBody,
        });

        
        setSubmissionStatus("Request submitted successfully! - Returning to Dashboard");
        setTimeout(() => {
          onReturnToDashboard();
        }, 1500);

      } catch (error) {
        console.error('Error inserting jobs or comments:', error);
        setSubmissionStatus("An error occurred: " + error);
      } finally {
        setIsSubmitting(false); // Re-enable button after submission
      }
    }
  };
  
  return (
    <div className="job-request-form">
      
      <div className='job-request-return'>
        <button className="decline-button" 
        onClick={() => onReturnToDashboard()}>
        Return to Dashboard
        </button>
      </div>
      
      <div className='job-request-header'>

        <h2>Instructions</h2>

        <p>
          1. Select a trade type for the works you require a quote for. This will ensure the request is directed to the right management team
        </p>
        <p>
          2. Provide a brief job description about the work you require a quote for. e.g. <i>'Paint Hallway walls and ceiling'</i>
        </p>
        <p>
          3. Add any further information that may help when requesting a quote, the size of a room, or any special requirements.
        </p>
        <p>
          4. When complete, click send details for a quote and our team will evauluate your request and provide an estimate for the work you require. Please note: the team may have to contact you for clarification on a request that doesnt contain enough information.
        </p>

      </div>

      <JobRequestFormJobDetails
        formData={formData}
        setFormData={setFormData}
        selectedTrades={selectedTrades}
        setSelectedTrades={setSelectedTrades}
        propertyId={propertyId}
        onPhotoUpload={handlePhotoUpload}
      />
      
      <div className="form-navigation">
        <button onClick={handleSubmit} disabled={!isFormValid || isSubmitting}>
          {isSubmitting ? "Submitting..." : "Send Details for a Quote"}
        </button>

        {submissionStatus && (
          <p className={`submission-status ${submissionStatus.includes("error") ? "error" : submissionStatus.includes("success") ? "success" : ""}`}>
            {submissionStatus}
          </p>
        )}

      </div>
    </div>
  );
};

export default JobRequestForm;
