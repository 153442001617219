import React, { useState, useEffect } from "react";
import { updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../firebase"; // Firebase instances
import { updatePassword, sendSignInLinkToEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { createNotification } from "../services/dbService";
import styles from "./CPLandlord.module.css";

const CPAdmin = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    agreeToTerms: false,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = urlParams.get('email');
    if (userEmail) {
      setFormData((prev) => ({ ...prev, email: userEmail }));
    } else {
      console.error('No email found in URL parameters.');
      setError("Email not found in URL parameters.");
      navigate("/login");
    }
  }, [navigate]);

  const isFormValid = formData.agreeToTerms && formData.password;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      if (formData.password) {
        try {
          await updatePassword(auth.currentUser, formData.password);
        } catch (error) {
          if (error.code === "auth/requires-recent-login") {
            alert("Your session has expired. A new login link will be sent to your email.");
            const actionCodeSettings = {
              url: `https://completemaintenance.uk/complete-profile?email=${encodeURIComponent(formData.email)}`,
              handleCodeInApp: true,
            };
            await sendSignInLinkToEmail(auth, formData.email, actionCodeSettings);
            alert("A new login link has been sent to your email.");
            return;
          } else {
            setError("Failed to update password. Please try again.");
            return;
          }
        }
      }

      const userDocRef = doc(db, "users", formData.email);
      await updateDoc(userDocRef, {
        isProfileComplete: true,
        lastLoggedIn: serverTimestamp(),
        inviteAcceptedAt: serverTimestamp(),
      });

      // Create Notification
      const customMessage = `Admin ${formData.email} has completed sign-up`;
      await createNotification(
        null, 
        "admin_signup_complete", 
        {
          admin: true,
        }, 
        "Admin Panel", 
        null, 
        customMessage,
      );

      setSuccess("Profile updated successfully!");
      navigate("/dashboard");

    } catch (error) {
      console.error("Error during profile update:", error);
      setError("Failed to update profile. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.brandingHeaderPanel}>
        <img src="/mmg-logo.png" alt="Logo" className={styles.logo} />
        <span>Complete Maintenance Hub</span>
      </div>

      <form onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Admin Account</legend>

          <div className={styles.row}>
            <div>
              <label className={styles.label}>Email Address</label>
              <input type="email" className={styles.input} value={formData.email || ""} name="email" readOnly />
            </div>
            <div>
              <label className={styles.label}>Password</label>
              <input type="password" className={styles.input} value={formData.password || ""} name="password" onChange={handleChange} required />
            </div>
          </div>

        </fieldset>
        <div className={styles.termsContainer}>
          <input className={styles.checkBox} type="checkbox" id="agreeToTerms" name="agreeToTerms" checked={formData.agreeToTerms || false} onChange={handleChange} required />
          <label htmlFor="agreeToTerms" className={styles.label}>I agree to the <button type="button" onClick={() => window.open('/terms-and-conditions.html', '_blank', 'width=800,height=600')}>Terms and Conditions</button></label>
        </div>
        <div className={styles.submitContainer}>
          <button type="submit" className={styles.submitButton} disabled={!formData.agreeToTerms || isSubmitting} style={{ opacity: !formData.agreeToTerms ? 0.5 : 1, cursor: !formData.agreeToTerms ? 'not-allowed' : 'pointer' }}>
            {isSubmitting ? <span className={styles.spinner}></span> : "Complete Registration"}
          </button>
        </div>
        <div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {success && <p style={{ color: "green" }}>{success}</p>}
        </div>
      </form>
    </div>
  );
};

export default CPAdmin;
