import React, { useState, useEffect } from "react";
import { fetchAllUsers } from '../services/dbService';
import { formatTimestamp, formatDateOnly } from '../Utilities/dateUtils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/SettingsUsers.css';
import ProfileForm from '../ProfileForm/ProfileForm'; 

const SettingsUsers = (props) => {
  const [rowData, setRowData] = useState([]);
  const [paginationPageSize] = useState(18);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDisabledUsers, setShowDisabledUsers] = useState(false);
  const filteredRowData = showDisabledUsers
  ? rowData
  : rowData.filter(user => user.enabled !== false);


  useEffect(() => {
    setSelectedUser(null); 
  }, []);

  const handleRowClick = (row) => {
    setSelectedUser(row.data); // Capture the clicked user's data
  };

  useEffect(() => {
    const loadAllUsers = async () => {
      try {
        const users = await fetchAllUsers();
        setRowData(users);
      } catch (error) {
        console.error('SettingsUser: Error fetching Users: ', error);
      }
    }
    loadAllUsers()
  }, []);

  const columnDefs = [
    { field: "firstName", headerName: "First Name", flex: 0.5 },
    { field: "lastName", headerName: "Last Name", flex: 0.6 },
    { field: "companyName", headerName: "Company", flex: 0.6 },
    { field: "role", headerName: "Role", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 0.9 },
    { field: "mobileNumber", headerName: "Mobile", flex: 0.6 },
    { headerName: "Invite Date", field: "invitedAt", flex: 0.6 ,
      valueFormatter: (params) => formatDateOnly(params.value) },
    { headerName: "Last Logged In", field: "lastLoggedIn", flex: 0.6 ,
      valueFormatter: (params) => formatTimestamp(params.value) },
    { field: "isProfileComplete", headerName: "Complete", flex: 0.4},
    { field: "enabled", headerName: "Enabled", flex: 0.4},
    ];

    return (
      <>
      <div className="users-topbar-elevated">
        <div className="users-heading-group">
          <h2>Users</h2>
          <span className="user-count">Showing {filteredRowData.length} user{filteredRowData.length !== 1 && 's'}</span>
        </div>
        <button
          className="toggle-disabled-button"
          onClick={() => setShowDisabledUsers(prev => !prev)}
        >
          {showDisabledUsers ? "Hide Disabled Users" : "Show All Users"}
        </button>
      </div>

      <div className="settings-users-container">
        {selectedUser ? (
        // <>
          <ProfileForm user={selectedUser} onClose={() => setSelectedUser(null)} role={selectedUser.role}  onUpdate={setSelectedUser}/>
        // </>
        ) : (
          <div className="ag-theme-alpine" style={{ height: '80vh', width: '100%' }}>
            {/* <div className="users-heading">
              <h2>Users</h2>
            </div> */}
            <AgGridReact
              rowData={filteredRowData}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={paginationPageSize}
              suppressCellSelection={true} 
              suppressCellFocus={true}
              onRowClicked={handleRowClick}
            />
          </div>
        )}
      </div>
      </>
    );
} 

export default SettingsUsers;
