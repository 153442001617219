import React, { useState, useEffect } from "react";
import { updateUserProfile, fetchUserByEmail } from "../services/dbService";
import ChangePassword from "../components/ChangePassword";
import styles from "../styles/ProfileEdit.module.css";

const AgentEditProfile = ({ userDetails }) => {
  const [submitMessage, setSubmitMessage] = useState("");
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [isPasswordChanging] = useState(false);

  const [formData, setFormData] = useState({
    allowEmail: userDetails?.allowEmail !== undefined ? userDetails.allowEmail : true,
    allowNotifications: userDetails?.allowNotifications !== undefined ? userDetails.allowNotifications : true,
    ...userDetails // Preserve other profile fields
  });
    
  
  useEffect(() => {
    const fetchLatestUserData = async () => {
      if (!userDetails?.email) return;
  
      try {
        const updatedUser = await fetchUserByEmail(userDetails.email); // ✅ Get latest data from Firestore
        if (updatedUser) {
          setFormData({
            ...updatedUser,
            allowEmail: updatedUser.allowEmail ?? true,
            allowNotifications: updatedUser.allowNotifications ?? true,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchLatestUserData(); // ✅ Always fetch fresh data when component mounts
  }, [userDetails?.email]); // ✅ Runs when the user profile is opened
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value, // Handles checkboxes properly
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage("");
  
    try {
      await updateUserProfile(formData.email, formData, userDetails.email);
      
      setSubmitMessage("Profile updated successfully!");
      setTimeout(() => setSubmitMessage(""), 3000);

    } catch (error) {
      setSubmitMessage("Failed to update profile." + error);
      setTimeout(() => setSubmitMessage(""), 3000);
    }
  };
  
  return (
    <div className={styles.container}>
      <h2>Edit Profile</h2>

      <div className={styles.profileEditSubheadingRow}>
        <div className={styles.profileEditSubHeading}>
          Make changes to your profile information, email sending, notifications, and password
        </div>
        <div className={styles.profileEditSubheadingButtonContainer}>
          <button 
            type="button" 
            className={styles.profileEditSmallButton} 
            onClick={() => setShowPasswordForm((prev) => !prev)}
          >
            {showPasswordForm ? "Cancel" : "Change Password"}
          </button>
        </div>
      </div>

      {showPasswordForm ? (
        <ChangePassword setShowPasswordForm={setShowPasswordForm} />
      ) : (

        <form onSubmit={handleSubmit} className={styles.form}>
          {/* Company Information */}
          <fieldset className={styles.profileEditFieldset}>
            <legend className={styles.legend}>Company Information</legend>
            <div className={`${styles.row} ${styles.threeColumns}`}>
              <div>
                <label className={styles.label}>Company Name</label>
                <input type="text" className={styles.profileInput} name="companyName" value={formData.companyName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Company Address</label>
                <input type="text" className={styles.profileInput} name="companyAddress" value={formData.companyAddress || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Town / City</label>
                <input type="text" className={styles.profileInput} name="companyCity" value={formData.companyCity || ""} onChange={handleChange} required />
              </div>
            </div>

            <div className={`${styles.row} ${styles.threeColumns}`}>
              <div>
                <label className={styles.label}>Postcode</label>
                <input type="text" className={styles.profileInput} name="companyPostcode" value={formData.companyPostcode || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Office Phone Number</label>
                <input type="text" className={styles.profileInput} name="officePhoneNumber" value={formData.officePhoneNumber || ""} onChange={handleChange} required />
              </div>
            </div>
          </fieldset>

          {/* Banking Information */}
          <fieldset className={styles.profileEditFieldset}>
            <legend className={styles.legend}>Banking Information</legend>
            <div className={`${styles.row} ${styles.threeColumns}`}>
              <div>
                <label className={styles.label}>Bank Name</label>
                <input type="text" className={styles.profileInput} name="paymentBankName" value={formData.paymentBankName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Account Number</label>
                <input type="text" className={styles.profileInput} name="paymentAccountNumber" value={formData.paymentAccountNumber || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Sort Code</label>
                <input type="text" className={styles.profileInput} name="paymentSortCode" value={formData.paymentSortCode || ""} onChange={handleChange} required />
              </div>
            </div>
          </fieldset>

          {/* Personal Information */}
          <fieldset className={styles.profileEditFieldset}>
            <legend className={styles.legend}>Personal Information</legend>
            <div className={`${styles.row} ${styles.fourColumns}`}>
              <div>
                <label className={styles.label}>Company Contact First Name</label>
                <input type="text" className={styles.profileInput} name="firstName" value={formData.firstName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Company Contact Last Name</label>
                <input type="text" className={styles.profileInput} name="lastName" value={formData.lastName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Email Address</label>
                <input type="email" className={styles.profileInput} name="email" value={formData.email || ""} onChange={handleChange} required />
              </div>
            </div>
          </fieldset>

          {/* Terms and Conditions */}
          <div className={styles.notificationPreferences}>

            {/* Send Email */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowEmail" name="allowEmail" 
                checked={formData.allowEmail ?? true} onChange={handleChange} />
              <label htmlFor="allowEmail" className={styles.profileEditLabel}>
                Allow Email: <span className={styles.profileEditInfo}>The site will send you emails based on activity connected to you.</span>
              </label>
            </div>

            {/* Allow Notifications */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowNotifications" name="allowNotifications" 
                checked={formData.allowNotifications ?? true} onChange={handleChange} />
              <label htmlFor="allowNotifications" className={styles.profileEditLabel}>
                Allow Notifications: <span className={styles.profileEditInfo}>When there is new activity, a new notification in the website will appear.</span>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className={styles.profileEditsubmitContainer}>
            {submitMessage && <div className={styles.submitMessage}>{submitMessage}</div>}
            <button type="submit" className={styles.profileEditButton} 
              disabled={isPasswordChanging}>
              Save Changes
            </button>
          </div>

        </form>
      )}
    </div>
  );
};

export default AgentEditProfile;
