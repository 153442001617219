import React from "react";
import { NavLink } from "react-router-dom";

const ContractorNavLinks = ({ menuLinksRef, onHover, unreadCount, onDashboardClick, onNotificationClick }) => (
  <>
    <li>
      <NavLink to="/contractor-dashboard" ref={(el) => (menuLinksRef.current[0] = el)} onMouseEnter={onHover} onClick={onDashboardClick}>
        <svg> <use xlinkHref="#pages"></use> </svg>
        <span>Dashboard</span>
      </NavLink>
    </li>
    <li>
      <NavLink to="/notifications" ref={(el) => (menuLinksRef.current[1] = el)} onMouseEnter={onHover} onClick={onNotificationClick}>
        <svg> <use xlinkHref="#notifications"></use> </svg>
        <span>Notifications</span>
        {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
        </NavLink>
    </li>

      <li>
        <NavLink to="/profile-edit" ref={(el) => (menuLinksRef.current[1] = el)} onMouseEnter={onHover} onClick={onNotificationClick}>
          <svg> <use xlinkHref="#users"></use></svg>
          <span>Edit Profile</span>
        </NavLink>
      </li>

  </>
);

export default ContractorNavLinks;
