import React, { useState } from 'react';
import Header from '../components/UI/Header';
import SideNav from '../components/UI/SideNav';
import AdministrationContent from './AdministrationContent';

const MainLayout = ({ isCollapsed, setIsCollapsed, isDarkMode, toggleDarkMode, isContractor, }) => {
  const [dashboardKey, setDashboardKey] = useState(0); 
  const [notificationKey, setNotificationKey] = useState(0);
  const [addUserKey, setAddUserKey] = useState(0);
  const [usersKey, setUsersKey] = useState(0);
  const [tradesKey, setTradesKey] = useState(0);
  const [pqwKey, setPqwKey] = useState(0);

  const handleDashboardClick = () => {
    setDashboardKey((prevKeyD) => prevKeyD + 1); 
  };
  
  const handleNotificationClick = () => {
    setNotificationKey((prevKeyN) => prevKeyN + 1);
  };

  const handleAddUserClick = () => {
    setAddUserKey((prevKeyAU) => prevKeyAU + 1);
  }

  const handleUsersClick = () => {
    setUsersKey((prevKeyU) => prevKeyU + 1);
  }

  const handleTradesClick = () => {
    setTradesKey((prevKeyT) => prevKeyT + 1);
  }

  const handlePQWClick = () => {
    setPqwKey((prevKeyP) => prevKeyP + 1);
  }

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <Header />
      <SideNav
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isContractor={isContractor}
        onNotificationClick={handleNotificationClick}
        onDashboardClick={handleDashboardClick}
        onAddUserClick={handleAddUserClick}
        onUsersClick={handleUsersClick}
        onTradesClick={handleTradesClick}
        onPQWClick={handlePQWClick}
      />
      <AdministrationContent 
        notificationKey={notificationKey}
        dashboardKey={dashboardKey}
        addUserKey={addUserKey}
        usersKey={usersKey}
        tradesKey={tradesKey}
        pqwKey={pqwKey}
        isCollapsed={isCollapsed}
        isContractor={isContractor}
      />
    </div>
  );
};

export default MainLayout;
