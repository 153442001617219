import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatDateOnly } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const JobCompletedClosed = ({ isSingleColumn, onRowSelectAction }) => {
  const [closedJobs, setClosedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const loadClosedJobs = useCallback(async () => {
    try {
      // console.log("🔄 Fetching Job Completed & Closed...");
      const options = { status: 7 };
      const jobs = await fetchJobs(options);
      setClosedJobs(jobs);
    } catch (error) {
      console.error("Error loading closed jobs:", error);
    }
  }, []);

  useEffect(() => {
    const handleRefresh = () => {
      // console.log("🔄 JobCompletedClosed grid refreshing...");
      loadClosedJobs();
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadClosedJobs]);

  useEffect(() => {
    loadClosedJobs();
  }, [loadClosedJobs]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };

    onRowSelectAction(event.data, tabModes);
  };

  return (
    <GridPanel
      title="Job Completed & Closed [admin]"
      columnDefs={[
        { headerName: "JOB ID", field: "jobId", flex: 1.0 },
        { 
          headerName: "TRADE",
          field: "tradeName",
          flex: 1.7,
          cellRenderer: TradeCellRenderer,
          valueGetter: (params) =>
            params.data.tradeName === 'Other (please specify)' ? params.data.instructionNotes || 'N/A' : params.data.tradeName,
        },
        { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
        { 
          headerName: "CLOSED DATE",
          field: "completedDate",
          flex: 1.4,
          valueFormatter: (params) => formatDateOnly(params.value)
        }
      ]}
      rowData={closedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobCompletedClosed;
