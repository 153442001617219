import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { STATUS_MAPPING, fetchJobsByPropertyAndStatuses } from '../services/dbService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const LandlordContractorAllocated = ({ onRowSelectAction, propertyId }) => {
  const [contractorAllocatedJobs, setContractorAllocatedJobs] = useState([]);
  
  const loadContractorAllocatedJobs = useCallback(async () => {
    try {
      const jobs = await fetchJobsByPropertyAndStatuses(propertyId, [3, 4, 5]);
      setContractorAllocatedJobs(jobs);
    } catch (error) {
      console.error("Error fetching contractor allocated jobs:", error);
    }
  }, [propertyId]);

  useEffect(() => {
    const handleRefresh = () => {
      loadContractorAllocatedJobs(); 
  };
  
  window.addEventListener("refreshGrids", handleRefresh);
  return () => window.removeEventListener("refreshGrids", handleRefresh);

  }, [loadContractorAllocatedJobs]); 

  useEffect(() => {
    loadContractorAllocatedJobs(); 
  }, [loadContractorAllocatedJobs]);

  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "TRADE", 
      field: "tradeName",
      flex: 1.2,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)' 
          ? params.data.instructionNotes || 'N/A' 
          : params.data.tradeName;
      }
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 1.5 },
    {
      headerName: "STATUS",
      field: "status",
      flex: 1.4,
      cellRenderer: (params) => {
        const statusText = STATUS_MAPPING[String(params.value)]?.label || STATUS_MAPPING.default.label;
    
        return (
          <span>
            {statusText === "Appointment Suggested" && (
              <span style={{ color: "red", fontSize: "18px", marginRight: "5px" }}>●</span>
            )}
            {statusText}
          </span>
        );
      }
    }
    
  ];
  
  const handleRowClick = (jobData) => {
    const tabModes = {
      Appointments: 'readWrite',
      Quote: 'readOnly',
      Detail: 'readOnly',
      Comments: 'readWrite',
      Property: 'hide',
    };

    if (jobData.data) {
      onRowSelectAction(jobData.data,tabModes,);
    } else {
      onRowSelectAction(jobData,tabModes,);
    }
  };

  return (
    <GridPanel
      title="Contractor Allocated [landlord]" // 3
      rowData={contractorAllocatedJobs}
      columnDefs={columnDefs}
      onRowClick={handleRowClick}
    />
  );
};

export default LandlordContractorAllocated;
