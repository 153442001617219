import React, { useState, useEffect } from 'react';
import JobTabs from '../Job/JobTabs';
import JobsToPrice from '../Panels/JobsToPrice';
import QuotedWaitingOnLLAcceptance from '../Panels/QuotedWaitingOnLLAcceptance'
import JobApprovedAwaitingContractor from '../Panels/JobApprovedAwaitingContractor'
import JobsAvailable from '../Panels/JobsAvailable';
import JobsAccepted from '../Panels/JobsAccepted';
import StartDateOffered from '../Panels/StartDateOffered';
import DateAcceptedAwaitingStartDate from '../Panels/DateAcceptedAwaitingStartDate';
import JobInProgress from '../Panels/JobInProgress';
import JobCompletedInvoiceSent from '../Panels/JobCompletedInvoiceSent';
import JobCompletedClosed from '../Panels/JobCompletedClosed';
import JobCancelled from '../Panels/JobCancelled';
import { db } from '../firebase';
import { doc, onSnapshot, } from 'firebase/firestore';

const Dashboard = () => {
  const [selectedJob, setSelectedJob] = useState(null); // Track the selected job
  const [selectedTab, setSelectedTab] = useState(null);
  const [tabModes, setTabModes] = useState({});
  const [refreshKey, setRefreshKey] = useState(0); // Add a state to track refreshes

  useEffect(() => {
    const updatesRef = doc(db, "updates", "admin");

    const unsubscribe = onSnapshot(updatesRef, (snapshot) => {
      const data = snapshot.data();
      if (data?.lastUpdated) {
        // console.log("🔄 Admin dashboard detected update:", data.lastUpdated);
        window.dispatchEvent(new Event("refreshGrids"));
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  useEffect(() => {
    setSelectedJob(null); // Ensures the default view loads
  }, []);

  const handleRowSelected = (jobData, tabModes, selectedTab) => {
    setSelectedJob(jobData);
    setTabModes(tabModes);
    setSelectedTab(selectedTab);
  };

  const handleDashboardClick = () => {
    setSelectedJob(null); // Reset selectedJob to show top-level Dashboard
  };

  const refreshGrid = () => {
    setRefreshKey((prevKey) => prevKey + 1); // Increment refreshKey to trigger re-render
  };

  return (
    <div>
      {selectedJob ? ( // Show JobTabs when a job is selected
           <JobTabs jobData={selectedJob} onReturnToDashboard={handleDashboardClick} tabModes={tabModes} tab={selectedTab}/>
      ) : (
        // Default dashboard content
        <>
          <div className="dashboard-container two-columns">
              <JobsToPrice isSingleColumn={false} onRowSelectAction={handleRowSelected} selectedJob={selectedJob} selectedTab={selectedTab}/>
              <QuotedWaitingOnLLAcceptance isSingleColumn={false} onRowSelectAction={handleRowSelected} selectedJob={selectedJob} />
          </div>

          <div className="dashboard-container">
            <JobApprovedAwaitingContractor key={refreshKey} isSingleColumn={true} onRowSelectAction={handleRowSelected} selectedJob={selectedJob} refreshGrid={refreshGrid}/>
          </div>

          <div className="dashboard-container two-columns">
            <JobsAvailable key={refreshKey} isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
            <JobsAccepted isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
          </div>

          <div className="dashboard-container two-columns">
            <StartDateOffered isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
            <DateAcceptedAwaitingStartDate isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
          </div>

          <div className="dashboard-container two-columns">
            <JobInProgress isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
            <JobCompletedInvoiceSent isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
          </div>

          <div className="dashboard-container two-columns">
            <JobCompletedClosed isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
            <JobCancelled isSingleColumn={false} onRowSelectAction={handleRowSelected } selectedJob={selectedJob} />
          </div>

        </>
      )}

    </div>
  );
};

export default Dashboard;
