import React from 'react';

/**
 * RequestQuotePanel Component
 * Displays the "Request a Quote" section of the landlord dashboard.
 */
const RequestQuotePanel = ({ property, handleRAQClick }) => {
  return (
    <div className="request-quote-panel">
      <button
        className="quote-button"
        onClick={() => {
          handleRAQClick('JobRequest', property.propertyId, property.agentId);
        }}
      >
        Request a Quote
      </button>
      <div className="request-quote-panel-description">
        Here you can request a quote on all types of trades work on your property.
        The request will be sent to our team for evaluation and a quote will be sent by return to you.
      </div>
    </div>
  );
};

export default RequestQuotePanel;
