import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { formatDateOnly } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { collection, query, where, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const AwaitingApproval = ({ onRowSelectAction, propertyId}) => {
  const [awaitingApprovalJobs, setAwaitingApprovalJobs] = useState([]);  
  const loadJobsAwaitingApproval = useCallback(async () => {
    try {
      const jobs = await fetchJobsAwaitingLandlordQuoteAcceptanceByPropertyId(propertyId, 1);
      setAwaitingApprovalJobs(jobs);
    } catch (error) {
      console.error(`Error loading jobs waiting to be approved: for landlord`, error);
    }
  }, [propertyId]);

  useEffect(() => {
    const handleRefresh = () => {
      loadJobsAwaitingApproval(); 
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadJobsAwaitingApproval]); 

  useEffect(() => {
    loadJobsAwaitingApproval(); 
  }, [loadJobsAwaitingApproval]);

  const columnDefs = [
      { headerName: "JOB ID", field: "jobId", flex: 1.0 },
      { headerName: "TRADE", 
        field: "tradeName",
        flex: 1.6,
        filter: true,
        cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
        valueGetter: (params) => {
          return params.data.tradeName === 'Other (please specify)' 
            ? params.data.instructionNotes || 'N/A' 
            : params.data.tradeName;
        }
      },
      { headerName: "DESCRIPTION", field: "jobDetail", flex: 2 },
      { headerName: "QUOTE DATE", field: "createdAt", flex: 1.4,
        valueFormatter: (params) => formatDateOnly(params.value) }

  ];

  const handleRowClick = (jobData) => {
    const tabModes = {
      Detail: 'readOnly',
      Quote: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };

    onRowSelectAction(jobData, tabModes, "Detail");

  };

  return (
    <GridPanel
      title="(Quote Provided Awaiting Acceptance and Payment) Awaiting Approval [landlord]"
      rowData={awaitingApprovalJobs}
      columnDefs={columnDefs}
      onRowClick={handleRowClick}
    />
  );
};

export default AwaitingApproval;


/**
 * Fetch jobs for a specific property and status.
 * @param {string} propertyId - The ID of the property.
 * @param {number} status - The status of the jobs to fetch.
 * @returns {Promise<Array>} - Array of jobs matching the criteria.
 */
export const fetchJobsAwaitingLandlordQuoteAcceptanceByPropertyId = async (propertyId) => {
  try {
    const jobsRef = collection(db, 'jobs');

    // Fetch jobs with status 0 or 1
    const jobsQuery = query(
      jobsRef,
      where('propertyId', '==', propertyId),
      where('status', 'in', [0, 1]),
      orderBy('createdAt')
    );

    const querySnapshot = await getDocs(jobsQuery);
    const filteredJobs = [];

    for (const docSnap of querySnapshot.docs) {
      const jobData = { jobId: docSnap.id, ...docSnap.data() };

      if (jobData.status === 1) {
        // ✅ Always include status 1
        filteredJobs.push(jobData);
      } else {
        // ✅ Only include status 0 if a quote signpost exists
        const signpostSnap = await getDoc(doc(db, 'quotes', jobData.jobId));
        if (signpostSnap.exists()) {
          filteredJobs.push(jobData);
        }
      }
    }

    return filteredJobs;
  } catch (error) {
    console.error('Error fetching landlord approval jobs:', error);
    return [];
  }
};
