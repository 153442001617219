import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase"; // Adjust the path to your Firebase configuration
import { fetchUserByEmail } from "../services/dbService";

// Callable reference to the Firebase Cloud Function
const sendEmail = httpsCallable(functions, "sendEmail");
const bypassSending = false; // SENDING DISBALED (= true)

// Function to send a notification email
export const sendNotificationEmail = async ({ to, subject, text, html }) => {
  console.log({ to, subject, text, html }); // Debugging: Log the email payload

  if (!to || !subject || !html) {
    console.error("sendNotificationEmail: Missing required parameters.");
    return;
  }

  try {

    // 🔹 Fetch user data dynamically
    const userData = await fetchUserByEmail(to);

    // 🔹 Ensure allowEmail is checked, default to true if missing
    const allowEmail = userData?.allowEmail ?? true;
    if (!allowEmail) {
      console.warn(`🚫 Email sending disabled for: ${to}`);
      return;
    }

    if (!bypassSending) { // bypassSending is a convenience override on sending emails
      // Use the callable function to send the email
      const response = await sendEmail({
        to,
        subject,
        text,  // Plain-text version
        html,  // HTML version
      });

      console.log("sendNotificationEmail: Email sent successfully.", response);

    } else {
      console.log('EMAIL SENDING DISBALED IN EMAILCLIENT.JS');
    }

  } catch (error) {
    console.error("sendNotificationEmail: Error sending email:", error);
  }
};
