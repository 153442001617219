import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { db } from '../firebase';
import { collection, query, where, getDocs, Timestamp, doc, getDoc} from 'firebase/firestore';
import { useUser } from "../context/UserContext";

const ContractorProvideQuote = ({ onRowSelectAction }) => {
  const [jobsToQuote, setJobsToQuote] = useState([]);
  const userX = useUser();  
    
  const loadJobsToPrice = useCallback(async () => {
    try {
      const contractorId = userX.user.email;
      const jobs = await fetchJobsForContractorToQuote(contractorId);
      setJobsToQuote(jobs);
    } catch (error) {
      console.error("JobsToPrice: Error fetching jobs to price:", error);
    }
  }, [userX]);

  useEffect(() => {
    const handleRefresh = () => {
      loadJobsToPrice(); 
    };
  
    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadJobsToPrice]); 
  
  useEffect(() => {
    loadJobsToPrice(); 
  }, [loadJobsToPrice]);

  const getRowStyle = (params) => {
    if (params.data.quoted) {
      return { background: "#f0f0f0", color: "#888" }; // Grey out quoted rows
    }
    return {};
  };
  
  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    { headerName: "TRADE", field: "tradeName", flex: 1.5, filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "STATUS", field: "quoted", flex: 1, filter: true,
      cellRenderer: (params) => params.value ? "🔹 Quoted" : "To Quote", 
      cellStyle: (params) => params.value ? { color: "#888", fontWeight: "bold" } : {},
    }
  ];

  const handleRowClick = (event) => {
    const jobData = event.data;
    const tabModes =  {
        Quote: 'readWrite',
        Detail: 'readOnly',
        Comments: 'readOnly',
        Appointments: 'hide',
        Property: 'hide',
      }
      const isProvidingQuote = true;
      onRowSelectAction(jobData, tabModes, 'Detail', isProvidingQuote);
    };

  return (
    <GridPanel
      title="Jobs Requiring Quotes [contractor]" 
      columnDefs={columnDefs}
      rowData={jobsToQuote}
      onRowClick={handleRowClick}
      getRowStyle={getRowStyle}
    />
  );
};  

export default ContractorProvideQuote;


/**
 * Fetches jobs available for a contractor to quote on.
 *
 * Logic:
 * 1️⃣ Jobs where `status = 0` AND `createdAt` is more than 1 hour ago.
 * 2️⃣ Jobs where `status = 1` AND the contractor has already submitted a quote.
 *
 * @param {string} contractorId - The contractor's email or ID.
 * @returns {Promise<Array>} - A list of jobs the contractor can quote on.
 */
export const fetchJobsForContractorToQuote = async (contractorId) => {
  try {
    const oneHourAgo = Timestamp.fromDate(new Date(Date.now() - 60 * 60 * 1000));
    const jobsRef = collection(db, "jobs");
    const jobsQuery = query(jobsRef, //where(
      // "status", "==", 0), 
      where("status", "in", [0, 1]),
      where("createdAt", "<", oneHourAgo),
    );

    const jobsSnapshot = await getDocs(jobsQuery);
    const jobs = jobsSnapshot.docs.map(doc => ({ jobId: doc.id, ...doc.data() }));

    const results = [];

    for (const job of jobs) {
      const signpostRef = doc(db, "quotes", job.jobId);
      const signpostSnap = await getDoc(signpostRef);
    
      const contractorQuoteIds = signpostSnap.exists() ? (signpostSnap.data().contractorQuoteIds || []) : [];
    
      let hasQuoted = false;
      let myQuoteId = null;

      if (contractorQuoteIds.length > 0) {
        const quoteDocs = await Promise.all(
          contractorQuoteIds.map(id => getDoc(doc(db, "quotes", id)))
        );
    
        const myQuoteDoc = quoteDocs.find(docSnap =>
          docSnap.exists() && docSnap.data().contractorId === contractorId
        );
        
        hasQuoted = !!myQuoteDoc;
        myQuoteId = hasQuoted ? myQuoteDoc.id : null;
      }
    
      if (job.status === 1 && !hasQuoted) {
        continue; // Don't let contractors see status 1 jobs they didn't quote on
      }


      results.push({
        ...job,
        quoted: hasQuoted,
        myQuoteId: myQuoteId
      });
    }
    
    return results;
  } catch (error) {
    console.error("Error fetching jobs for contractor to quote:", error);
    return [];
  }
};
