import React, { useEffect, useState, useCallback } from 'react';
import { auth } from '../firebase';
import GridPanel from '../components/GridPanel';
import { fetchJobsByAgentAndStatuses } from '../services/jobService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const AgentJobsAccepted = ({ onRowSelectAction }) => {
  const [approvedJobs, setApprovedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 1 },
    {headerName: "ADDRESS",
      valueGetter: (params) => `${params.data.propertyDetails?.address || ''}, ${params.data.propertyDetails?.postcode || ''}`,
      flex: 4,
    },
    { headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 2.0,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
  ];  

    const loadApprovedJobs = useCallback(async () => {
      try {
        setLoading(true);
        const agentId = auth.currentUser?.email;
  
        // Ensure agentId exists
        if (!agentId) {
          throw new Error("No agent email found for the current user.");
        }
        // Fetch jobs with statuses [2, 3] for the agent
        const jobs = await fetchJobsByAgentAndStatuses(agentId, [2, 3, 4, 5, 6]);
  
        setApprovedJobs(jobs);
      } catch (error) {
        console.error("Error fetching approved jobs for agent:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }, []);
  
  useEffect(() => {
    const handleRefresh = () => {
      loadApprovedJobs(); 
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadApprovedJobs]); 

  useEffect(() => {
    loadApprovedJobs(); 
  }, [loadApprovedJobs]);

  const handleRowClick = (jobData) => {
    const tabModes = {
      Quote: 'readOnly',
      Comments: 'readOnly',
      Detail: 'readOnly',
      Appointments: 'readOnly',
      Property: 'readOnly',
    };

    if (jobData.data) {
      onRowSelectAction(jobData.data,tabModes,);
    } else {
      onRowSelectAction(jobData,tabModes,);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading approved jobs: {error.message}</p>;

  return (
    <GridPanel 
    title="Quotes Accepted - Awaiting Completion By Contractor [agent]" 
    rowData={approvedJobs} 
    columnDefs={columnDefs} 
    onRowClick={handleRowClick} />
  )
};

export default AgentJobsAccepted;
