import React from 'react';
import { NavLink } from "react-router-dom";

const AdminNavLinks = ({ menuLinksRef, onHover, unreadCount, 
  onDashboardClick, onNotificationClick, onAddUserClick,
  onUsersClick, onTradesClick, onPQWClick
}) => {
 
  return (
      <>
      <li>
        <NavLink to="/dashboard" ref={(el) => (menuLinksRef.current[0] = el)} onMouseEnter={onHover} onClick={onDashboardClick}>
          <svg><use xlinkHref="#pages"></use></svg>
          <span>Dashboard</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/notifications" ref={(el) => (menuLinksRef.current[1] = el)} onMouseEnter={onHover} onClick={onNotificationClick}>
          <svg><use xlinkHref="#notifications"></use></svg>
          <span>Notifications</span>
          {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
          </NavLink>
      </li>
  
      {/* <li>
        <NavLink to="/settings" ref={(el) => (menuLinksRef.current[2] = el)} onMouseEnter={onHover}>
          <svg> <use xlinkHref="#settings"></use> </svg>
          <span>Settings</span>
        </NavLink>
      </li> */}

      <li>
        <NavLink to="/adduser" ref={(el) => (menuLinksRef.current[3] = el)} onMouseEnter={onHover} onClick={onAddUserClick}>
          <svg> <use xlinkHref="#users"></use> </svg>
          <span>Add User</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/users" ref={(el) => (menuLinksRef.current[4] = el)} onMouseEnter={onHover} onClick={onUsersClick}>
          <svg> <use xlinkHref="#users"></use> </svg>
          <span>Users</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/trades" ref={(el) => (menuLinksRef.current[5] = el)} onMouseEnter={onHover} onClick={onTradesClick}>
          <svg> <use xlinkHref="#settings"></use> </svg>
          <span>Trades</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/pqw" ref={(el) => (menuLinksRef.current[6] = el)} onMouseEnter={onHover} onClick={onPQWClick}>
          <svg> <use xlinkHref="#settings"></use> </svg>
          <span>Pre Quoted Works</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/profile-edit" ref={(el) => (menuLinksRef.current[1] = el)} onMouseEnter={onHover} onClick={onNotificationClick}>
          <svg> <use xlinkHref="#users"></use></svg>
          <span>Edit Profile</span>
        </NavLink>
      </li>

    </>
    );
};

export default AdminNavLinks;
