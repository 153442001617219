import React, { useState, useEffect } from "react";
import { updateUserProfile, fetchUserByEmail } from "../services/dbService";
import { getDocs, collection } from 'firebase/firestore'; 
import { db } from '../firebase'; // Firebase instances
import ChangePassword from "../components/ChangePassword";
import styles from "../styles/ProfileEdit.module.css";

const ContractorEditProfile = ({ userDetails }) => {
  const [submitMessage, setSubmitMessage] = useState("");
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [isPasswordChanging] = useState(false);
  const [trades, setTrades] = useState([]); // Holds trades collection data

  const [formData, setFormData] = useState({
    allowEmail: userDetails?.allowEmail !== undefined ? userDetails.allowEmail : true,
    allowNotifications: userDetails?.allowNotifications !== undefined ? userDetails.allowNotifications : true,
    ...userDetails // Preserve other profile fields
  });

    // Handle trade selection to set tradeId
    const handleTradeChange = (e) => {
      const selectedTrade = trades.find((trade) => trade.tradename === e.target.value);
      setFormData((prev) => ({
        ...prev,
        tradesName: e.target.value,
        tradesId: selectedTrade?.id || null,
      }));
    };

    // Fetch trade names and IDs from Firestore
    useEffect(() => {
      const fetchTrades = async () => {
        try {
          const tradeSnapshot = await getDocs(collection(db, "trades"));
          const tradesList = tradeSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTrades(tradesList);
        } catch (error) {
          console.error("Error fetching trades:", error);
        }
      };
  
      fetchTrades();
    }, []);
  

    useEffect(() => {
      const fetchLatestUserData = async () => {
        if (!userDetails?.email) return;
    
        try {
          const updatedUser = await fetchUserByEmail(userDetails.email); // ✅ Get latest data from Firestore
          if (updatedUser) {
            setFormData({
              ...updatedUser,
              allowEmail: updatedUser.allowEmail ?? true,
              allowNotifications: updatedUser.allowNotifications ?? true,
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
    
      fetchLatestUserData(); // ✅ Always fetch fresh data when component mounts
    }, [userDetails?.email]); // ✅ Runs when the user profile is opened
    
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value, // Handles checkboxes properly
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage("");
  
    try {
      await updateUserProfile(formData.email, formData, userDetails.email);

      setSubmitMessage("Profile updated successfully!");
      setTimeout(() => setSubmitMessage(""), 3000);

    } catch (error) {
      setSubmitMessage("Failed to update profile." + error);
      setTimeout(() => setSubmitMessage(""), 3000);
    }
  };
  
  return (
    <div className={styles.container}>
      <h2>Edit Profile</h2>

      <div className={styles.profileEditSubheadingRow}>
        <div className={styles.profileEditSubHeading}>
          Make changes to your profile information, email sending, notifications, and password
        </div>
        <div className={styles.profileEditSubheadingButtonContainer}>
          <button 
            type="button" 
            className={styles.profileEditSmallButton} 
            onClick={() => setShowPasswordForm((prev) => !prev)}
          >
            {showPasswordForm ? "Cancel" : "Change Password"}
          </button>
        </div>
      </div>

      {showPasswordForm ? (
        <ChangePassword setShowPasswordForm={setShowPasswordForm} />
      ) : (

        <form onSubmit={handleSubmit} className={styles.form}>

        {/* Personal Information */}
        <fieldset className={styles.profileEditFieldset}>
          <legend className={styles.legend}>Personal Information</legend>
          <div className={styles.row}>
            <div>
              <label className={styles.label}>First Name</label>
              <input type="text" className={styles.profileInput} value={formData.firstName || ""} name="firstName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Last Name</label>
              <input type="text" className={styles.profileInput} value={formData.lastName || ""} name="lastName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Mobile Number</label>
              <input type="text" className={styles.profileInput} value={formData.mobileNumber || ""} name="mobileNumber" onChange={handleChange} required />
            </div>
          </div>

          <div className={styles.row}>
            <div>
              <label className={styles.label}>Email Address</label>
              <input type="email" className={styles.profileInput} value={formData.email || ""} name="email" readOnly />
            </div>
          </div>

        </fieldset>

        {/* Company Information */}
        <fieldset className={styles.profileEditFieldset}>
          <legend className={styles.legend}>Company Information</legend>
          <div className={styles.row}>
            <div>
              <label className={styles.label}>Company Name</label>
              <input type="text" className={styles.profileInput} value={formData.companyName || ""} name="companyName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Company Address</label>
              <input type="text" className={styles.profileInput} value={formData.companyAddress || ""} name="companyAddress" onChange={handleChange} required />
            </div>

            <div>
              <label className={styles.label}>Town / City</label>
              <input type="text" className={styles.profileInput} value={formData.companyCity || ""} name="companyCity" onChange={handleChange} required />
            </div>

            <div>
              <label className={styles.label}>Company Postcode</label>
              <input type="text" className={styles.profileInput} value={formData.companyPostcode || ""} name="companyPostcode" onChange={handleChange} required />
            </div>

          </div>

          <div className={styles.row}>
            <div>
              <label className={styles.label}>Office Phone Number</label>
              <input type="text" className={styles.profileInput} value={formData.officePhoneNumber || ""} name="officePhoneNumber" onChange={handleChange} required />
            </div>
          </div>


          <div className={styles.row}>
            <div>
              <label className={styles.label}>Bank Name</label>
              <input type="text" className={styles.profileInput} value={formData.paymentBankName || ""} name="paymentBankName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Account Number</label>
              <input type="text" className={styles.profileInput} value={formData.paymentAccountNumber || ""} name="paymentAccountNumber" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Sort Code</label>
              <input type="text" className={styles.profileInput} value={formData.paymentSortCode || ""} name="paymentSortCode" onChange={handleChange} required />
            </div>
          </div>
        </fieldset>

        {/* Trade Information */}
        <fieldset className={styles.profileEditFieldset}>
          <legend className={styles.legend}>Trade Information</legend>
          <div className={styles.row}>
            <div>
              <label className={styles.label}>Trade Name</label>
              <select className={styles.profileInput} name="tradesName" value={formData.tradesName || ""} onChange={handleTradeChange} required>
                <option value="">Select Trade</option>
                {trades.map((trade) => (<option key={trade.id} value={trade.tradename}>{trade.tradename}</option>))}
              </select>
            </div>
          </div>
        </fieldset>

          {/* Terms and Conditions */}
          <div className={styles.notificationPreferences}>

            {/* Send Email */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowEmail" name="allowEmail" 
                checked={formData.allowEmail ?? true} onChange={handleChange} />
              <label htmlFor="allowEmail" className={styles.profileEditLabel}>
                Allow Email: <span className={styles.profileEditInfo}>The site will send you emails based on activity connected to you.</span>
              </label>
            </div>

            {/* Allow Notifications */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowNotifications" name="allowNotifications" 
                checked={formData.allowNotifications ?? true} onChange={handleChange} />
              <label htmlFor="allowNotifications" className={styles.profileEditLabel}>
                Allow Notifications: <span className={styles.profileEditInfo}>When there is new activity, a new notification in the website will appear.</span>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className={styles.profileEditsubmitContainer}>
            {submitMessage && <div className={styles.submitMessage}>{submitMessage}</div>}
            <button type="submit" className={styles.profileEditButton} 
              disabled={isPasswordChanging}>
              Save Changes
            </button>
          </div>

        </form>
      )}
    </div>
  );
};

export default ContractorEditProfile;
