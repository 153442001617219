import { useEffect, useState } from "react";
import { db } from "../firebase"; // Adjust import based on your project structure
import { collection, query, where, getDocs } from "firebase/firestore";
import styles from "../styles/AppointmentForm.module.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const ContractorAppointmentReadOnly = ({ jobId }) => {

  const [appointmentData, setAppointmentData] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAppointmentData = async () => {
      try {
        const appointmentsRef = collection(db, "appointments");
        const q = query(appointmentsRef, where("jobId", "==", jobId));
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
          setAppointmentData(querySnapshot.docs[0].data());
        } else {
          console.warn(`No appointment found for jobId: ${jobId}`);
        }
      } catch (error) {
        console.error("Error fetching appointment data:", error);
      }
    };

    const fetchJobDetails = async () => {
      try {
        const jobsRef = collection(db, "jobs");
        const q = query(jobsRef, where("jobId", "==", jobId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setJobDetails(querySnapshot.docs[0].data());
        } else {
          console.warn(`No job details found for jobId: ${jobId}`);
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };


    const fetchQuoteDetails = async () => {
      try {
        const quotesRef = collection(db, "quotes");
        const q = query(quotesRef, where("jobId", "==", jobId));
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
          setQuoteDetails(querySnapshot.docs[0].data());
        } else {
          console.warn(`No quote details found for jobId: ${jobId}`);
        }
      } catch (error) {
        console.error("Error fetching quote details:", error);
      }
    };

    const fetchAllData = async () => {
      await Promise.all([fetchAppointmentData(), fetchJobDetails(), fetchQuoteDetails()]);
      setLoading(false);
    };

    fetchAllData();
  }, [jobId]);

  if (loading) return <p>Loading appointment details...</p>;

  if (!appointmentData || appointmentData.dates.length === 0) {
    return <p className={styles.noDatesText}>No appointment dates suggested yet.</p>;
  }

  return (
    <>
      <h2>{jobId}: Start Date Offered</h2>

      {/* Job Details (Same Layout, Read-Only) */}
      <div className={styles.jobDetails}>
        <div className={styles.jobDetailRow}>
          <div className={styles.formGroup}>
            <label><strong>Trade:</strong></label>
            <input type="text" value={jobDetails?.tradeName || "N/A"} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label><strong>Job Description:</strong></label>
            <input type="text" value={jobDetails?.jobDetail || "N/A"} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label><strong>Further Information:</strong></label>
            <input type="text" value={jobDetails?.furtherDetails || "N/A"} readOnly />
          </div>
        </div>
        <div className={styles.jobDetailRow}>
          <div className={styles.formGroup}>
            <label><strong>Lead Time Required:</strong></label>
            <input type="text" value={quoteDetails?.leadTime || "N/A"} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label><strong>Est. Duration:</strong></label>
            <input type="text" value={quoteDetails?.duration || "N/A"} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label><strong>Quote Detail:</strong></label>
            <input type="text" value={quoteDetails?.quoteDetails || "N/A"} readOnly />
          </div>
        </div>
      </div>

      <div className={styles.container}>
        {/* Calendar - Read-Only */}
        <div className={styles.calendarContainer}>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={appointmentData.dates.map(({ date }) => ({
              title: "Suggested",
              start: new Date(date.seconds * 1000).toISOString().split("T")[0],
              color: "blue",
            }))}
            height="auto"
            aspectRatio={1.35}
            firstDay={1}
          />
        </div>

        {/* Right Panel - Read-Only Dates */}
        <div className={styles.detailsContainer}>
          <h3>The dates that have been suggested for the work</h3>
          <ul className={styles.selectedDatesList}>
            {appointmentData.dates.map(({ date, description }, index) => {
              const formattedDate = new Date(date.seconds * 1000).toLocaleDateString("en-GB");
              return (
                <li key={index} className={styles.selectedDateItem}>
                  <span>{formattedDate}</span>
                  {description && <span className={styles.descriptionText}>{description}</span>}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContractorAppointmentReadOnly;
