import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase'; // src/firebase.js
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth } from '../firebase';
import { fetchPreQuotedWorks } from '../services/dbService';
import JobTabsLandlord from '../Job/JobTabsLandlord';
import LandlordDashboardHeader from '../LandlordDashboard/LandlordDashboardHeader';
import RequestQuotePanel from '../LandlordDashboard/RequestQuotePanel';
import PreQuotedWorksPanel from '../LandlordDashboard/PreQuotedWorksPanel';
import LandlordManagePropertiesPanel from '../LandlordDashboard/LandlordManagePropertiesPanel';
import JobRequestForm from '../Job/JobRequestForm';
import PreQuotedWorkForm from '../PreQuotedWork/PreQuotedWorkForm';
import '../styles/LandlordDashboard.css';

const LandlordDashboard = () => {
  const [isJobDetailVisible, setIsJobDetailVisible] = useState(false);
  const [isRequestAQuoteVisible, setIsRequestAQuoteVisible] = useState(false);
  const [isPreQuotedWorksVisible, setIsPreQuotedWorksVisible] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [selectedPQW, setSelectedPQW] = useState(null);
  const [preQuotedWorksList, setPreQuotedWorksList] = useState([]);
  const [isMainContentVisible, setIsMainContentVisible] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null); // Holds the selected job data
  const [selectedTab, setSelectedTab] = useState(null);
  const [tabModes, setTabModes] = useState(null); // Holds tabModes for JobTabs
  const managePropertiesRef = useRef(null); // Ref for LandlordManageProperties

  // Obtain list of pre quoted work
  useEffect(() => {
    const loadPreQuotedWorks = async () => {
      try {
        const works = await fetchPreQuotedWorks();
        setPreQuotedWorksList(works);
      } catch (error) {
        console.error('LandlordDashboard: Error fetching Pre-Quoted Works:', error);
      }
    };

    loadPreQuotedWorks();
  }, []);

  // Load property details
  useEffect(() => {
    const loadProperty = async () => {
      const landlordEmail = auth.currentUser?.email;
  
      if (!landlordEmail) {
        console.error('LandlordDashboard: No logged-in user found.');
        return;
      }
  
      try {
        const properties = await getPropertiesByEmail(landlordEmail);
        if (properties.length > 0) {
          setPropertyDetails(properties[0]);
        } else {
          setPropertyDetails(null);
        }
      } catch (error) {
        console.error('LandlordDashboard: Error loading property:', error);
      }
    };
  
    loadProperty();
  }, []);

  const handleViewPQWForm = (work) => {
    setIsMainContentVisible(false);
    setIsRequestAQuoteVisible(false);
    setIsJobDetailVisible(false);
    setSelectedPQW(work);
    setIsPreQuotedWorksVisible(true);
  };

  const handleViewRAQForm = (type, propertyId, agentId, readOnly = false) => {
    setIsRequestAQuoteVisible(true);
    setIsPreQuotedWorksVisible(false);
    setIsMainContentVisible(false);
    setIsJobDetailVisible(false);
  };

  const handleRowSelectAction = (jobData, tabModes, selectedTab = null) => {
    setSelectedJob(jobData);
    setTabModes(tabModes);
    setIsMainContentVisible(false); 
    setIsPreQuotedWorksVisible(false);
    setIsRequestAQuoteVisible(false);
    setIsJobDetailVisible(true); 
    setSelectedTab(selectedTab); // pass this into JobTabs
  };
  
  const resetViews = () => {
    setIsMainContentVisible(true); 
    setIsRequestAQuoteVisible(false);
    setIsPreQuotedWorksVisible(false);
    setIsJobDetailVisible(false); 
  };

  return (
    <div className="dashboard-main-content-container">
      <LandlordDashboardHeader />

      {isMainContentVisible && (
        <>
        <RequestQuotePanel property={propertyDetails} handleRAQClick={handleViewRAQForm} />
        <PreQuotedWorksPanel preQuotedWorks={preQuotedWorksList} handlePQWClick={handleViewPQWForm} />
        <LandlordManagePropertiesPanel property={propertyDetails} managePropertiesRef={managePropertiesRef} onRowSelectAction={handleRowSelectAction} />
        </>
      )}

    {isRequestAQuoteVisible && (
      <>
      <div>
        {/* const JobRequestForm = ({ onClose, propertyId, agentId }) => { */}
        <JobRequestForm propertyId={propertyDetails.propertyId} agentId={propertyDetails.agentId} onReturnToDashboard={() => resetViews()} />
      </div>      
      </>
    )}

    {isPreQuotedWorksVisible && (
      <>
        <div>
        {/* const PreQuotedWorkForm = ({ onClose, pqwData, propertyId, agentId }) => { */}
          <PreQuotedWorkForm pqwData={selectedPQW} propertyId={propertyDetails.propertyId} agentId={propertyDetails.agentId} onReturnToDashboard={() => resetViews()} />
        </div>
      </>
    )}

    {isJobDetailVisible && (
      <>
        <JobTabsLandlord jobData={selectedJob} tab={selectedTab} tabModes={tabModes} onReturnToDashboard={() => resetViews()} />
      </>
    )}

    </div>
  );
};

export default LandlordDashboard;


/**
 * Fetches properties based on the landlord's email address.
 * @param {string} email - The landlord's email address.
 * @returns {Promise<Array>} - An array of property details.
 */
export const getPropertiesByEmail = async (landlordsEmail) => {
  try {
    const propertiesRef = collection(db, 'properties');
    const q = query(propertiesRef, where('landlordsEmail', '==', landlordsEmail));

    const querySnapshot = await getDocs(q);

    const properties = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return properties;
  } catch (error) {
    console.error('Failed to fetch properties by Landlords Email:', error);
    throw error;
  }
};
