import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const JobCancelled = ({ onRowClick }) => {
  const [cancelledJobs, setCancelledJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const loadCancelledJobs = useCallback(async () => {
    try {
      // console.log("🔄 Fetching Cancelled Jobs...");
      const options = { status: -1 };
      const jobs = await fetchJobs(options);
      setCancelledJobs(jobs);
    } catch (error) {
      console.error("Error loading cancelled jobs:", error);
    }
  }, []);

  useEffect(() => {
    const handleRefresh = () => {
      // console.log("🔄 JobCancelled grid refreshing...");
      loadCancelledJobs();
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadCancelledJobs]);

  useEffect(() => {
    loadCancelledJobs();
  }, [loadCancelledJobs]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Job Cancelled"
      columnDefs={[
        { headerName: "JOB ID", field: "jobId", flex: 1.0 },
        { 
          headerName: "TRADE",
          field: "tradeName",
          flex: 1.5,
          filter: true,
          cellRenderer: TradeCellRenderer,
          valueGetter: (params) =>
            params.data.tradeName === 'Other (please specify)' ? params.data.instructionNotes || 'N/A' : params.data.tradeName,
        },
        { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
        { 
          headerName: "CANCELLED DATE",
          field: "cancelledAt",
          flex: 1.4,
          valueFormatter: (params) => formatTimestamp(params.value)
        }
      ]}
      rowData={cancelledJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={onRowClick}
      isSingleColumn={true}
    />
  );
};

export default JobCancelled;
