import { globalConfig } from '../config';

/**
 * Generates the HTML content for a quote email.
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} - The HTML content for the email.
 */
export const generateQuoteEmailHTMLTemplate = (jobId, landlordName) => {
  return `
    <html>
      <body style="font-family: Arial, sans-serif; line-height: 1.6;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd;">
          <h2 style="color: #333;">Hello ${landlordName},</h2>
          <p>
            A new quote has been provided for your job, <strong>Job ID: ${jobId}</strong>.
          </p>
          <p>
            Please log in to your account to review the details and take the next steps.
          </p>
          <a href="${globalConfig.siteUrl}/dashboard?job=${jobId}" 
             style="display: inline-block; margin-top: 20px; padding: 10px 15px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px;">
            View Quote Now
          </a>
          <p style="margin-top: 20px; color: #555;">
            If you have any questions, don’t hesitate to contact us via the platform.
          </p>
          <p style="margin-top: 20px; font-size: 0.9em; color: #888;">
            Best regards, <br/>
            The ${globalConfig.sitename} Team
          </p>
        </div>
      </body>
    </html>
  `;
};

/**
 * Generates the text content for a quote email.
 *
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} The text content for the email.
 */
export const generateQuoteEmailTextTemplate = (jobId, landlordName) => {
  return `Hello ${landlordName},\n\nA new quote has been provided for your job ${jobId}.\n\nPlease log in to your account to review the details.`;
};

/**
 * Generates the HTML content for a quote email.
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} - The HTML content for the email.
 */
export const generatePQWEmailHTMLTemplate = (jobId, landlordName) => {
  return `
    <html>
      <body style="font-family: Arial, sans-serif; line-height: 1.6;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd;">
          <h2 style="color: #333;">Hello ${landlordName},</h2>
          <p>
            This is a confirmation for your job, <strong>Job ID: ${jobId}</strong>.
          </p>
          <p>
            Please log in to your account to review the details and take the next steps.
          </p>
          <a href="${globalConfig.siteUrl}/dashboard?job=${jobId}" 
             style="display: inline-block; margin-top: 20px; padding: 10px 15px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px;">
            View Quote Now
          </a>
          <p style="margin-top: 20px; color: #555;">
            If you have any questions, don’t hesitate to contact us via the platform.
          </p>
          <p style="margin-top: 20px; font-size: 0.9em; color: #888;">
            Best regards, <br/>
            The ${globalConfig.sitename} Team
          </p>
        </div>
      </body>
    </html>
  `;
};

/**
 * Generates the text content for a quote email.
 *
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} The text content for the email.
 */
export const generatePQWEmailTextTemplate = (jobId, landlordName) => {
  return `Hello ${landlordName},\n\nYour job request ${jobId} has been confirmed.\n\nPlease log in to your account to review the details.`;
};

export const generateRAQEmailHTMLTemplate = (jobId, landlordName) => {
  return `
    <html>
      <body style="font-family: Arial, sans-serif; line-height: 1.6;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd;">
          <h2 style="color: #333;">Hello ${landlordName},</h2>
          <p>
            This is a confirmation for your request a quote has been received.
          </p>
          <p>
            The job reference number is: ${jobId} <br />
            We shall contact you when the quote has been created.
          </p>
          <a href="${globalConfig.siteUrl}/dashboard?job=${jobId}" 
             style="display: inline-block; margin-top: 20px; padding: 10px 15px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px;">
            Please log in to your account to review the details and take the next steps.
          </a>
          <p style="margin-top: 20px; color: #555;">
            If you have any questions, don’t hesitate to contact us via the platform.
          </p>
          <p style="margin-top: 20px; font-size: 0.9em; color: #888;">
            Best regards, <br/>
            The ${globalConfig.sitename} Team
          </p>
        </div>
      </body>
    </html>
  `;
};

/**
 * Generates the text content for a quote email.
 *
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} The text content for the email.
 */
export const generateRAQEmailTextTemplate = (jobId, landlordName) => {
  return `Hello ${landlordName},\n\nThis is a confirmation for your request a quote has been received.\n\n The job reference number is:${jobId}\n\nWe shall contact you when the quote has been created.\n\nPlease log in to your account to review the details.`;
};


export const generateCommentEmailHTMLTemplate = (jobId, recipientName, comment) => {
  return `
  <html>
    <body style="font-family: Arial, sans-serif; line-height: 1.6;">
      <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd;">
        <h2 style="color: #333;">Hello ${recipientName},</h2>
        <p>
          There has been a new comment in relation to <strong>Job ID: ${jobId}</strong>.
        </p>
        <p>
          The comment is <br/>
          ${comment}
        </p>
        <p>
          Please log in to your account to see the comment and reply.
        </p>
        <a href="${globalConfig.siteUrl}/dashboard?job=${jobId}" 
           style="display: inline-block; margin-top: 20px; padding: 10px 15px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px;">
          View Comment Now
        </a>
        <p style="margin-top: 20px; color: #555;">
          If you have any questions, don’t hesitate to contact us via the platform.
        </p>
        <p style="margin-top: 20px; font-size: 0.9em; color: #888;">
          Best regards, <br/>
          The ${globalConfig.sitename} Team
        </p>
      </div>
    </body>
  </html>
`;
};

export const generateCommentEmailTextTemplate = (jobId, recipientName, comment ) => {
  return `Hello ${recipientName},\n\nThere has been a new comment in relation to Job ID: ${jobId}.\n\n The comment is \n\n${comment}\n\nPlease log in to your account to see the comment.`;
};

export const generateAppointmentEmailHTMLTemplate = (jobId, recipientName ) => {
  return `
  <html>
    <body style="font-family: Arial, sans-serif; line-height: 1.6;">
      <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd;">
        <h2 style="color: #333;">Hello ${recipientName},</h2>
        <p>
          An appointment date has been suggested in relation to <strong>Job ID: ${jobId}</strong>.
        </p>
        <p>
          Please log in to your account to see the comment and reply.
        </p>
        <p style="margin-top: 20px; color: #555;">
          If you have any questions, don’t hesitate to contact us via the platform.
        </p>
        <p style="margin-top: 20px; font-size: 0.9em; color: #888;">
          Best regards, <br/>
          The ${globalConfig.sitename} Team
        </p>
      </div>
    </body>
  </html>
`;
};

export const generateAppointmentEmailTextTemplate = (jobId, recipientName ) => {
  return `Hello ${recipientName},\n\nAn appointment date has been suggested in relation to Job ID: ${jobId}.\n\nPlease log in to your account to see the comment.`;
};













/**
 * Generates the HTML content for a quote email.
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} - The HTML content for the email.
 */
export const generateAcceptanceEmailHTMLTemplate = (jobId, landlordName) => {
  return `
    <html>
      <body style="font-family: Arial, sans-serif; line-height: 1.6;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd;">
          <h2 style="color: #333;">Hello ${landlordName},</h2>
          <p>
            A contractor has been assigned for your job, <strong>Job ID: ${jobId}</strong>.
          </p>
          <p>
            Please log in to your account to review the details and take the next steps.
          </p>
          <a href="${globalConfig.siteUrl}/dashboard?job=${jobId}" 
             style="display: inline-block; margin-top: 20px; padding: 10px 15px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px;">
            View Quote Now
          </a>
          <p style="margin-top: 20px; color: #555;">
            If you have any questions, don’t hesitate to contact us via the platform.
          </p>
          <p style="margin-top: 20px; font-size: 0.9em; color: #888;">
            Best regards, <br/>
            The ${globalConfig.sitename} Team
          </p>
        </div>
      </body>
    </html>
  `;
};

/**
 * Generates the text content for a quote email.
 *
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} The text content for the email.
 */
export const generateAcceptanceEmailTextTemplate = (jobId, landlordName) => {
  return `Hello ${landlordName},\n\nA contractor has been assigned for your job ${jobId}.\n\nPlease log in to your account to review the details.`;
};
