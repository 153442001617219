import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { isPlatinumWindowActive, calcPlatinumEndTime } from '../utils/jobVisibility';

const ContractorJobsAvailable = ({ isSingleColumn, onRowSelectAction}) => {
  const [availableJobs, setAvailableJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const loadAvailableJobs = useCallback(async () => {
    try {
      const options = { status: 2 };
      const jobs = await fetchJobs(options);

      let filteredJobs = jobs.filter((job) => {
        // Include jobs that have bypassHold set to true
        if (job.bypassHold) {
          return true;
        }

        const quoteAcceptedAt = job.quoteAcceptedAt.toDate(); // Convert Firestore timestamp
        const platinumEndTime = calcPlatinumEndTime(quoteAcceptedAt, job.jobId);

        // Exclude jobs that are still within the Platinum window
        if (isPlatinumWindowActive(platinumEndTime)) {
          return false;
        }

        // Jobs outside the Platinum window should be included
        return true;
      });

      setAvailableJobs(filteredJobs); // Update state with negated list
    } catch (error) {
      console.error("Error loading available jobs:", error);
    }
  }, []);

  useEffect(() => {
    const handleRefresh = () => {
      loadAvailableJobs(); 
    };
  
    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadAvailableJobs]); 
  
  useEffect(() => {
    loadAvailableJobs(); 
  }, [loadAvailableJobs]);


  const columnDefsAvailableJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    { headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.5,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
  ];
  
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleRowClick = (event) => {
    const jobData = event.data;
    const tabModes =  {
        Quote: 'readOnly',
        Detail: 'readOnly',
        Comments: 'readOnly',
        Appointments: 'hide',
        Property: 'hide',
      }
    onRowSelectAction(jobData, tabModes);
  };

  return (
    <GridPanel
      title="Jobs Available [contractor]" 
      columnDefs={columnDefsAvailableJobs}
      rowData={availableJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default ContractorJobsAvailable;
