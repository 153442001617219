import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { isPlatinumWindowActive, calcPlatinumEndTime } from '../utils/jobVisibility';

const JobsAvailable = ({ isSingleColumn, onRowSelectAction, isContractor }) => {
  const [availableJobs, setAvailableJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const loadAvailableJobs = useCallback(async () => {
    try {
      // console.log("🔄 Fetching Available Jobs...");
      const options = { status: 2 };
      const jobs = await fetchJobs(options);

      let filteredJobs = jobs.filter((job) => {
        if (job.bypassHold) return true; // Include bypassHold jobs

        const quoteAcceptedAt = job.quoteAcceptedAt.toDate();
        const platinumEndTime = calcPlatinumEndTime(quoteAcceptedAt, job.jobId);

        return !isPlatinumWindowActive(platinumEndTime); // Exclude jobs still in Platinum window
      });

      setAvailableJobs(filteredJobs);
    } catch (error) {
      console.error("Error loading available jobs:", error);
    }
  }, []);

  useEffect(() => {
    const handleRefresh = () => {
      // console.log("🔄 JobsAvailable grid refreshing...");
      loadAvailableJobs();
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadAvailableJobs]);

  useEffect(() => {
    loadAvailableJobs();
  }, [loadAvailableJobs]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = isContractor
      ? { Quote: 'readOnly', Detail: 'readOnly', Comments: 'readWrite', Appointments: 'hide', Property: 'hide' }
      : { Quote: 'readWrite', Detail: 'readWrite', Comments: 'readWrite', Appointments: 'hide', Property: 'readOnly' };

    onRowSelectAction(jobData, tabModes);
  };

  return (
    <GridPanel
      title="Jobs Available [admin]"
      columnDefs={[
        { headerName: "JOB ID", field: "jobId", flex: 0.9 },
        { 
          headerName: "TRADE",
          field: "tradeName",
          flex: 1.5,
          filter: true,
          cellRenderer: TradeCellRenderer,
          valueGetter: (params) =>
            params.data.tradeName === 'Other (please specify)' ? params.data.instructionNotes || 'N/A' : params.data.tradeName,
        },
        { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
      ]}
      rowData={availableJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobsAvailable;
