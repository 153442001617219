import React from "react";
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import ContractorDashboard from "../Contractor/ContractorDashboard";
import LandlordDashboard from "../Landlord/LandlordDashboard";
import Settings from '../Settings/Settings';
import Job from '../Job/Job';
import Notifications from "../Notifications/Notifications";
import '../styles/AdministrationContent.css';
import AgentDashboard from "../Agent/AgentDashboard";
import SettingsAddUser from "../Settings/SettingsAddUser";
import SettingsUsers from '../Settings/SettingsUsers';
import SettingsTrades from '../Settings/SettingsTrades';
import SettingsPreQuotedWorks from '../Settings/SettingsPreQuotedWorks';
import ProfileEdit from "../ProfileForm/ProfileEdit";

function AdministrationContent({ dashboardKey, notificationKey, addUserKey, usersKey, tradesKey, pqwKey, isCollapsed }) {
  return (
    <div className={`page-content ${isCollapsed ? 'collapsed' : ''}`}>
      <Routes key={dashboardKey}>
        <Route path="/dashboard" element={<Dashboard key={dashboardKey}/>} />
        <Route path="/landlord-dashboard" element={<LandlordDashboard />} />
        <Route path="/contractor-dashboard" element={<ContractorDashboard />} />
        <Route path="/agent-dashboard" element={<AgentDashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/job" element={<Job />} />
        <Route path="/notifications" element={<Notifications key={notificationKey} />} />
        <Route path="/adduser" element={<SettingsAddUser key={addUserKey}/>} />
        <Route path="/users" element={<SettingsUsers key={usersKey}/>} />
        <Route path="/trades" element={<SettingsTrades key={tradesKey}/>} />
        <Route path="/pqw" element={<SettingsPreQuotedWorks key={pqwKey}/>} />
        <Route path="/profile-edit" element={<ProfileEdit />} />
        {/* Add more routes as needed */}
      </Routes>
    </div>
  );
}

export default AdministrationContent;