import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { SystemCommenter } from "../Shared/SystemCommenter";
import { notifyLandlord } from "../services/quoteService";
import { generateAppointmentEmailTextTemplate, generateAppointmentEmailHTMLTemplate } from "../EmailTemplates/emailTemplates";
import { fetchQuote } from '../services/quoteService';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { auth, db } from "../firebase";
import { globalConfig } from "../config";
import { createNotification, createAppointment } from "../services/dbService";
import { updateJobStatus } from "../services/jobService";
import { fetchPropertyById } from "../services/dbService";
import { doc, getDoc } from "firebase/firestore";
import styles from "../styles/AppointmentForm.module.css";
import { Timestamp } from "firebase/firestore"; 

const ContractorAppointmentForm = ({ jobId, landlordData, onReturnToDashboard }) => {
  const [jobDetails, setJobDetails] = useState(null);
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [selectedDates, setSelectedDates] = useState({});
  const [multiVisit, setMultiVisit] = useState(false);
  const [unavailableDates, setUnavailableDates] = useState(new Set());
  const [suggestedDates, setSuggestedDates] = useState([]);
  const [busyDates, setBusyDates] = useState(new Set());
  const [contractorSubmitted, setContractorSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!jobId) return;
      try {
        const jobDocRef = doc(db, "jobs", jobId);
        const jobDoc = await getDoc(jobDocRef);
        if (jobDoc.exists()) {
          const jobData = jobDoc.data();
          setJobDetails(jobData);
          setPropertyId(jobData.propertyId);

          if (Array.isArray(jobData.unavailableDates)) {
            setUnavailableDates(new Set(jobData.unavailableDates));
          }
          if (Array.isArray(jobData.dates)) {
            setSuggestedDates(
              jobData.dates.map((date) =>
                date.seconds ? new Date(date.seconds * 1000).toISOString().split("T")[0] : date
              )
            );
            setContractorSubmitted(jobData.dates.length > 0);
          }

          if (Array.isArray(jobData.busyDates)) {
            setBusyDates(new Set(jobData.busyDates));
          }
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();

  }, [jobId]);

  useEffect(() => {
    const loadQuoteData = async () => {
      if (jobId) {
        try {
          const quoteData = await fetchQuote(jobId);
          if (quoteData) {
            setQuoteDetails(quoteData);
          }
        } catch (error) {
          console.error('Error fetching quote details:', error);
        }
      }
    };
    loadQuoteData();

  }, [jobId]);

  useEffect(() => {
    const loadPropertyData = async () => {
        try {
          const fetchedProperty = await fetchPropertyById(propertyId);
          setPropertyDetails(fetchedProperty);
        } catch(error) {
          console.error('Error fetching property details:', error);
        }
      };
    loadPropertyData();
  }, [propertyId]);


  const isDateDisabled = (dateStr) => {
    const today = new Date().toISOString().split("T")[0];
    return dateStr <= today || unavailableDates.has(dateStr) || busyDates.has(dateStr);
  };

  const handleContractorDateSelect = (info) => {
    if (isDateDisabled(info.dateStr)) return;
  
    setSelectedDates((prev) => {
      const newDates = { ...prev };
      if (newDates.hasOwnProperty(info.dateStr)) {
        // 🔹 If already selected, remove it (deselect)
        delete newDates[info.dateStr];
      } else {
        // 🔹 Otherwise, add it as selected
        newDates[info.dateStr] = "";
      }
      return { ...newDates }; // 🔹 Ensure state updates properly
    });
  };
  
  const handleDescriptionChange = (date, value) => {
    setSelectedDates((prev) => ({
      ...prev,
      [date]: value,
    }));
  };

  const handleContractorSubmit = async (e) => {
    e.preventDefault();
  
    if (isSubmitting) return; // Prevent duplicate submissions
  
    if (Object.keys(selectedDates).length === 0) {
      alert("Please select at least one available date.");
      return;
    }
  
    setIsSubmitting(true);
  
    const formattedDates = Object.entries(selectedDates).map(([date, description]) => {
      const [year, month, day] = date.split("-");
      const parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
  
      if (isNaN(parsedDate.getTime())) {
        console.error("Invalid date format:", date);
        alert(`Invalid date selected: ${date}`);
        return null;
      }
  
      return {
        date: Timestamp.fromDate(parsedDate), // 🔹 Store as Firestore Timestamp
        description: multiVisit ? description : "", // 🔹 Store description if multi-visit enabled
      };
    }).filter(Boolean); // 🔹 Remove invalid dates
    
    try {
      await createAppointment(jobId, {
        dates: formattedDates, // 🔹 Now stores [{ date, description }]
        contractor: auth.currentUser?.email || "Unknown Contractor",
        multiVisit,
      });
  
      await SystemCommenter(jobId, "Contractor", "Hi, we have sent our suggested dates to the Landlord.");
      await updateJobStatus(jobId, 4);
  
      notifyLandlord(auth.currentUser?.email, jobId);
  
      const plainTextBody = generateAppointmentEmailTextTemplate(jobId, landlordData.landlordFirstname);
      const htmlBody = generateAppointmentEmailHTMLTemplate(jobId, landlordData.landlordFirstname);
  
      await sendNotificationEmail({
        to: landlordData.landlordEmail,
        subject: `${globalConfig.sitename} Appointment - Job ${jobId}`,
        text: plainTextBody,
        html: htmlBody,
      });
  
      // Create Site  Notifications
      const customMessage = `Contractor: ${jobDetails.contractorName} has suggested dates to Landlord ${landlordData.landlordEmail}`;
      const notifAddress = `${propertyDetails.address} ${propertyDetails.companyCity} ${propertyDetails.postcode}`; 
      await createNotification(
        jobId, 
        "appointment_suggested", 
        {
          admin: true,
          landlord: landlordData.landlordEmail,
        },
        notifAddress,
        "Appointments",
        customMessage,
      );

      setContractorSubmitted(true);
    } catch (error) {
      alert("Failed to complete the operation: " + error);
    } finally {
      setIsSubmitting(false);
    }
  };
    
  return (
    <>
      <h2>Propose Appointment Date(s)</h2>
  
      {jobDetails ? (
        <div className={styles.jobDetails}>
          <div className={styles.jobDetailRow}>
            <div className={styles.formGroup}>
              <label><strong>Trade:</strong></label>
              <input type="text" value={jobDetails?.tradeName || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Job Description:</strong></label>
              <input type="text" value={jobDetails?.jobDetail || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Further Information:</strong></label>
              <input type="text" value={jobDetails?.furtherDetails || "N/A"} readOnly />
            </div>
          </div>
          <div className={styles.jobDetailRow}>
            <div className={styles.formGroup}>
              <label><strong>Lead Time Required:</strong></label>
              <input type="text" value={quoteDetails?.leadTime || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Est. Duration:</strong></label>
              <input type="text" value={quoteDetails?.duration || "N/A"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label><strong>Quote Detail:</strong></label>
              <input type="text" value={quoteDetails?.quoteDetails || "N/A"} readOnly />
            </div>
          </div>
        </div>
      ) : (
        <p>Loading job details...</p>
      )}
  
      <div className={styles.container}>
        <div className={styles.calendarContainer}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            selectable
            dateClick={handleContractorDateSelect}
            events={[
              ...Array.from(unavailableDates).map((date) => ({
                title: "Not Feasible",
                start: date,
                color: "red",
              })),
              ...Array.from(busyDates).map((date) => ({
                title: "Busy",
                start: date,
                color: "orange",
              })),
              ...suggestedDates.map((date) => ({
                title: "Suggested",
                start: date,
                color: "blue",
              })),
              ...Object.keys(selectedDates).map((date) => ({
                title: "Selected",
                start: date,
                color: "green",
              })),
            ]}
            height="auto"
            aspectRatio={1.35}
            firstDay={1}
            validRange={{
              start: new Date().toISOString().split("T")[0],
            }}
          />

          <p className={styles.calendarHelperText}>
            Click on the days you are able to attend to carry out the works.
          </p>

        </div>
  
        <div className={styles.detailsContainer}>

          <div className={styles.multiVisitWrapper}>
            <label className={styles.multiVisitToggle}>
              <input type="checkbox" checked={multiVisit} onChange={() => setMultiVisit(!multiVisit)} />
              Multi-Visit Required
            </label>
            <p className={styles.helperText}>If more than 1 attendance is required, provide more information to the landlord.</p>
          </div>

            <h3>
              {multiVisit
                ? "The dates and brief description of work being suggested to the landlord"
                : "The dates that are being suggested to the landlord for the work"}
            </h3>
          
            <ul className={styles.selectedDatesList}>
              {Object.keys(selectedDates).length > 0 ? (
                Object.entries(selectedDates).map(([date, description], index) => {
                  const [year, month, day] = date.split("-");
                  const formattedDate = `${day}-${month}-${year}`;
                  
                  return (
                    <li key={index} className={styles.selectedDateItem}>
                      <span>{formattedDate}</span>
                      {multiVisit && (
                        <input
                          type="text"
                          placeholder="Enter task description..."
                          value={description}
                          onChange={(e) => handleDescriptionChange(date, e.target.value)}
                          className={styles.descriptionInput}
                        />
                      )}
                    </li>
                  );
                })
              ) : (
                <li>No dates selected</li>
              )}
            </ul>
  
          {!contractorSubmitted ? (
            <button
              className={`${styles.submitButton} ${Object.keys(selectedDates).length === 0 ? styles.disabledButton : ""}`}
              onClick={handleContractorSubmit}
              disabled={Object.keys(selectedDates).length === 0 || isSubmitting}
            >
              {isSubmitting ? (
                <span className={styles.spinner}></span> // Spinner shown while submitting
              ) : (
                "Submit Availability"
              )}
            </button>
          ) : (
            <p className={styles.successMessage}>The dates you have suggested have been sent to the landlord.</p>
          )}
        </div>
      </div>
    </>
  );  
};

export default ContractorAppointmentForm;
