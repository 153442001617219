import React, { useState, useEffect } from 'react';
import '../styles/Dashboard.css';
import ContractorProvideQuote from './ContractorProvideQuote';
import ContractorJobsAvailable from './ContractorJobsAvailable';
import ContractorJobsAccepted from './ContractorJobsAccepted';
import ContractorStartDateOffered from './ContractorStartDateOffered';
import ContractorDateAcceptedAwaitingStartDate from './ContractorDateAcceptedAwaitingStartDate';
import ContractorJobInProgress from './ContractorJobInProgress';
import ContractorJobCompletedInvoiceSent from './ContractorJobCompletedInvoiceSent';
import ContractorJobCompletedClosed from './ContractorJobCompletedClosed';
import ContractorJobCancelled from './ContractorJobCancelled';
import JobTabsContractor from '../Job/JobTabsContractor';
import { db } from '../firebase';
import { doc, onSnapshot, } from 'firebase/firestore';

const ContractorDashboard = () => {
  const [isSingleColumn] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null); // Track the selected job
  const [tabModes, setTabModes] = useState({});
  const [selectedTab, setSelectedTab] = useState('Quote'); // pass tab into JobTabs
  const [isProvidingQuote, setIsProvidingQuote] = useState(false);
  const [myQuoteId, setMyQuoteId] = useState(null);

  useEffect(() => {
    const updatesRef = doc(db, "updates", "admin");

    const unsubscribe = onSnapshot(updatesRef, (snapshot) => {
      const data = snapshot.data();
      if (data?.lastUpdated) {
        // console.log("🔄 Contractor dashboard detected update:", data.lastUpdated);
        window.dispatchEvent(new Event("refreshGrids"));
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const handleRowSelected = (jobData, tabModes, selectedTab, isProvidingQuote = false) => {
    setIsProvidingQuote(isProvidingQuote)
    setTabModes(tabModes);
    setSelectedTab(selectedTab);
    setMyQuoteId(jobData.myQuoteId || null);
    setSelectedJob(jobData);
  };

  const handleBackToDashboard = () => {
    setSelectedJob(null); // Clear the selected job and return to the dashboard
  };

  return (
    <div>
      {selectedJob ? (
        <JobTabsContractor jobData={selectedJob} onReturnToDashboard={handleBackToDashboard} tab={selectedTab} tabModes={tabModes} isProvidingQuote={isProvidingQuote} myQuoteId={myQuoteId}/>
      ) : (
        <>
          <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <ContractorProvideQuote onRowSelectAction={handleRowSelected} />
          <ContractorJobsAvailable isSingleColumn={false} onRowSelectAction={handleRowSelected} />
        </div>

        <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <ContractorJobsAccepted isSingleColumn={false} onRowSelectAction={handleRowSelected } />
          <ContractorStartDateOffered isSingleColumn={false} onRowSelectAction={handleRowSelected} />
        </div>

        <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <ContractorDateAcceptedAwaitingStartDate isSingleColumn={false} onRowSelectAction={handleRowSelected} isContractor={true} />
          <ContractorJobInProgress isSingleColumn={false} onRowSelectAction={handleRowSelected } isContractor={true} />
        </div>

        <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <ContractorJobCompletedInvoiceSent isSingleColumn={false} onRowSelectAction={handleRowSelected} isContractor={true} />
          <ContractorJobCompletedClosed isSingleColumn={false} onRowSelectAction={handleRowSelected } isContractor={true} />
        </div>

        <div className={`dashboard-container 'two-columns'}`}>
          <ContractorJobCancelled isSingleColumn={false} onRowSelectAction={handleRowSelected } isContractor={true} />
        </div>

        </>
      )}
    </div>
  );
};

export default ContractorDashboard;
