// src/Panels/JobCompletedInvoiceSent.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const ContractorJobCompletedInvoiceSent = ({ isSingleColumn, onRowSelectAction }) => {
  const [invoicedJobs, setInvoicedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsInvoiceSentJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 1.0 },
    { headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.7,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },    
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "INVOICE SENT DATE", field: "invoiceSentAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadInvoicedJobs = async () => {
      const options = { status: 6 };
      const jobs = await fetchJobs(options);
      setInvoicedJobs(jobs);
    };

    loadInvoicedJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleRowClick = (jobData) => {
    const tabModes =  {
      Detail: 'readOnly',
      Quote: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'readOnly',
      Property: 'hide',
      };

    onRowSelectAction(jobData.data,tabModes,);
  };
  
  return (
    <GridPanel
      title="Job Completed - Invoice Sent [contractor]" // 6
      columnDefs={columnDefsInvoiceSentJobs}
      rowData={invoicedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default ContractorJobCompletedInvoiceSent;
