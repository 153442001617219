import React, { useState, useEffect } from 'react';
import { ThemeProvider } from './ThemeProvider/ThemeProvider'; // Adjust path as needed
import { UserProvider } from "./context/UserContext";
import { BrowserRouter as Router } from 'react-router-dom';
import useAuth from './hooks/useAuth'; // Import the useAuth hook
import AppRouter from './AppRouter'; // Import the refactored router
import { loadTradeColors } from './Shared/tradeDecorator'; // Adjust path as necessary
import { FeedbackProvider } from './context/FeedbackContext'; // Import FeedbackProvider

import './styles/App.css';

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('dark-mode') === 'true');
  const { isAuthenticated, userEmail, loading } = useAuth(); // ✅ Get loading state

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.documentElement.classList.toggle('light-mode', !newMode);
      localStorage.setItem('dark-mode', newMode ? 'true' : 'false');
      return newMode;
    });
  };

  useEffect(() => {
    const initializeTradeColors = async () => {
      try {
        await loadTradeColors();
      } catch (error) {
        console.error("Failed to load trade colors:", error);
      }
    };

    initializeTradeColors();
  }, []);

  // ✅ Absolute final step: Prevent rendering **ANYTHING** until auth check completes
  if (loading) {
    return null; // ✅ Fully blank screen while checking authentication
  }

  return (
    <UserProvider>
      <Router>
        <FeedbackProvider>
          <ThemeProvider>
            {/* ✅ AppRouter now waits until Firebase confirms auth */}
            {isAuthenticated !== null && (
              <AppRouter
                isAuthenticated={isAuthenticated}
                userEmail={userEmail}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                isDarkMode={isDarkMode}
                toggleDarkMode={toggleDarkMode}
              />
            )}
          </ThemeProvider>
        </FeedbackProvider>
      </Router>
    </UserProvider>
  );
}

export default App;
