import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { useAuthContext } from '../context/AuthContext';
import { fetchUserByEmail } from '../services/dbService';
import { auth } from '../firebase';

const JobsAccepted = ({ isSingleColumn, onRowSelectAction, isContractor, tab }) => {
  const [acceptedJobs, setAcceptedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user } = useAuthContext();
  const [selectedTab, setSelectedTab] = useState(null);

  const loadAcceptedJobs = useCallback(async () => {
    try {
      // console.log("🔄 Fetching Accepted Jobs...");
      const options = { status: 3 };

      if (user?.role === "Contractor") {
        setSelectedTab("Appointments");
        const userData = await fetchUserByEmail(auth.currentUser.email);
        options.contractorName = userData.companyName;
        options.contractorEmail = userData.email;
      }

      const jobs = await fetchJobs(options);
      setAcceptedJobs(jobs);
    } catch (error) {
      console.error("Error loading accepted jobs:", error);
    }
  }, [user?.role]);

  useEffect(() => {
    const handleRefresh = () => {
      // console.log("🔄 JobsAccepted grid refreshing...");
      loadAcceptedJobs();
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadAcceptedJobs]);

  useEffect(() => {
    loadAcceptedJobs();
  }, [loadAcceptedJobs]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = isContractor
      ? { Quote: 'readOnly', Detail: 'readOnly', Comments: 'readWrite', Appointments: 'readWrite', Property: 'hide' }
      : { Appointments: 'hide', Quote: 'readOnly', Detail: 'readWrite', Comments: 'readWrite', Property: 'hide' };

    onRowSelectAction(jobData, tabModes, selectedTab);
  };

  return (
    <GridPanel
      title="Jobs Accepted (by contractor)"
      columnDefs={[
        { headerName: "JOB ID", field: "jobId", flex: 0.9 },
        { 
          headerName: "TRADE",
          field: "tradeName",
          flex: 1.5,
          filter: true,
          cellRenderer: TradeCellRenderer,
          valueGetter: (params) =>
            params.data.tradeName === 'Other (please specify)' ? params.data.instructionNotes || 'N/A' : params.data.tradeName,
        },
        { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
        { 
          headerName: "ACCEPTED DATE", 
          field: "quoteAcceptedAt", 
          flex: 1.4,
          valueFormatter: (params) => formatTimestamp(params.value)
        }
      ]}
      rowData={acceptedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobsAccepted;
