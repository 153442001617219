import { db } from '../firebase';
import { collection, query, where, orderBy, getDocs, doc, addDoc, setDoc, serverTimestamp, getDoc, updateDoc, arrayUnion, onSnapshot, Timestamp, deleteDoc, documentId } from 'firebase/firestore';
import { auth } from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

const storage = getStorage();

export const STATUS_MAPPING = {
  "0": { label: 'Awaiting Quote', description: 'The job is awaiting a quote to be submitted by admin.' },
  "1": { label: 'Quote Provided Awaiting Acceptance', description: 'The quote has been submitted and is awaiting acceptance.' },
  "2": { label: 'Quote Accepted', description: 'The quote has been accepted, and the job is queued for allocation.' },
  "3": { label: 'Job Allocated', description: 'The job has been allocated and waiting for suggested appointment date.' },
  "4": { label: 'Appointment Suggested', description: 'An appointment date has been suggested to the landlord for the job.' },
  "5": { label: 'Appointment Accepted', description: 'An appointment date has been accepted and the job queued until start date.' },
  // "5": { label: 'Job Started', description: 'The job has started.' }, THIS IS 5 DATE CONTROLLED
  "6": { label: 'Job Completed', description: 'The job has been completed, invoice sent, and ready for sign off by landlord.' },
  "7": { label: 'Closed', description: 'The job has been completed.' },
  "-1": { label: 'Rejected', description: 'The quote has been rejected by the landlord.' },
  "default": { label: 'Unknown', description: 'The job status is not recognized.' },
};

export const SUBSCRIPTION_MAPPING = {
  "0": {labelShort: 'Platinum', labelLong: 'Platinum Member', description: 'In house tradesperson', cost: 0, releaseAfter: 0},
  "1": {labelShort: 'Gold', labelLong: 'Gold Member', description: 'Early access to jobs.', cost: 30, releaseAfter: 60},
  "2": {labelShort: 'Silver', labelLong: 'Silver Member', description: 'Priviledged access to jobs.', cost: 20, releaseAfter: 90},
  "3": {labelShort: 'Bronze', labelLong: 'Bronze Member', description: 'Standard access to jobs.', cost: 10, releaseAfter: 120},
};

export const NOTIFICATION_MESSAGES = {
  "quote_requested": { label: "New Quote Requested", message: "The landlord has requested a quote for Job ", },
  "quote_provided": { label: "Quote Provided", message: "A quote has been provided for Job ", },
  "quote_accepted": { label: "Quote Accepted", message: "The landlord has accepted the quote for Job ", },
  "pqw_requested": { label: "Quote Accepted", message: "The landlord has requested a Pre Quoted Job ", },
  "job_updated": { label: "Job Status Updated", message: "The job status has been updated for Job ", },
  "job_accepted": { label: "Job Accepted", message: "A contractor has accepted Job ", },
  "appointment_suggested": { label: "Appointment Suggested", message: "The contractor has suggested an appointment date for the Job ", },
  "comment_posted": { label: "New Comment Posted", message: "A new comment was posted for Job ", },
  "landlord_invited": { label: "Notification", message: "A landlord has been invited ", },
  "landlord_accepted": { label: "Notification", message: "A landlord has accepted an invitation ", },
  "default": { label: "Notification", message: "You have a new notification regarding Job ", }, 
  "user_invited": { label: "Notification", message: "A landlord has been invited ", },  
};

/**
 * Fetches properties submitted by an agent and includes inviteAcceptedAt from the associated landlord's user document.
 * @param {string} agentEmail - The email address of the agent.
 * @returns {Promise<Array>} - An array of properties associated with the agent, enriched with inviteAcceptedAt.
 */
export const fetchPropertiesSubmittedByAgent = async (agentEmail) => {
  try {
    const propertiesRef = collection(db, 'properties'); // Reference to the properties collection

    // Query properties where agentId matches the given agentEmail
    const propertiesQuery = query(propertiesRef, where('agentId', '==', agentEmail));
    const querySnapshot = await getDocs(propertiesQuery);

    const properties = await Promise.all(
      querySnapshot.docs.map(async (propertyDoc) => {
        const propertyData = propertyDoc.data();
        const landlordEmail = propertyData.landlordsEmail;

        if (landlordEmail) {
          try {
            // Fetch the corresponding landlord user document
            const landlordDocRef = doc(db, 'users', landlordEmail);
            const landlordDocSnapshot = await getDoc(landlordDocRef);

            return {
              id: propertyDoc.id,
              ...propertyData,
              inviteAcceptedAt: landlordDocSnapshot.exists() ? landlordDocSnapshot.data().inviteAcceptedAt : null,
            };
          } catch (landlordError) {
            console.error(`Error fetching landlord data for ${landlordEmail}:`, landlordError);
            return {
              id: propertyDoc.id,
              ...propertyData,
              inviteAcceptedAt: null,
            };
          }
        } else {
          return {
            id: propertyDoc.id,
            ...propertyData,
            inviteAcceptedAt: null,
          };
        }
      })
    );
    return properties;
  } catch (error) {
    console.error('Error fetching properties submitted by agent:', error);
    alert('Error fetching properties submitted by agent: ' + error);
    throw error;
  }
};

export const fetchJobsByPropertyAndStatuses = async (propertyId, statuses) => {
  if (!propertyId || typeof propertyId !== 'string') {
    throw new Error('Invalid propertyId: Must be a non-empty string.');
  }

  if (!Array.isArray(statuses) || statuses.some((status) => typeof status !== 'number')) {
    throw new Error('Invalid statuses: Must be an array of numbers.');
  }

  try {
    // console.log(`Fetching jobs for propertyId: ${propertyId} with statuses: ${statuses}`);
    const jobsRef = collection(db, 'jobs'); // Reference to the jobs collection
    const jobsQuery = query(
      jobsRef,
      where('propertyId', '==', propertyId),
      where('status', 'in', statuses), // Use 'in' for multiple status values
      orderBy('createdAt') // Ensure `createdAt` is indexed
    );

    const querySnapshot = await getDocs(jobsQuery);

    if (querySnapshot.empty) {
      // console.log('No jobs found matching the criteria.');
      return [];
    }

    const jobs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // console.log('Fetched jobs:', jobs);
    return jobs;
  } catch (error) {
    console.error('Error fetching jobs by property and statuses:', error);
    throw error;
  }
};

/**
 * Fetch jobs for a specific property and status.
 * @param {string} propertyId - The ID of the property.
 * @param {number} status - The status of the jobs to fetch.
 * @returns {Promise<Array>} - Array of jobs matching the criteria.
 */
export const fetchJobsByPropertyAndStatus = async (propertyId, status) => {
  if (!propertyId || typeof propertyId !== 'string') {
    throw new Error('Invalid propertyId: Must be a non-empty string.');
  }

  if (typeof status !== 'number') {
    throw new Error('Invalid status: Must be a number.');
  }

  try {
    // console.log(`Fetching jobs for propertyId: ${propertyId} with status: ${status}`);
    const jobsRef = collection(db, 'jobs'); // Reference to the jobs collection
    const jobsQuery = query(
      jobsRef,
      where('propertyId', '==', propertyId),
      where('status', '==', status),
      orderBy('createdAt') // Ensure `createdAt` is indexed
    );

    const querySnapshot = await getDocs(jobsQuery);

    if (querySnapshot.empty) {
      // console.log('No jobs found matching the criteria.');
      return [];
    }

    const jobs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // console.log('Fetched jobs:', jobs);
    return jobs;
  } catch (error) {
    console.error('Error fetching jobs by property and status:', error);
    throw error;
  }
};

/**
 * Fetch jobs with dynamic conditions.
 * @param {Array} conditions - Array of condition objects { field, operator, value }.
 * @returns {Promise<Array>} - Array of jobs matching the criteria.
 */
export const fetchJobsWithConditions = async (conditions) => {
  try {
    // console.log('Fetching jobs with conditions:', conditions);
    const jobsRef = collection(db, 'jobs'); // Reference to the jobs collection

    // Build the query dynamically based on conditions
    let jobsQuery = jobsRef;
    conditions.forEach((condition) => {
      jobsQuery = query(jobsQuery, where(condition.field, condition.operator, condition.value));
    });

    const querySnapshot = await getDocs(jobsQuery);

    const jobs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // console.log('Fetched jobs with conditions:', jobs);
    return jobs;
  } catch (error) {
    console.error('Error fetching jobs with conditions:', error);
    throw error;
  }
};

/**
 * Fetch all Pre-Quoted Works (PQW) from the Firestore collection.
 * This function retrieves a list of pre-quoted work items, including their
 * title, job detail description, and price, for display in the landlord dashboard.
 * 
 * @returns {Promise<Array>} An array of PQW objects with the following fields:
 * - id: Unique document ID from Firestore.
 * - title: The name/title of the pre-quoted work.
 * - jobDetail: A description of the work to be performed.
 * - price: The cost of the work.
 */
export const fetchPreQuotedWorks = async () => {
  try {
    const pqwCollection = collection(db, 'preQuotedWorks');
    const snapshot = await getDocs(pqwCollection);
    const works = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return works;
  } catch (error) {
    console.error('dbService: Error fetching Pre-Quoted Works:', error);
    throw error;
  }
};

/**
 * Fetches all user record from the Firestore database.
 * @returns {Promise<Object|null>} - The user document data if found, or `null` if the user does not exist.
 * @throws {Error} - If an error occurs during the query.
 */
export const fetchAllUsers = async () => {
  try {
    const usersCollection = collection(db, 'users'); 
    const snapshot = await getDocs(usersCollection);
    const users = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return users;
  } catch (error) {
    console.error('dbService.js: Error fetching all users: ', error);
    throw error; // Rethrow to allow error handling in calling code
  }
};

/**
 * Insert a new job into the Firestore `jobs` collection.
 * This job is for pre-approved work and skips the quote process.
 *
 * @param {string} propertyId - The ID of the property the job is associated with.
 * @param {string} jobId - A unique job ID in the format propertyId-jobId.
 * @param {Object} jobData - The data for the job, including trade, title, detail, and price.
 * @returns {Promise<void>} A promise that resolves when the job is successfully added.
 */
export const insertPreApprovedJob = async (propertyId, jobId, jobData) => {
  try {
    const jobRef = doc(db, 'jobs', jobId);
    const newJob = {
      ...jobData,
      createdAt: serverTimestamp(),
      status: 2, // Pre-approved work
    };
    await setDoc(jobRef, newJob);
    // console.log('dbService: Pre-approved job inserted successfully:', newJob);
  } catch (error) {
    console.error('dbService: Error inserting pre-approved job:', error);
    throw error;
  }
};

/**
 * Get the next available job ID for a given property.
 * Queries the `jobs` collection to find the highest existing job ID for the property
 * and returns the next sequential job ID.
 *
 * @param {string} propertyId - The ID of the property.
 * @returns {Promise<string>} The next job ID in the format propertyId-<nextNumber>.
 */
export const getNextJobId = async (propertyId) => {

  try {
    const jobsCollection = collection(db, 'jobs');
    const jobQuery = query(jobsCollection, where('propertyId', '==', propertyId));
    const snapshot = await getDocs(jobQuery);

    // Extract job numbers from IDs in the format propertyId-jobNumber
    const jobNumbers = snapshot.docs
      .map((doc) => doc.id.split('-')[1]) // Get job number part
      .map(Number) // Convert to number for sorting
      .filter((num) => !isNaN(num)); // Ensure valid numbers

    const nextJobNumber = jobNumbers.length > 0 ? Math.max(...jobNumbers) + 1 : 1;
    return `${propertyId}-${nextJobNumber}`;
  } catch (error) {
    console.error('dbService: Error calculating next job ID:', error);
    throw error;
  }
};

/**
 * Fetch a job by its ID.
 * @param {string} jobId - The ID of the job to fetch.
 * @returns {Promise<Object>} - The job record.
 */
export const fetchJobById = async (jobId) => {
  try {
    const jobRef = doc(db, "jobs", jobId);
    const jobSnapshot = await getDoc(jobRef);

    if (jobSnapshot.exists()) {
      return { id: jobSnapshot.id, ...jobSnapshot.data() };
    } else {
      throw new Error(`Job with ID ${jobId} does not exist.`);
    }
  } catch (error) {
    console.error("dbService: Error fetching job by ID:", error);
    throw error;
  }
};

/**
 * Fetches jobs for admins to price, allowing only jobs that were created within the last hour.
 *
 * @param {Object} options - Optional filters.
 *   - `landlordEmail` (string, optional): Filter to return jobs associated with a specific landlord.
 *   - `status` (any, required): Filter to return jobs with a specific status.
 * @returns {Promise<Array>} - A promise resolving to an array of jobs that are less than 1 hour old and match the filters.
 */
export const fetchJobsForAdminToPriceX = async (options = {}) => {
  const jobsCollection = collection(db, "jobs");

  try {

    let jobQuery = [where("status", "==", options.status)];

    if (options.timeboxedFor === "contractor") {
      const oneHourAgo = Timestamp.fromDate(new Date(Date.now() - 60 * 60 * 1000));
      jobQuery.push(where("createdAt", "<", oneHourAgo)); // Jobs older than 1 hour
    } else if (options.timeboxedFor === "admin") {
      const oneHourAgo = Timestamp.fromDate(new Date(Date.now() - 60 * 60 * 1000));
      jobQuery.push(where("createdAt", ">", oneHourAgo)); // Jobs within the last hour
    }
        
    // Apply ordering after conditions
    jobQuery.push(orderBy("createdAt"));  
    const jobQueryRef = query(jobsCollection, ...jobQuery);
    const querySnapshot = await getDocs(jobQueryRef);
    
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching jobs for admin:", error);
    throw error;
  }
};

/**
 * Fetches jobs available for a contractor to quote on.
 *
 * Logic:
 * 1️⃣ Jobs where `status = 0` AND `createdAt` is more than 1 hour ago.
 * 2️⃣ Jobs where `status = 1` AND the contractor has already submitted a quote.
 *
 * @param {string} contractorId - The contractor's email or ID.
 * @returns {Promise<Array>} - A list of jobs the contractor can quote on.
 */
export const fetchJobsForContractorToQuoteX = async (contractorId) => {
  try {
    const oneHourAgo = Timestamp.fromDate(new Date(Date.now() - 60 * 60 * 1000));
    const jobsRef = collection(db, "jobs");
    const quotesRef = collection(db, "quotes");

    // ✅ Step 1: Fetch jobs where status = 0 and createdAt is older than 1 hour
    const jobQuery1 = query(jobsRef, where("status", "==", 0), where("createdAt", "<", oneHourAgo));
    const jobSnapshot1 = await getDocs(jobQuery1);
    const jobsFromClause1 = jobSnapshot1.docs.map(doc => ({ jobId: doc.id, ...doc.data() }));

    // ✅ Step 2: Fetch quotes submitted by this contractor
    const quoteQuery = query(quotesRef, where("contractorId", "==", contractorId));
    const quoteSnapshot = await getDocs(quoteQuery);
    const quotedJobIds = new Set(quoteSnapshot.docs.map(doc => doc.data().jobId));

    // ✅ Step 3: Fetch jobs where status = 1 AND contractor has quoted
    let jobsFromClause2 = [];
    if (quotedJobIds.size > 0) {
      const jobQuery2 = query(
        jobsRef,
        where("status", "==", 1),
        where("jobId", "in", Array.from(quotedJobIds))
      );
      const jobSnapshot2 = await getDocs(jobQuery2);
      jobsFromClause2 = jobSnapshot2.docs.map(doc => ({ jobId: doc.id, ...doc.data() }));
    }

    // ✅ Step 4: Merge both job lists and return
    const allJobs = [...jobsFromClause1, ...jobsFromClause2];
    // console.log("✅ Final jobs to return:", allJobs.map(j => j.jobId));

    // console.log("Jobs available for contractor:", allJobs);
    return allJobs;
  } catch (error) {
    console.error("Error fetching jobs for contractor to quote:", error);
    return [];
  }
};


/**
 * Updates the status of a job in Firestore.
 *
 * @param {string} jobId - The ID of the job to update.
 * @param {number} status - The new status to set (-1 for rejected).
 * @returns {Promise<void>} - Resolves when the status is updated.
 */
export const updateJobStatus = async (jobId, status) => {
  try {
    const jobRef = doc(db, 'jobs', jobId);    
    const updateData = { status };

    // If status is 2, include quoteAccepted and quoteAcceptedAt
    if (status === 2) {
      updateData.quoteAccepted = true;
      updateData.quoteAcceptedAt = serverTimestamp(); // Use server timestamp
    }

    // Save the completed date for display
    if (status === 7) {
      updateData.completedDate = serverTimestamp();
    }

    await updateDoc(jobRef, updateData);

    // 🔹 Update admin broadcast
    await updateAdminBroadcast();

  } catch (error) {
    console.error(`Error updating status for job ${jobId}:`, error);
    throw error;
  }
};

/**
 * Fetches a user record from the Firestore database based on their email address.
 * @param {string} email - The email address of the user.
 * @returns {Promise<Object|null>} - The user document data if found, or `null` if the user does not exist.
 * @throws {Error} - If an error occurs during the query.
 */
export const fetchUserByEmail = async (email) => {
  try {
    const userDocRef = doc(db, 'users', email); // Correctly reference the document
    const userDoc = await getDoc(userDocRef); // Use getDoc to fetch the document
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() }; // Return the user data
    } else {
      return null; // User does not exist
    }
  } catch (error) {
    console.error('dbService.js: Error fetching user by email:', error);
    throw error; // Rethrow to allow error handling in calling code
  }
};

/**
 * Fetches the user document from the Firestore 'users' collection.
 *
 * @param {string} userEmail - The email address of the user whose document is to be fetched.
 * @returns {Promise<Object>} - A promise that resolves to the user document data.
 * @throws {Error} - Throws an error if the email is not provided, or if the document doesn't exist.
 *
 * Example usage:
 * const user = await fetchUserDocument('example@domain.com');
 * console.log(user.role); // Access specific fields like 'role'
 */
export const fetchUserDocument = async (userEmail) => {
  if (!userEmail) {
    throw new Error('User email is required to fetch user document');
  }

  try {
    const userDocRef = doc(db, 'users', userEmail);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error(`No user document found for email: ${userEmail}`);
    }

    return userDoc.data(); // Return the full user document
  } catch (error) {
    console.error('fetchUserDocument: Failed to fetch user document:', error);
    throw error;
  }
};

/**
 * Fetches the logged-in agent's profile data from the `users` collection.
 * @param {string} email - The email of the logged-in agent.
 * @returns {Promise<Object>} The agent's profile data.
 * @throws {Error} If no user document is found for the provided email.
 */
export const fetchAgentData = async (email) => {
  if (!email) {
    throw new Error('Email is required to fetch agent data.');
  }

  const userDocRef = doc(db, 'users', email);
  const userDoc = await getDoc(userDocRef);

  if (!userDoc.exists()) {
    throw new Error(`No user document found for email: ${email}`);
  }

  return userDoc.data();
};

/**
 * Updates the lastLoggedIn timestamp for a given user in Firestore.
 * @param {string} userEmail - The email of the user whose lastLoggedIn field is to be updated.
 * @returns {Promise<void>} - Resolves if the update is successful; rejects otherwise.
 */
export const updateLastLoggedIn = async (userEmail) => {
  if (!userEmail) throw new Error('User email is required to update lastLoggedIn.');

  const userDocRef = doc(db, 'users', userEmail);
  await updateDoc(userDocRef, {
    lastLoggedIn: new Date(),
  });
};

/**
 * Fetches a property by its ID and includes the associated user data.
 * @param {string} propertyId - The property's propertyId.
 * @returns {Promise<Object>} - The property details along with user data.
 */
export const fetchPropertyById = async (propertyId) => {
  try {
    // Fetch the property document
    const propertyRef = doc(db, "properties", propertyId);
    const propertySnapshot = await getDoc(propertyRef);

    if (!propertySnapshot.exists()) {
      throw new Error(`Property with ID ${propertyId} does not exist.`);
    }

    const propertyData = { id: propertySnapshot.id, ...propertySnapshot.data() };

    // Fetch the associated user document based on the email or user ID
    const userIdentifier = propertyData.landlordsEmail;
    if (!userIdentifier) {
      throw new Error("Property is missing user information landlordsEmail.");
    }

    const userRef = doc(db, "users", userIdentifier);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      throw new Error(`User with ID/Email ${userIdentifier} does not exist.`);
    }

    const userData = { id: userSnapshot.id, ...userSnapshot.data() };

    // Combine property data and user data
    return { ...propertyData, user: userData };
  } catch (error) {
    console.error("dbService: Error fetching property with user data:", error);
    throw error;
  }
};

/**
 * Safely inserts a comment into the comments collection for a given job ID.
 * @param {string} jobId - The ID of the job.
 * @param {string} commentText - The text of the comment.
 * @param {string} sender - The sender of the comment (e.g., 'Admin').
 */
export const insertComment = async (jobId, commentText, sender = 'Admin') => {
  if (!jobId || typeof jobId !== 'string' || jobId.trim() === '') {
    console.error('Invalid jobId:', jobId);
    return;
  }

  const messageData = {
    sender,
    text: commentText,
    timestamp: serverTimestamp(),
  };

  try {
    const commentsRef = doc(db, 'comments', jobId);

    // Check if the document exists
    const commentsSnap = await getDoc(commentsRef);
    if (commentsSnap.exists()) {
      // Update existing document with a new comment
      await updateDoc(commentsRef, {
        comments: arrayUnion(messageData),
      });
      // console.log('Comment added to existing document:', messageData);
    } else {
      // Create a new document with the comment
      await setDoc(commentsRef, {
        jobId,
        comments: [messageData],
      });
      // console.log('Created new document with comment:', messageData);
    }
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

/**
 * Fetches just enough data for a property from the properties collection 
 * for the agents viewing.
 * @param {*} propertyId 
 * @param {*} agentId 
 * @returns 
 */
export const fetchPropertyDetailsForAgent = async (propertyId, agentId) => {
  try {
    const propertyRef = doc(db, "properties", propertyId); // 'properties' collection
    const propertySnap = await getDoc(propertyRef);

    if (propertySnap.exists()) {
      const propertyData = propertySnap.data();

      // Check agentId and return only specific fields
      if (propertyData.agentId === agentId) {
        return {
          address: propertyData.address,
          postcode: propertyData.postcode,
        };
      } else {
        throw new Error("Agent not authorized for this property.");
      }
    } else {
      throw new Error("Property not found.");
    }
  } catch (error) {
    console.error("Error fetching property details:", error);
    throw error;
  }
};

/**
 * Fetches work history for a specific property from the `works` collection.
 * The query retrieves jobs with the `requestSubmitted` status, ordered by date.
 * 
 * @param {string} propertyId - The unique ID of the property to fetch work history for.
 * @returns {Promise<Array>} - A promise that resolves to an array of work objects.
 * @throws {Error} - Throws an error if the query fails.
 */
export const fetchWorkHistoryByPropertyId = async (propertyId) => {
  try {
    const worksRef = collection(db, "jobs"); // Replace "jobs" with your actual collection name

    // Query to fetch all jobs for the given property
    const q = query(
      worksRef,
      where("propertyId", "==", propertyId) // Only filter by propertyId
    );

    const results = await getDocs(q);

    // Return the jobs as an array
    return results.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching jobs for property:", error);
    throw error;
  }
};

/**
 * Assigns a job to "In-House" by updating the job record.
 *
 * This function sets the `contractorName` field to "In-House"
 * and updates the `status` field to 3 in the Firestore database.
 *
 * @param {string} jobId - The unique ID of the job document to update.
 * @returns {Promise<void>} - Resolves when the job is successfully updated.
 * @throws {Error} - Throws an error if the update operation fails.
 */
export const assignJobInHouse = async (jobId) => {
  try {
    const jobRef = doc(db, "jobs", jobId); // Reference the job document by its ID
    await updateDoc(jobRef, {
      contractorName: "In-House",
      status: 3,
    });

    // 🔹 Update admin broadcast
    const updatesRef = doc(db, "updates", "admin");
    await updateDoc(updatesRef, { lastUpdated: Date.now() });

    // console.log(`Job ${jobId} successfully assigned to In-House.`);
  } catch (error) {
    console.error("Error assigning job in-house:", error);
    throw new Error("Failed to assign job in-house. " + error);
  }
};

/**
 * 
 * @param {string } options.jobId - The unique ID of the job document to update.
 * @param {string } options.contractorName - The name of the contractor to add to the job record (as reserved)
 * @returns {Promise<void>} - Resolves when the job is successfully updated.
 * @throws {Error} - Throws an error if the update operation fails.
 */
export const assignJobToContractor = async (options) => {
  try {
    const jobRef = doc(db, "jobs", options.jobId); // Reference the job document by its ID
    await updateDoc(jobRef, {
      contractorName: options.contractorName,
      contractorEmail: options.contractorEmail,
      status: 3,
      isJobAccepted: true,
    });
  
    // 🔹 Update admin broadcast
    const updatesRef = doc(db, "updates", "admin");
    await updateDoc(updatesRef, { lastUpdated: Date.now() });

    // console.log(`Job ${options.jobId} successfully assigned to Contractor.`);
  } catch (error) {
    console.error("Error assigning job to contractor:", error);
    throw new Error("Failed to assign job to contractor. " + error);
  }

};

/**
 * Listens for the count of unread notifications for the current user.
 * 
 * @param {function} callback - Function to update the unread count in UI.
 * @returns {function} Unsubscribe function to stop listening for updates.
 */
export const fetchUnreadNotificationCount = (callback) => {
  const unsubscribeAuth = auth.onAuthStateChanged((user) => {
    if (!user) {
      console.warn("fetchUnreadNotificationCount: No authenticated user found.");
      callback(0);
      return () => {};
    }

    // console.log(`fetchUnreadNotificationCount: Running query for ${user.email}`);

    const notificationsRef = collection(db, "notifications");

    // 🔹 Query unread notifications for the logged-in user
    const notificationsQuery = query(
      notificationsRef,
      where("userId", "==", user.email),
      where("read", "==", false) // Only fetch unread notifications
    );

    return onSnapshot(notificationsQuery, (snapshot) => {
      // console.log(`✅ Firestore returned ${snapshot.docs.length} unread notifications.`);
      callback(snapshot.docs.length);
    });
  });

  return () => unsubscribeAuth();
};

/**
 * Fetches all notifications for the current user.
 * 
 * @param {function} callback - Function to update notifications in UI.
 * @returns {function} Unsubscribe function to stop listening for updates.
 */
export const fetchAllNotifications = (callback) => {
  if (!auth.currentUser) return () => {};

  const notificationsRef = collection(db, "notifications");

  const userFilter = { userId: auth.currentUser.email };

  const allNotificationsQuery = query(
    notificationsRef,
    where("recipients", "array-contains", userFilter) // Fetch all notifications for the user
  );

  return onSnapshot(allNotificationsQuery, (snapshot) => {
    callback(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))); // Returns all notifications
  });
};

/**
 * Creates a notification in Firestore for specified recipients.
 * 
 * @param {string} jobId - The ID of the job associated with the notification.
 * @param {string} eventType - The event type triggering the notification (e.g., "quote_received").
 * @param {Object} recipients - Object mapping recipient roles to emails (e.g., `{ admin: true, landlord: "email@example.com" }`).
 * @param {string} [address=null] - The property address related to the notification.
 * @param {string} [displayTab=null] - The tab to display when the notification is clicked.
 * @param {string} [customMessage=null] - A custom message to override default notification text.
 * @returns {Promise<void>} - Resolves when notifications are created successfully.
 */

export const createNotification = async (jobId, eventType, recipients, address, displayTab, customMessage) => {
  const notificationsRef = collection(db, "notifications");
  
  // Map notification types to user flags
  const userFlags = {
    "quote_received": "quoteNotif",
    "quote_accepted": "quoteNotif",
    "new_comment": "commentNotif",
  };
  
  let recipientEntries = Object.entries(recipients)
    .filter(([_, email]) => email !== null); // Only include non-null emails

  // If "admin" is a recipient, expand it into all admin emails
  if (recipients.admin) {
    const adminEmails = await fetchAllAdmins(); // Fetch all admin emails
    const adminEntries = adminEmails.map(email => ["Admin", email]); // Convert to role-email pairs
    recipientEntries = [...recipientEntries, ...adminEntries]; // Merge with existing recipients
  }

  recipientEntries = recipientEntries.filter(([role, email]) => !(role === "admin" && email === true));


  if (recipientEntries.length === 0) {
    console.warn(`⚠ No recipients for Job #${jobId}, Event: ${eventType}`);
    return;
  }

  const usersRef = collection(db, "users");
  const usersQuery = query(usersRef, where(documentId(), "in", recipientEntries.map(([_, email]) => email)));
  const usersSnapshot = await getDocs(usersQuery);
  

  const userPreferences = {};
  usersSnapshot.forEach((doc) => {
    const data = doc.data();
    userPreferences[doc.id] = {
      allowNotifications: data.allowNotifications ?? true, // Default to true
    };
  });
  
  const finalRecipients = recipientEntries.filter(([_, email]) => {
    const userPrefs = userPreferences[email]; 
    return userPrefs ? userPrefs.allowNotifications : true; // Default to true
  });

  const messageTemplate = NOTIFICATION_MESSAGES[eventType] || NOTIFICATION_MESSAGES["default"];
  const message = customMessage 
    ? customMessage  // Use customMessage if provided
    : jobId 
      ? `${messageTemplate.message}${jobId}`  // Default logic with jobId
      : `${messageTemplate.message}`;

  const propertyAddress = address || null;
  const tabToDisplay = displayTab || null;

  // 🔹 Create a document for each recipient
  const notificationPromises = finalRecipients.map(async ([role, email]) => {
    await addDoc(notificationsRef, {
      userId: email, 
      role,
      message,
      read: false,
      createdAt: serverTimestamp(),
      jobId,
      address: propertyAddress,
      displayTab: tabToDisplay,
      eventType,
    });

    // 🔹 Check if the event type has a corresponding user flag
    const flagToSet = userFlags[eventType];
    if (flagToSet) {
      const userRef = doc(db, "users", email);
      await updateDoc(userRef, { [flagToSet]: true });
    }
  });

  await Promise.all(notificationPromises);
  // console.log(`Notifications created for Job #${jobId} with recipients:`, recipientEntries);
};

/**
 * Fetches all notifications for the logged-in user.
 * 
 * @returns {Promise<Array>} A list of notifications sorted by newest first.
 */
export const fetchNotifications = async () => {
  if (!auth.currentUser) return [];

  // console.log(`fetchNotifications: Fetching notifications for ${auth.currentUser.email}`);

  const notificationsRef = collection(db, "notifications");

  // 🔹 Query only notifications for this user, sorted by `createdAt` (newest first)
  const notificationsQuery = query(
    notificationsRef,
    where("userId", "==", auth.currentUser.email),
    orderBy("createdAt", "desc")
  );

  const snapshot = await getDocs(notificationsQuery);

  // 🔹 Map the documents directly, no need to filter manually
  const userNotifications = snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      message: data.message,
      createdAt: data.createdAt.toDate().toLocaleString(),
      read: data.read, 
      jobId: data.jobId,
      address: data.address,
      displayTab: data.displayTab,
      eventType: data.eventType,
    };
  });

  // console.log(`✅ Found ${userNotifications.length} notifications.`);
  return userNotifications;
};

/**
 * Marks a notification as read for the current user.
 * 
 * @param {string} notificationId - The Firestore document ID of the notification.
 */
export const markNotificationAsRead = async (notificationId) => {
  if (!auth.currentUser) return;

  const notificationRef = doc(db, "notifications", notificationId);

  await updateDoc(notificationRef, { read: true });

  // console.log(`✅ Notification ${notificationId} marked as read.`);
};

/**
 * Marks a notification as unread for the current user.
 * 
 * @param {string} notificationId - The Firestore document ID of the notification.
 */
export const markNotificationAsUnRead = async (notificationId) => {
  if (!auth.currentUser) return;

  const notificationRef = doc(db, "notifications", notificationId);

  await updateDoc(notificationRef, { read: false });

  // console.log(`✅ Notification ${notificationId} marked as unread.`);
};

/**
 * @function fetchContractorsBySubscriptionType
 * @description Fetches a list of contractors from the `users` collection where `subscriptionType` matches the specified value.
 * 
 * @param {number} subscriptionType - The subscription type to filter contractors (e.g., `0` for Platinum Contractors).
 * @returns {Promise<Array<{id: string, name: string}>>} A promise that resolves to an array of contractor objects with `id` and `name` properties.
 * @throws Will throw an error if the Firestore query fails.
 */
export const fetchContractorsBySubscriptionType = async (subscriptionType) => {
  try {
    const q = query(
      collection(db, "users"),
      where("subscriptionType", "==", subscriptionType)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().companyName, // Assuming the user's name is stored under `name`
    }));
  } catch (error) {
    throw new Error("Failed to fetch contractors: " + error.message);
  }
};

/**
 * @function updateJobBypassHold
 * @description Updates a job record in the `jobs` collection to add the `bypassHold` field with a value of `true`.
 * 
 * @param {string} jobId - The ID of the job to update.
 * @returns {Promise<void>} Resolves if the update is successful, otherwise throws an error.
 */
export const updateJobBypassHold = async (jobId) => {
  try {
    const jobRef = doc(db, "jobs", jobId);
    await updateDoc(jobRef, {
      bypassHold: true,
    });

    // 🔹 Update admin broadcast
    await updateAdminBroadcast();

    // console.log(`Job ${jobId} successfully updated with bypassHold: true.`);
  } catch (error) {
    throw new Error(`Failed to update job ${jobId}: ${error.message}`);
  }
};

/**
 * @function uploadPhoto
 * @description Uploads a photo to Firebase Storage and returns the download URL.
 * 
 * @param {File} file - The photo file to be uploaded.
 * @param {string} path - The path in Firebase Storage where the photo will be uploaded.
 * @returns {Promise<string>} The download URL of the uploaded photo.
 */
export const uploadPhoto = async (file, path) => {
  try {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    throw new Error(`Failed to upload photo: ${error.message}`);
  }
};

/**
 * Creates an appointment record for a job, allowing multiple proposed dates.
 *
 * @param {string} jobId - The ID of the job the appointment belongs to.
 * @param {Object} appointmentDetails - Details of the appointment.
 * @param {Array<Timestamp>} appointmentDetails.dates - An array of Firestore timestamps representing proposed dates.
 * @param {string} appointmentDetails.contractor - The contractor proposing the appointment.
 * @returns {Promise<string>} - The ID of the created appointment record.
 */
export const createAppointment = async (jobId, appointmentDetails) => {
  try {
    const appointmentRef = await addDoc(collection(db, "appointments"), {
      jobId,
      dates: appointmentDetails.dates, // ✅ Now stores multiple dates in an array
      contractor: appointmentDetails.contractor,
      isAccepted: false, 
      status: "suggested",
      createdAt: serverTimestamp(),
    });
    return appointmentRef.id;
  } catch (error) {
    throw new Error("Failed to create appointment: " + error.message);
  }
};

/**
 * Fetches appointment records where:
 * - The `dates` field is not empty (indicating the contractor has offered dates).
 * - The `landlordConfirmedDates` field does **not** exist (meaning the landlord has not confirmed a date yet).
 *
 * @async
 * @function fetchAppointmentsWithDates
 * @returns {Promise<Array<{ jobId: string }>>} A promise that resolves to an array of objects, each containing a `jobId` from qualifying appointment records.
 */
export const fetchAppointmentsWithDates = async () => {
  const appointmentsRef = collection(db, "appointments");
  
  // Query where 'dates' is not empty
  const q = query(appointmentsRef, where("dates", "!=", []));

  const snapshot = await getDocs(q);

  return snapshot.docs
    .filter(doc => !doc.data().hasOwnProperty("landlordConfirmedDates")) // Exclude if landlordConfirmedDates exists
    .map(doc => ({ jobId: doc.data().jobId }));
};

/**
 * Updates a quote document with feedback details and rejection status.
 *
 * @param {string} jobId - The unique identifier for the job/quote.
 * @param {string} feedbackDetails - The feedback provided by the landlord.
 * @returns {Promise<void>} A promise that resolves when the update is complete.
 * @throws Will throw an error if the update operation fails.
 */
export const updateQuoteWithFeedback = async (jobId, feedbackDetails) => {
  if (!jobId) {
    throw new Error('Job ID is required to update the quote.');
  }

  const quoteRef = doc(db, 'quotes', jobId);

  await updateDoc(quoteRef, {
    feedbackDetails: feedbackDetails,
    feedbackDate: serverTimestamp(),
    status: -1, // Assuming -1 indicates a rejected status
  });

  // 🔹 Update admin broadcast
  const updatesRef = doc(db, "updates", "admin");
  await updateDoc(updatesRef, { lastUpdated: Date.now() });

};

// Fetch all admins from Firestore
export const fetchAllAdmins = async () => {
  const usersRef = collection(db, "users");
  const adminQuery = query(usersRef, where("role", "==", "Admin"));
  const snapshot = await getDocs(adminQuery);
  
  return snapshot.docs.map(doc => doc.data().email); // Extract admin emails
};

/**
 * Fetches property details by ID.
 * 
 * @param {string} propertyId - The unique ID of the property.
 * @returns {Promise<Object>} - Resolves to an object with address, city, and postcode.
 * @throws {Error} - Throws an error if the property is not found.
 */
export const fetchPropertyDetails = async (propertyId) => {
  if (!propertyId) throw new Error("Property ID is missing");

  const propertyDocRef = doc(db, "properties", propertyId);
  const propertyDoc = await getDoc(propertyDocRef);

  if (!propertyDoc.exists()) {
    throw new Error(`Property not found for ID: ${propertyId}`);
  }

  const propertyData = propertyDoc.data();
  return {
    address: propertyData.address || "Unknown Address",
    companyCity: propertyData.companyCity || "Unknown Town/City",
    postcode: propertyData.postcode || "Unknown Postcode",
  };
};

/**
 * Updates a user's profile in Firestore and refreshes the user context if applicable.
 * 
 * @param {string} email - The user's email (used as the Firestore document key).
 * @param {Object} updatedData - The profile fields to update.
 * @param {Function} [setUser] - (Optional) Context function to update the logged-in user state.
 * @param {string} [loggedInEmail] - The currently logged-in user's email.
 * @returns {Promise<void>}
 */
export const updateUserProfile = async (email, updatedData, setUser, loggedInEmail) => {
  if (!email) throw new Error("User email is required for profile update.");

  try {
    const userRef = doc(db, "users", email);
    await updateDoc(userRef, updatedData);

    // ✅ Only refresh the logged-in user's context if they are editing themselves
    if (setUser && loggedInEmail === email) {
      const updatedUserDoc = await getDoc(userRef);
      if (updatedUserDoc.exists()) {
        setUser(updatedUserDoc.data()); // ✅ Only update session if editing self
      }
    }

  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

/**
 * Fetches all properties associated with a given landlord's email.
 * @param {string} landlordEmail - The email of the landlord.
 * @returns {Promise<Array>} - Resolves to an array of property objects.
 */
export const fetchPropertyByLandlordsEmail = async (landlordEmail) => {
  if (!landlordEmail) throw new Error("Landlord email is required");

  try {
    const propertiesRef = collection(db, "properties");
    const q = query(propertiesRef, where("landlordsEmail", "==", landlordEmail));
    const querySnapshot = await getDocs(q);

    const properties = querySnapshot.docs.map(doc => ({
      id: doc.id, 
      ...doc.data(),
    }));

    return properties;
  } catch (error) {
    console.error("Error fetching properties for landlord:", error);
    throw error;
  }
};

export const updatePropertyById = async (propertyId, updatedData) => {
  if (!propertyId) throw new Error("Property ID is missing");

  const propertyRef = doc(db, "properties", propertyId);
  await updateDoc(propertyRef, updatedData);
};

export const deleteFileFromStorage = async (fileUrl) => {
  if (!fileUrl) return;

  try {
    const storage = getStorage();
    const fileRef = ref(storage, fileUrl);
    await deleteObject(fileRef);
    // console.log("File deleted from storage:", fileUrl);
  } catch (error) {
    console.error("Error deleting file from Firebase Storage:", error);
    throw error;
  }
};

export const uploadFileToStorage = async (file, path) => {
  const storage = getStorage();
  const fileRef = ref(storage, `${path}/${file.name}`);

  try {
    const snapshot = await uploadBytes(fileRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file to Firebase Storage:", error);
    throw error;
  }
};

/**
 * Deletes a notification from Firestore.
 * @param {string} notificationId - ID of the notification to delete.
 */
export const deleteNotification = async (notificationId) => {
  if (!notificationId) throw new Error("Notification ID is required.");
  
  try {
    const notificationRef = doc(db, "notifications", notificationId);
    await deleteDoc(notificationRef);
  } catch (error) {
    console.error("Error deleting notification:", error);
    throw error;
  }
};

/**
 * Marks a tab as viewed by updating the user's profile flags.
 * 
 * @param {string} email - The user's email (used as the Firestore document key).
 * @param {string} field - The notification flag to reset (e.g., "quoteNotif", "commentNotif").
 */
export const markTabAsViewed = async (email, field, jobId) => {
  try {
    const userRef = doc(db, "users", email);
    await updateDoc(userRef, { [field]: false }); // Clear unread flag in Firestore

    // Find the corresponding notification for this job and mark it as read
    const notificationsRef = collection(db, "notifications");
    const q = query(
      notificationsRef,
      where("userId", "==", email),
      where("jobId", "==", jobId),
      where("read", "==", false) // Only unread ones
    );

    const snapshot = await getDocs(q);
    snapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, { read: true }); // Mark as read
    });

    // console.log(`Marked ${field} as viewed for ${email} and updated notifications.`);
  } catch (error) {
    console.error("Error marking tab as viewed:", error);
  }
};

export const updateAdminBroadcast = async () => {
  try {
    const updatesRef = doc(db, "updates", "admin");
    const now = Date.now();
    
    // 🔹 Check if the document exists
    const docSnap = await getDoc(updatesRef);
    if (docSnap.exists()) {
      await updateDoc(updatesRef, { lastUpdated: now }); 
    } else {
      await setDoc(updatesRef, { lastUpdated: now }); 
    }

    // console.log("✅ updates/admin broadcast updated:", now);
  } catch (error) {
    console.error("❌ Error updating updates/admin:", error);
  }
};

// import { collection, query, where, getDocs } from "firebase/firestore";
// import { db } from "../firebase";

/**
 * Fetch jobs that are still awaiting their start date.
 * Filters out jobs where the earliest landlordConfirmedDate has passed.
 * 
 * @returns {Promise<Array>} - List of jobs that are still upcoming.
 */
export const fetchUpcomingStartDateJobs = async () => {
  try {
    const jobsRef = collection(db, "jobs");
    const jobsQuery = query(jobsRef, where("status", "==", 5));
    const jobsSnapshot = await getDocs(jobsQuery);
    // console.log("🔥 Raw Job Docs:", jobsSnapshot.docs.map(doc => doc.data())); // 🔍 Log raw Firestore output

    if (jobsSnapshot.empty) return []; // No jobs found

    const jobs = [];
    for (const jobDoc of jobsSnapshot.docs) {
      const jobData = { jobId: jobDoc.id, ...jobDoc.data() };

      // 🔹 Fetch appointments related to this job
      const appointmentsRef = collection(db, "appointments");
      const appointmentQuery = query(appointmentsRef, where("jobId", "==", jobData.jobId));
      const appointmentSnapshot = await getDocs(appointmentQuery);

      let earliestDate = null;

      appointmentSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.landlordConfirmedDates && data.landlordConfirmedDates.length > 0) {

          const confirmedDates = data.landlordConfirmedDates
          .filter(entry => entry?.date) // ✅ Ensure entry has a valid `date` property
          .map(entry => {
            const parsedDate = new Date(entry.date.includes("T") ? entry.date : `${entry.date}T00:00:00`); // ✅ Normalize format
            return isNaN(parsedDate) ? null : parsedDate; // ✅ Filter out invalid dates
          })
          .filter(date => date !== null); // ✅ Remove null values
          const minDate = new Date(Math.min(...confirmedDates));

          if (!earliestDate || minDate < earliestDate) {
            earliestDate = minDate;
          }
        }
      });

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize to midnight for comparison

      // 🔹 Add only jobs that have a future confirmed date
      if (!earliestDate || earliestDate > today) {
        jobs.push(jobData);
      }
    }

    return jobs;
  } catch (error) {
    console.error("Error fetching upcoming start date jobs:", error);
    return [];
  }
};

/**
 * Fetches jobs that are in progress (status = 5) and have a start date of today or earlier.
 * Supports filtering by landlord or contractor.
 *
 * @param {Object} options - Filtering options.
 * @param {string} [options.landlordEmail] - Landlord's email for filtering.
 * @param {string} [options.contractorEmail] - Contractor's email for filtering.
 * @returns {Promise<Array>} A list of filtered jobs that are currently in progress.
 */
export const fetchInProgressJobs = async (options = {}) => {
  try {
    const jobsRef = collection(db, "jobs");
    const filters = [where("status", "==", 5)];

    if (options.landlordEmail) {
      filters.push(where("landlordEmail", "==", options.landlordEmail));
    }
    if (options.contractorEmail) {
      filters.push(where("contractorEmail", "==", options.contractorEmail));
    }

    const jobsQuery = query(jobsRef, ...filters);
    const jobsSnapshot = await getDocs(jobsQuery);

    if (jobsSnapshot.empty) return []; // No jobs found

    const jobs = [];
    for (const jobDoc of jobsSnapshot.docs) {
      const jobData = { jobId: jobDoc.id, ...jobDoc.data() };

      // 🔹 Fetch appointments related to this job
      const appointmentsRef = collection(db, "appointments");
      const appointmentQuery = query(appointmentsRef, where("jobId", "==", jobData.jobId));
      const appointmentSnapshot = await getDocs(appointmentQuery);

      let earliestDate = null;

      appointmentSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.landlordConfirmedDates && data.landlordConfirmedDates.length > 0) {
          const confirmedDates = data.landlordConfirmedDates
            .filter(entry => entry?.date)
            .map(entry => {
              const parsedDate = new Date(entry.date.includes("T") ? entry.date : `${entry.date}T00:00:00`);
              return isNaN(parsedDate) ? null : parsedDate;
            })
            .filter(date => date !== null);

          const minDate = new Date(Math.min(...confirmedDates));

          if (!earliestDate || minDate < earliestDate) {
            earliestDate = minDate;
          }
        }
      });

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // 🔹 Add only jobs that have a start date of today or earlier
      if (earliestDate && earliestDate <= today) {
        jobs.push(jobData);
      }
    }

    return jobs;
  } catch (error) {
    console.error("Error fetching in-progress jobs:", error);
    return [];
  }
};


/**
 * Updates the `updates/global` document in Firestore to notify agents, landlords, and contractors of relevant changes.
 * 
 * This function:
 * - Updates the `lastUpdated` timestamp to trigger real-time UI refreshes.
 * - Stores a list of `affectedUsers` (emails of impacted users).
 * - Prevents unnecessary writes if `affectedUsers` hasn't changed.
 *
 * @param {string[]} affectedUsers - An array of email addresses for users who should refresh their dashboards.
 * @returns {Promise<void>} Resolves when Firestore has been successfully updated.
 */
export const updateGlobalBroadcast = async (affectedUsers = []) => {
  try {
    const updatesRef = doc(db, "updates", "global");
    const now = Date.now();

    // 🔹 Check if the document exists
    const docSnap = await getDoc(updatesRef);
    if (docSnap.exists()) {
      const existingData = docSnap.data();

      // ✅ Only update if affectedUsers list has changed
      if (JSON.stringify(existingData.affectedUsers || []) !== JSON.stringify(affectedUsers)) {
        await updateDoc(updatesRef, { lastUpdated: now, affectedUsers });
      } else {
        await updateDoc(updatesRef, { lastUpdated: now }); // Only timestamp update
      }
    } else {
      await setDoc(updatesRef, { lastUpdated: now, affectedUsers });
    }

    // console.log("✅ updates/global broadcast updated:", now, "Affected Users:", affectedUsers);
  } catch (error) {
    console.error("❌ Error updating updates/global:", error);
  }
};
