// src/services/quoteService.js
import { db } from '../firebase';
import { collection, doc, setDoc, updateDoc, getDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { updateAdminBroadcast } from './dbService';

/**
 * Inserts a new quote into the 'quotes' collection and updates the corresponding job's status.
 * @param {string} jobId - The ID of the job for which the quote is being created.
 * @param {Object} quoteData - The data for the quote to be inserted.
 * @returns {Promise} - A promise that resolves when the operation completes.
 */
export const createQuote = async (jobId, quoteData, status) => {
  // alert("createQuote - delete");
  try {
    // console.log('quoteService: Creating quote with data:', quoteData);
    const quoteRef = doc(collection(db, 'quotes'), jobId);
    const quotePayload = {
      ...quoteData,
      createdAt: serverTimestamp(),
    };
    await setDoc(quoteRef, quotePayload);
    // console.log('quoteService: Quote created successfully for jobId:', jobId);

    const jobRef = doc(db, 'jobs', jobId);
    await updateDoc(jobRef, { status: status });
    // console.log('quoteService: Job status updated to 1 for jobId:', jobId);

    // 🔹 Update admin broadcast
    await updateAdminBroadcast();
    
  } catch (error) {
    console.error('quoteService: Error creating quote:', error);
    throw new Error('Failed to create quote and update job status.');
  }
};

/**
 * Fetches an existing quote from the 'quotes' collection using the job ID.
 * @param {string} jobId - The ID of the job for which the quote is being fetched.
 * @returns {Promise<Object|null>} - The quote data if found, otherwise null.
 */
export const fetchQuote = async (jobId) => {
  try {
    const quoteRef = doc(db, 'quotes', jobId);
    const quoteSnapshot = await getDoc(quoteRef);

    if (quoteSnapshot.exists()) {
      // console.log('quoteService: Quote data retrieved for jobId:', jobId);
      return quoteSnapshot.data(); // Return the quote data
    } else {
      // console.log('quoteService: No quote found for jobId:', jobId);
      return null; // No document found
    }
  } catch (error) {
    console.error('quoteService: Error fetching quote:', error);
    throw new Error('Failed to fetch quote data.');
  }
};

/**
 * Fetches a contractor generated quote from the 'quotes' collection using the quoteId.
 * @param {string} quoteId - The ID of the job for which the quote is being fetched.
 * @returns {Promise<Object|null>} - The quote data if found, otherwise null.
 */

export const fetchContractorQuoteById = async (quoteId) => {
  const quoteRef = doc(db, "quotes", quoteId);
  const quoteSnap = await getDoc(quoteRef);
  return quoteSnap.exists() ? quoteSnap.data() : null;
};

/**
 * Mock function to notify landlord about the new quote submission.
 * 
 * @param {string} landlordEmail - The email of the landlord to notify.
 * @param {string} jobId - The job ID associated with the quote.
 */
export const notifyLandlord = (landlordEmail, jobId) => {
  // console.log(`Notification sent to landlord at ${landlordEmail} for job ID: ${jobId}`);
  // In the future, integrate actual email or notification service here.
};

/**
 * Fetches the list of contractor-provided quote IDs for a given job.
 *
 * @param {string} jobId - The ID of the job.
 * @returns {Promise<string[]>} - An array of contractor quote IDs or an empty array if none exist.
 */
export const fetchContractorQuoteIds = async (jobId) => {
  try {
    const signpostRef = doc(collection(db, "quotes"), jobId); // The signpost document
    const signpostSnapshot = await getDoc(signpostRef);

    if (signpostSnapshot.exists()) {
      const signpostData = signpostSnapshot.data();
      return signpostData.contractorQuoteIds || []; // Return the array of quote IDs
    }
  } catch (error) {
    console.error("Error fetching contractor quotes:", error);
  }
  return [];
};

export const renameAndStoreChosenQuote = async (jobId, quoteId) => {
  try {
    const originalDocRef = doc(db, "quotes", jobId); // Existing contractor quote
    const newDocRef = doc(db, "quotes", `${jobId}_accepted`); // New name

    const docSnap = await getDoc(originalDocRef);
    if (!docSnap.exists()) {
      console.error("Quote not found.");
      return;
    }

    const quoteData = docSnap.data();

    // Copy data to new document with additional field
    await setDoc(newDocRef, {
      ...quoteData,
      chosenQuoteId: quoteId, // Store which contractor quote was chosen
    });

    // Delete the old document after successful copy
    await deleteDoc(originalDocRef);

    // 🔹 Update admin broadcast
    await updateAdminBroadcast();

    // console.log(`Renamed quote ${jobId} to ${jobId}_accepted and stored chosenQuoteId.`);
  } catch (error) {
    console.error("Error renaming quote:", error);
  }
};

/**
 * Creates a new standard quote document from an accepted contractor quote.
 *
 * This function copies the data from the renamed `M1020-4_accepted` contractor quote 
 * and creates a new `M1020-4` document in the `quotes` collection. This aligns 
 * contractor-accepted quotes with admin-generated quotes for a unified job flow.
 *
 * @param {string} jobId - The unique job ID associated with the accepted quote.
 * @returns {Promise<void>} Resolves when the new standard quote is successfully created.
 */
export const createStandardQuoteFromAccepted = async (jobId, selectedQuoteId) => {
  try {
    const acceptedQuoteRef = doc(db, "quotes", selectedQuoteId);
    const newQuoteRef = doc(db, "quotes", jobId); // Standard quote name

    const docSnap = await getDoc(acceptedQuoteRef);
    if (!docSnap.exists()) {
      console.error("Accepted quote not found.");
      return;
    }

    const quoteData = docSnap.data();

    // Ensure contractor details are included
    if (!quoteData.contractorId || !quoteData.companyName) {
      console.error("Missing contractor details in accepted quote.");
      return;
    }

    // Copy quote data into a new standard quote doc
    await setDoc(newQuoteRef, {
      ...quoteData,
      standardQuote: true, // Optional flag to indicate standardization
      createdFrom: "Contractor", // Identifies that this was a contractor quote
    });

    // 🔹 Update admin broadcast
    await updateAdminBroadcast();

    // console.log(`Created new standard quote: ${jobId} with contractor details.`);
  } catch (error) {
    console.error("Error creating standard quote:", error);
  }
};

/**
 * Updates the job record with pricing and acceptance details from the selected contractor quote.
 *
 * @param {string} jobId - The job ID to update.
 * @param {string} quoteId - The accepted contractor quote ID.
 * @returns {Promise<void>} Resolves when the job record is updated.
 */
export const updateJobWithAcceptedQuoteDetails = async (jobId, quoteId) => {
  try {
    const quoteRef = doc(db, "quotes", quoteId);
    const jobRef = doc(db, "jobs", jobId);

    const quoteSnap = await getDoc(quoteRef);
    if (!quoteSnap.exists()) {
      console.error("Accepted quote not found.");
      return;
    }

    const quoteData = quoteSnap.data();

    // Extract financial data from costBreakdown (assuming first item holds the correct values)
    const costBreakdown = quoteData.costBreakdown?.[0]; // Get the first item safely

    if (!costBreakdown) {
      throw new Error('Cost breakdown missing for quote');
    }

    // Update job with financial and acceptance details
    await updateDoc(jobRef, {
      quoteAmount: costBreakdown.total,
      vatAmount: costBreakdown.vatAmount,
      quoteAccepted: true,
      quoteAcceptedAt: serverTimestamp(),
      quoteTime: serverTimestamp(),
    });

    // 🔹 Update admin broadcast
    const updatesRef = doc(db, "updates", "admin");
    await updateDoc(updatesRef, { lastUpdated: Date.now() });

    // console.log(`Updated job ${jobId} with contractor quote details.`);
  } catch (error) {
    console.error("Error updating job with accepted quote details:", error);
  }
};
