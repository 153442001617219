
import React, { useState } from 'react';
import SettingsLanding from './SettingsLanding';
import SettingsAddUser from './SettingsAddUser';
import SettingsUsers from './SettingsUsers';
import SettingsTrades from './SettingsTrades';
import SettingsPreQuotedWorks from './SettingsPreQuotedWorks';
import SettingsPayments from './SettingsPayments';
import Filter from './SettingsFilter';
import Platinum from './SettingsPlatinum';
import GenerateEmail from './GenerateEmail.js';
import '../styles/Settings.css';

const SettingsPage = () => {
  const [activePage, setActivePage] = useState('Landing'); 
  return (
    <div className="settings-page">
      <div className="second-column-nav">
        <ul>
          <li onClick={() => setActivePage('AddUser')}>Add User</li>
          <li onClick={() => setActivePage('Users')}>Users</li>
          <li onClick={() => setActivePage('Trades')}>Trades</li>
          <li onClick={() => setActivePage('PreQuotedWorks')}>Pre Quoted Works</li>
          <li onClick={() => setActivePage('Landing')}>Home</li>

        </ul>
      </div>

      <div className="content-section">
        {activePage === 'Landing' && <SettingsLanding />}
        {activePage === 'AddUser' && <SettingsAddUser />}
        {activePage === 'Users' && <SettingsUsers />}
        {activePage === 'Trades' && <SettingsTrades />}
        {activePage === 'PreQuotedWorks' && <SettingsPreQuotedWorks />}
        {activePage === 'Payments' && <SettingsPayments />}
        {activePage === 'Filter' && <Filter />}
        {activePage === 'Platinum' && <Platinum />}
        {activePage === 'GenerateEmail' && <GenerateEmail />}

      </div>

    </div>
  );
};

export default SettingsPage;
