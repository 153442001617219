import { useState, useEffect } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, sendSignInLinkToEmail, 
  onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true); // ✅ Add loading state
  const auth = getAuth(); // Ensure auth is initialized

  useEffect(() => {
    const persistSession = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
        // console.log("Session persistence enabled.");
      } catch (error) {
        console.error("Error setting persistence:", error);
      }
    };
  
    persistSession();
  }, [auth]); 

  useEffect(() => {
    const auth = getAuth();

    const handleEmailSignIn = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const emailForSignIn = window.localStorage.getItem('emailForSignIn');
        if (!emailForSignIn) {
          alert('The sign-in process could not be completed. Please click the email link again.');
          window.location.href = '/login-user';
          return;
        }

        try {
          const result = await signInWithEmailLink(auth, emailForSignIn, window.location.href);
          setIsAuthenticated(true);
          setUserEmail(result.user.email);
        } catch (error) {
          if (error.code === 'auth/invalid-action-code' || error.code === 'auth/expired-action-code') {
            alert('Your sign-in link has expired. We will send you a new one now.');
            
            const urlParams = new URLSearchParams(window.location.search);
            const email = urlParams.get("email");

            if (email) {
              try {
                const actionCodeSettings = {
                  url: `https://completemaintenance.uk/complete-profile?email=${encodeURIComponent(email)}`,
                  handleCodeInApp: true,
                };
        
                await sendSignInLinkToEmail(auth, email, actionCodeSettings);
                alert(`A new sign-in link has been sent to ${email}. Please check your inbox.`);
              } catch (resendError) {
                console.error("Error resending sign-in link:", resendError);
                alert("Failed to resend sign-in link. Please try again or contact support.");
              }
            } else {
              alert("We couldn't determine your email. Please request a new sign-in link.");
            }          
          } else {
            alert('Error signing in with email link: ' + error.message);
          }
        }
      }
    };

    handleEmailSignIn();

    // ✅ Listen for auth state changes and track loading
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUserEmail(user.email);
      } else {
        setIsAuthenticated(false);
        setUserEmail('');
      }
      setLoading(false); // ✅ Set loading to false when Firebase check is complete
    });

    return () => unsubscribe();
  }, []);

  const logout = () => {
    auth.signOut()
      .then(() => {
        setIsAuthenticated(false);
        setUserEmail('');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return { isAuthenticated, userEmail, logout, loading }; // ✅ Return loading state
};

export default useAuth;
