import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { isPlatinumWindowActive, calcPlatinumEndTime } from '../utils/jobVisibility';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { formatTimestamp } from '../Utilities/dateUtils';
import { updateJobBypassHold } from "../services/dbService";

const JobApprovedAwaitingContractor = ({ isSingleColumn, onRowSelectAction, selectedJob }) => {
  const [approvedJobs, setApprovedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  // 🔹 Stable function to fetch grid data
  const loadApprovedJobs = useCallback(async () => {
    // console.log("🔄 Fetching Approved Jobs...");
    const options = { status: 2 };
    const jobs = await fetchJobs(options); 

    const filteredJobs = jobs.filter((job) => {
      if (job.bypassHold) return false; // Exclude bypassed jobs

      const quoteAcceptedAt = job.quoteAcceptedAt.toDate(); 
      const platinumEndTime = calcPlatinumEndTime(quoteAcceptedAt, job.jobId);
      const isWithinPlatinumWindow = isPlatinumWindowActive(platinumEndTime);

      if (isWithinPlatinumWindow) {
        job.changesAt = platinumEndTime;
        return true;
      }

      return false;
    });

    setApprovedJobs(filteredJobs);
  }, []);

  useEffect(() => {
    const handleRefresh = () => {
      // console.log("🔄 JobApprovedAwaitingContractor grid refreshing...");
      loadApprovedJobs(); // ✅ Consistent with previous two grids
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadApprovedJobs]);

  useEffect(() => {
    loadApprovedJobs(); // ✅ Ensures data loads on mount
  }, [loadApprovedJobs]);

  const handleReleaseJob = async (jobId) => {
    // console.log(`🔹 Releasing Job: ${jobId}`);
    try {
      await updateJobBypassHold(jobId);
      // console.log(`✅ Job ${jobId} successfully released.`);
      loadApprovedJobs();
    } catch (error) {
      alert(`❌ Error updating job: ${error.message}`);
    }
  };

  const columnDefsApprovedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.5 },
    { 
      headerName: "TRADE",
      field: "tradeName",
      flex: 0.9,
      filter: true,
      cellRenderer: TradeCellRenderer,
      valueGetter: (params) => params.data.tradeName === 'Other (please specify)' 
        ? params.data.instructionNotes || 'N/A' 
        : params.data.tradeName,
    },
    { headerName: "POSTCODE", field: "propertyDetails.postcode", flex: 0.6 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 2.8 },
    { 
      headerName: "PLATINUM UNTIL", 
      field: "changesAt", 
      flex: 0.9,
      valueFormatter: (params) => new Date(params.value).toLocaleString()
    },
    { 
      headerName: "VALUE", 
      field: "quoteAmount", 
      flex: 0.5,
      valueFormatter: (params) => new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(params.value ? parseFloat(params.value) : 0)
    },
    { 
      headerName: "PAID AT", 
      field: "quoteAcceptedAt", 
      flex: 0.8,
      valueFormatter: (params) => formatTimestamp(params.value)
    },
    { 
      headerName: "RELEASE", 
      flex: 1.0,
      suppressClickEdit: true,
      cellRenderer: ({ data }) => (
        <button onClick={(e) => { e.stopPropagation(); handleReleaseJob(data.jobId); }} style={{ marginLeft: 10 }}>
          Release Job
        </button>
      )
    }
  ];
          
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    if (event.event?.target.tagName === "BUTTON") return; // Ignore button clicks

    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };

    onRowSelectAction(jobData, tabModes);
  };

  return (
    <GridPanel
      title="Jobs Approved Awaiting Distribution"
      columnDefs={columnDefsApprovedJobs}
      rowData={approvedJobs}
      isSingleColumn={isSingleColumn}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick}
    />
  );
};

export default JobApprovedAwaitingContractor;
