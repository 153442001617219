// src/services/jobService.js

import { db } from '../firebase';
import { doc, setDoc, collection, serverTimestamp, updateDoc, query, where, getDocs, getDoc } from 'firebase/firestore';
import { getNextJobId, updateAdminBroadcast } from './dbService'; // Ensure the path is correct
import { fetchContractorQuoteIds } from "./quoteService";

const jobsCollection = collection(db, 'jobs'); // Reference to the jobs collection

/**
 * Fetches contractor quote IDs linked to a specific job.
 * @param {string} jobId - The ID of the job.
 * @returns {Promise<Array<string>>} - An array of quote IDs.
 */
export const getContractorQuotes = async (jobId) => {
  if (!jobId) return [];
  return await fetchContractorQuoteIds(jobId);
};

/**
 * Inserts a job with a unique ID based on the propertyId.
 * Uses `getNextJobId` to determine the next available job number.
 *
 * @param {Object} jobData - Data for the job to be inserted.
 * @param {string} propertyId - The ID of the property associated with the job.
 * @returns {Promise<string>} - The unique job ID assigned to the new job.
 */
export const insertJobWithNumbering = async (jobData, propertyId) => {
  try {
    if (!propertyId) {
      throw new Error('Property ID is required to insert a job!');
    }

    // Get the next job ID using the shared numbering system
    const jobId = await getNextJobId(propertyId);
    // console.log('Generated Job ID:', jobId);

    // Add the job document
    const jobDocRef = doc(collection(db, 'jobs'), jobId);
    await setDoc(jobDocRef, {
      ...jobData,
      jobId,
      propertyId,
      createdAt: serverTimestamp(), // Use Firestore's server timestamp
      status: 0, // Default status == 0
      completedDate: null,
      contractorName: null,
    });

    // 🔹 Update admin broadcast
    await updateAdminBroadcast();

    // console.log('Job successfully added with ID:', jobId);
    return jobId;
  } catch (error) {
    console.error('Error inserting job:', error);
    throw error;
  }
};

/**
 * Fetches jobs with optional filters and includes associated property details.
 *
 * @param {Object} options - Optional filters (e.g., { landlordEmail: 'email@example.com', status: 2 }).
 *   - `landlordEmail`: (string) Filter to return jobs associated with a specific landlord.
 *   - `agentId`: (string) Filter to return jobs associated with a specific agent.
 *   - `status`: (number) Filter to return jobs with a specific status.
 *   - `contractorName: (string) Filter to return jobs associated with either contractor, or all unsassigned (null)
 * @returns {Promise<Array>} - An array of jobs with merged property details.
 */
export const fetchJobs = async (options = {}) => {
  try {
    // Start with the base jobs query
    let jobQuery = jobsCollection;

    const filters = [];
    if (options.landlordEmail) {
      filters.push(where('landlordEmail', '==', options.landlordEmail));
    }
    if (options.agentId) {
      filters.push(where('agentId', '==', options.agentId));
    }
    if (options.status !== undefined) {
      if (Array.isArray(options.status))  {
        filters.push(where('status', 'in', options.status));
      } else {
        filters.push(where('status', '==', options.status));
      }
    }

    if (options.contractorName !== undefined) {
      if (options.contractorName === null) {
        filters.push(where("contractorName", "==", null));
      } else {
        filters.push(where("contractorEmail", "==", options.contractorEmail));
      }
    }

    if (options.contractorEmail !== undefined) {
      filters.push(where("contractorEmail", "==", options.contractorEmail));
    }

    if (filters.length > 0) {
      jobQuery = query(jobQuery, ...filters);
    }

    const querySnapshot = await getDocs(jobQuery);

    const jobs = await Promise.all(
      querySnapshot.docs.map(async (jobDoc) => {
        const jobData = { id: jobDoc.id, ...jobDoc.data() };

        // Fetch the property associated with this job
        if (jobData.propertyId) {
          const propertyRef = doc(db, 'properties', jobData.propertyId);
          const propertySnap = await getDoc(propertyRef);

          if (propertySnap.exists()) {
            jobData.propertyDetails = {
              id: propertySnap.id,
              ...propertySnap.data(),
            };
          } else {
            console.warn(`No property found for propertyId: ${jobData.propertyId}`);
            jobData.propertyDetails = null;
          }
        } else {
          jobData.propertyDetails = null;
        }
        return jobData;
      })
    );

    return jobs;
  } catch (error) {
    console.error('Error fetching jobs with property details:', error);
    throw error;
  }
};

/**
 * Fetches jobs for a specific property.
 *
 * @param {string} propertyId - The ID of the property.
 * @returns {Promise<Array>} - An array of jobs associated with the property.
 */
export const fetchJobsByProperty = async (options, propertyId) => {
  try {
        const jobsQuery = query(collection(db, 'jobs'), where("propertyId", "==", propertyId),
                                                    where("status", "==", options.status));
const querySnapshot = await getDocs(jobsQuery);

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching jobs by property:', error);
    throw error;
  }
};

/**
 * Updates the status of a job in the Firestore database.
 *
 * @param {string} jobId - The unique ID of the job to be updated.
 * @param {number} newStatus - The new status to assign to the job.
 * @returns {Promise<void>} - A promise that resolves once the update is complete.
 *   - If the jobId is not found, the update will silently complete with no effect.
 */
export const updateJobStatus = async (jobId, newStatus) => {
  try {
    // console.log('quoteService: Updating job status for jobId:', jobId, 'to status:', newStatus);
    const jobRef = doc(db, 'jobs', jobId);
    await updateDoc(jobRef, { status: newStatus });

    // 🔹 Update admin broadcast
    await updateAdminBroadcast();

    // console.log('quoteService: Job status updated successfully.');
  } catch (error) {
    console.error('quoteService: Error updating job status:', error);
    throw new Error('Failed to update job status');
  }
};

/**
 * Fetches jobs for a landlord based on their email and an array of statuses.
 *
 * @param {string} landlordEmail - The landlord's email to filter jobs.
 * @param {Array<number>} statuses - Array of status values to filter jobs.
 * @returns {Promise<Array>} - An array of jobs with merged property details.
 */
export const fetchJobsByLandlordAndStatuses = async (landlordEmail, statuses) => {
  if (!landlordEmail || typeof landlordEmail !== "string") {
    throw new Error("Invalid landlordEmail: Must be a non-empty string.");
  }

  if (!Array.isArray(statuses) || statuses.some((status) => typeof status !== "number")) {
    throw new Error("Invalid statuses: Must be an array of numbers.");
  }

  try {
    const filters = [
      where("landlordEmail", "==", landlordEmail),
      where("status", "in", statuses), // Use the "in" operator for multiple statuses
    ];

    const jobsQuery = query(jobsCollection, ...filters);
    const querySnapshot = await getDocs(jobsQuery);

    const jobs = await Promise.all(
      querySnapshot.docs.map(async (jobDoc) => {
        const jobData = { id: jobDoc.id, ...jobDoc.data() };

        // Fetch property details for the job
        if (jobData.propertyId) {
          const propertyRef = doc(db, "properties", jobData.propertyId);
          const propertySnap = await getDoc(propertyRef);

          if (propertySnap.exists()) {
            jobData.propertyDetails = {
              id: propertySnap.id,
              ...propertySnap.data(),
            };
          } else {
            console.warn(`No property found for propertyId: ${jobData.propertyId}`);
            jobData.propertyDetails = null;
          }
        } else {
          jobData.propertyDetails = null;
        }

        return jobData;
      })
    );

    return jobs;
  } catch (error) {
    console.error("Error fetching jobs by landlord and statuses:", error);
    throw error;
  }
};

/**
 * Fetches jobs for an agent based on their email and an array of statuses.
 *
 * @param {string} agentId - The agent's email to filter jobs.
 * @param {Array<number>} statuses - Array of status values to filter jobs.
 * @returns {Promise<Array>} - An array of jobs with merged property details.
 */
export const fetchJobsByAgentAndStatuses = async (agentId, statuses) => {
  if (!agentId || typeof agentId !== "string") {
    throw new Error("Invalid agentId: Must be a non-empty string.");
  }

  if (!Array.isArray(statuses) || statuses.some((status) => typeof status !== "number")) {
    throw new Error("Invalid statuses: Must be an array of numbers.");
  }

  try {
    const filters = [
      where("agentId", "==", agentId), // Filter by agentId
      where("status", "in", statuses), // Use the "in" operator for multiple statuses
    ];

    const jobsQuery = query(jobsCollection, ...filters);
    const querySnapshot = await getDocs(jobsQuery);

    const jobs = await Promise.all(
      querySnapshot.docs.map(async (jobDoc) => {
        const jobData = { id: jobDoc.id, ...jobDoc.data() };

        // Fetch property details for the job
        if (jobData.propertyId) {
          const propertyRef = doc(db, "properties", jobData.propertyId);
          const propertySnap = await getDoc(propertyRef);

          if (propertySnap.exists()) {
            jobData.propertyDetails = {
              id: propertySnap.id,
              ...propertySnap.data(),
            };
          } else {
            console.warn(`No property found for propertyId: ${jobData.propertyId}`);
            jobData.propertyDetails = null;
          }
        } else {
          jobData.propertyDetails = null;
        }

        return jobData;
      })
    );

    return jobs;
  } catch (error) {
    console.error("Error fetching jobs by agent and statuses:", error);
    throw error;
  }
};