import React, { useState, useEffect, useRef } from "react";
import { updateUserProfile, fetchPropertyByLandlordsEmail, 
  updatePropertyById, deleteFileFromStorage,uploadFileToStorage } from "../services/dbService";
import styles from "../styles/ProfileEdit.module.css";
import ImagePopup from '../ImagePopup/ImagePopup';
import { createRoot } from "react-dom/client";
import ImageUpload from '../components/UI/ImageUpload';

const LandlordProfileAdminView = ({ userDetails, onUpdate }) => {
  const [submitMessage, setSubmitMessage] = useState("");
  const [propertyDetail, setPropertyDetail] = useState({});
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyFloorplans, setPropertyFloorplans] = useState([]);
  const propertyImageUploadRef = useRef(null);
  const floorplanImageUploadRef = useRef(null);
    
  const [formData, setFormData] = useState({
    allowEmail: userDetails?.allowEmail ?? true,
    allowNotifications: userDetails?.allowNotifications ?? true,
    enabled: userDetails?.enabled ?? true,
    ...userDetails, // Preserve other profile fields
  });

  const handlePhotoUploadComplete = async (files) => {
    try {
      const uploadedImageUrls = await Promise.all(
        files.map(async (file) => {
          const downloadURL = await uploadFileToStorage(file, `properties/${propertyDetail.id}/images`);
          return downloadURL;
        })
      );
  
      // Update state and Firestore
      const updatedImages = [...propertyImages, ...uploadedImageUrls];
      setPropertyImages(updatedImages);
      await updatePropertyById(propertyDetail.id, { propertyImages: updatedImages });
    } catch (error) {
      console.error("Error uploading property images:", error);
    }
  };
  
  const handleFloorplanUploadComplete = async (files) => {
    try {
      const uploadedFloorplanUrls = await Promise.all(
        files.map(async (file) => {
          const downloadURL = await uploadFileToStorage(file, `properties/${propertyDetail.id}/floorplans`);
          return downloadURL;
        })
      );
  
      // Update state and Firestore
      const updatedFloorplans = [...propertyFloorplans, ...uploadedFloorplanUrls];
      setPropertyFloorplans(updatedFloorplans);
      await updatePropertyById(propertyDetail.id, { propertyFloorplans: updatedFloorplans });
    } catch (error) {
      console.error("Error uploading floorplans:", error);
    }
  };
  
// Property
  useEffect(() => {
    const loadProperty = async () => {
      try {
        const fetchedProperties = await fetchPropertyByLandlordsEmail(userDetails.email);
      
        if (!Array.isArray(fetchedProperties) || fetchedProperties.length === 0) {
          console.error("No property found for this landlord.");
          return;
        }
  
        const fetchedProperty = fetchedProperties[0]; // ✅ Extract the first object from the array
        // console.log("Fetched Property:", fetchedProperty); // Confirm it's now an object
  
        setPropertyDetail(fetchedProperty || {});
        setPropertyImages(Array.isArray(fetchedProperty?.propertyImages) ? fetchedProperty.propertyImages : []);
        setPropertyFloorplans(Array.isArray(fetchedProperty?.propertyFloorplans) ? fetchedProperty.propertyFloorplans : []);


      } catch (error) {
        console.error("Error loading landlord property:", error);
      }
    };

    if (userDetails?.email) {
      loadProperty();
    }
  }, [userDetails.email]);


  const handleDeleteImage = async (index) => {
    const imageToDelete = propertyImages[index];
  
    try {
      await deleteFileFromStorage(imageToDelete); // Remove from Storage
      const updatedImages = propertyImages.filter((_, i) => i !== index);
      setPropertyImages(updatedImages);
      await updatePropertyById(propertyDetail.id, { propertyImages: updatedImages });
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  
  const handleDeleteFloorplan = async (index) => {
    const floorplanToDelete = propertyFloorplans[index];
  
    try {
      await deleteFileFromStorage(floorplanToDelete); // Remove from Storage
      const updatedFloorplans = propertyFloorplans.filter((_, i) => i !== index);
      setPropertyFloorplans(updatedFloorplans);
      await updatePropertyById(propertyDetail.id, { propertyFloorplans: updatedFloorplans });
    } catch (error) {
      console.error("Error deleting floorplan:", error);
    }
  };
  
  const handleImageClick = (index, images) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const popupWidth = 1100;
    const popupHeight = 800;
    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;
  
    const newWindow = window.open("", "_blank", `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
    newWindow.document.title = "Image Viewer";
  
    const container = newWindow.document.createElement("div");
    newWindow.document.body.appendChild(container);
  
    const root = createRoot(container);
    root.render(
      <ImagePopup images={images} initialIndex={index} onClose={() => newWindow.close()} />
    );
  };

  useEffect(() => {
    setFormData(userDetails);
  }, [userDetails]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value, 
    }));
  
    // If the field is related to the property, update propertyDetail state
    if (["address", "companyCity", "postcode"].includes(name)) {
      setPropertyDetail((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage("");
  
    try {
      // ✅ Explicitly include enabled in update
      await updateUserProfile(formData.email, { 
        ...formData, 
        enabled: formData.enabled 
      }, null, userDetails.email);
      
      // ✅ Notify parent component (SettingsUsers) of the update
      if (onUpdate) {
        onUpdate((prev) => ({
          ...prev,
          ...formData, // Merge new changes
        }));
      }
        
      await updatePropertyById(propertyDetail.id, {
        address: propertyDetail.address,
        companyCity: propertyDetail.companyCity,
        postcode: propertyDetail.postcode,
        propertyImages, 
        propertyFloorplans,
      });
  
      setSubmitMessage("Profile and property details updated successfully!");
      setTimeout(() => setSubmitMessage(""), 3000);
    } catch (error) {
      setSubmitMessage("Failed to update profile and property details. " + error);
      setTimeout(() => setSubmitMessage(""), 3000);
    }
  };
      
  return (
    <div className={styles.container}>
      <h2>{userDetails.firstName} {userDetails.lastName}'s Profile - {userDetails.role}</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <fieldset className={styles.profileEditFieldset}>
            {/* Personal Information */}
            <legend className={styles.legend}>Personal Information</legend>
            <div className={styles.row}>
              <div>
                <label className={styles.label}>First Name</label>
                <input type="text" className={styles.profileInput} name="firstName" value={formData.firstName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Last Name</label>
                <input type="text" className={styles.profileInput} name="lastName" value={formData.lastName || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Mobile Number</label>
                <input type="text" className={styles.profileInput} name="mobileNumber" value={formData.mobileNumber || ""} onChange={handleChange} required />
              </div>
            </div>

            <div className={styles.row}>
              <div>
                <label className={styles.label}>Email Address</label>
                <input type="email" className={styles.profileInput} value={formData.email || ""} name="email" required />
              </div>
            </div>
          </fieldset>

          <fieldset className={styles.profileEditFieldset}>
            {/* Property Information */}
            <legend className={styles.legend}>Property Information</legend>
            <div className={`${styles.row} ${styles.threeColumns}`}>
              <div>
                <label className={styles.label}>Property Address</label>
                <input type="text" className={styles.profileInput} name="address" value={propertyDetail.address || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Town / City</label>
                <input type="text" className={styles.profileInput} name="companyCity" value={propertyDetail.companyCity || ""} onChange={handleChange} required />
              </div>
              <div>
                <label className={styles.label}>Postcode</label>
                <input type="text" className={styles.profileInput} name="postcode" value={propertyDetail.postcode || ""} onChange={handleChange} required />
              </div>
            </div>

            <div className={styles.propertyMediaSection}>
              <h3>Property Images</h3>
              <div className={styles.imageGrid}>
                {propertyImages.map((image, index) => (
                  <div key={index} className={styles.imageContainer}>
                    <img 
                      src={image} 
                      alt={`Property ${index + 1}`} 
                      className={styles.propertyImage} 
                      onClick={() => handleImageClick(index, propertyImages)} 
                    />
                    <button className={styles.deleteButton} onClick={() => handleDeleteImage(index)}>
                      ✖
                    </button>
                  </div>
                ))}
              </div>
              <ImageUpload ref={propertyImageUploadRef} onFilesSelected={handlePhotoUploadComplete} />
            </div>

            <div className={styles.propertyMediaSection}>
              <h3>Floorplans</h3>
              <div className={styles.imageGrid}>
                {propertyFloorplans.map((url, index) => (
                  <div key={index} className={styles.imageContainer}>
                    <img 
                      src={url} 
                      alt={`Floorplan ${index + 1}`} 
                      className={styles.propertyImage} 
                      onClick={() => handleImageClick(index, propertyFloorplans)} 
                    />
                    <button className={styles.deleteButton} onClick={() => handleDeleteFloorplan(index)}>
                      ✖
                    </button>
                  </div>
                ))}
              </div>
              <ImageUpload ref={floorplanImageUploadRef} onFilesSelected={handleFloorplanUploadComplete} />
            </div>
          </fieldset>

          {/* Notification Preferences */}
          <div className={styles.notificationPreferences}>

            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowEmail" name="allowEmail" 
                checked={formData.allowEmail ?? true} onChange={handleChange} />
              <label htmlFor="allowEmail" className={styles.profileEditLabel}>
                Email: <span className={styles.profileEditInfo}>Enable or disable sending email to this user.</span>
              </label>
            </div>

            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowNotifications" name="allowNotifications" 
                checked={formData.allowNotifications ?? true} onChange={handleChange} />
              <label htmlFor="allowNotifications" className={styles.profileEditLabel}>
                Notifications: <span className={styles.profileEditInfo}>Enable or disable notifications for this user.</span>
              </label>
            </div>

            <div className={styles.profileEditCheckboxRow}>

            <input type="checkbox" id="enabled" name="enabled" 
              checked={!!formData.enabled} 
              onChange={(e) => {
                const isEnabled = e.target.checked;
                setFormData((prev) => ({
                  ...prev,
                  enabled: isEnabled,
                  allowEmail: isEnabled ? prev.allowEmail : false,
                  allowNotifications: isEnabled ? prev.allowNotifications : false,
                }));
              }} />
              
              <label htmlFor="enabled" className={styles.profileEditLabel}>
                Account access: <span className={styles.profileEditInfo}>Enable or disable site access for this user.</span>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className={styles.profileEditsubmitContainer}>
            {submitMessage && <div className={styles.submitMessage}>{submitMessage}</div>}
            <button type="submit" className={styles.profileEditButton} disabled={false}>
            Save Changes
            </button>
          </div>
        </form>
    </div>
  );
};

export default LandlordProfileAdminView;
