import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { useUser } from "../context/UserContext";


const ContractorJobCancelled = ({ onRowSelectAction }) => {
  const [cancelledJobs, setCancelledJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const userX = useUser();  


  const columnDefsCancelledJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 1.0 },
    { headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.5,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "CANCELLED DATE", field: "cancelledAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadCancelledJobs = async () => {
      const options = { 
        status: -1,
        contractorEmail: userX?.user?.email, 
       };
      const jobs = await fetchJobs(options);
      setCancelledJobs(jobs);
    };

    loadCancelledJobs();
  }, [userX]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleRowClick = (jobData) => {
    const tabModes =  {
      Detail: 'readOnly',
      Quote: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'readOnly',
      Property: 'hide',
      };

    onRowSelectAction(jobData.data,tabModes,);
  };

  return (
    <GridPanel
      title="Job Cancelled [contractor]" // -1
      columnDefs={columnDefsCancelledJobs}
      rowData={cancelledJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleRowClick}
      isSingleColumn={true} // Full row panel
    />
  );
};

export default ContractorJobCancelled;
