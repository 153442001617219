import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { formatTimestamp } from '../Utilities/dateUtils'; 
import { generateCommentEmailHTMLTemplate, generateCommentEmailTextTemplate } from '../EmailTemplates/emailTemplates';
import { globalConfig } from '../config';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { createNotification } from "../services/dbService";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ImagePopup from '../ImagePopup/ImagePopup';
import { createRoot } from "react-dom/client";
import styles from "../styles/Comments.module.css";

const storage = getStorage();

const ContractorQuoteComments = ({ quoteId, user, jobData, quote, isActive }) => {
  debugger;
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [landlordName, setLandlordName] = useState('Landlord');
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [lastSentMessage, setLastSentMessage] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const userRole = user?.role || 'Unknown';
  const userName = userRole === "Contractor" ? user?.companyName || "Unknown Contractor" : `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || userRole;
  const sender = userRole;

  useEffect(() => {
    if (propertyDetails?.user?.firstName && propertyDetails?.user?.lastName) {
      setLandlordName(`${propertyDetails.user.firstName} ${propertyDetails.user.lastName}`);
    }
  }, [propertyDetails]);

  useEffect(() => { // Listener
    if (!jobData.jobId || !userRole) return;
  
    const commentsRef = doc(db, 'quotes', quoteId, 'chat', 'thread')
  
    const unsubscribe = onSnapshot(commentsRef, (docSnap) => {
      if (docSnap.exists()) {
        const newMessages = docSnap.data().comments || [];

      // Avoid resetting messages if no new messages exist
      setMessages(newMessages.filter(msg => msg.timestamp !== lastSentMessage?.timestamp));
    }

    });
  
    // Cleanup listener when component unmounts
    return () => unsubscribe(); 
  }, [jobData.jobId, userRole, lastSentMessage?.timestamp, quoteId]);

  useEffect(() => { // Clear chat between viewing quotes
    setMessages([]);
    setNewMessage('');
    setUploadedAttachments([]);
    setLastSentMessage(null);
  }, [quoteId]);
    
  useEffect(() => { // Scroll to the end whenever messages change
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => { // Scroll to the end when the tab becomes active
    if (isActive && endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isActive]);

  useEffect(() => { // Fetch Property Details
    const fetchPropertyDetails = async () => {
      const propertyId = jobData?.propertyId;
      if (!propertyId) return;
  
      try {
        const propertyDocRef = doc(db, "properties", propertyId);
        const propertyDoc = await getDoc(propertyDocRef);
    
        if (propertyDoc.exists()) {
          const propertyData = propertyDoc.data();
          setPropertyDetails(propertyData);
        } else {
          console.warn(`Property not found for ID: ${propertyId}`);
        }


      } catch (error) {
        console.error("Failed to fetch property details:", error);
      }
    };
  
    fetchPropertyDetails();
  }, [jobData?.propertyId]);
      
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "30px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };
  
  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;
  
    const uploadPromises = files.map(async (file) => {
      if (file.size > 16 * 1024 * 1024) {
        alert("File too large! Maximum size is 16MB.");
        return null;
      }
  
      const storageRef = ref(storage, `attachments/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    });
  
    const uploadedUrls = (await Promise.all(uploadPromises)).filter(Boolean);
    if (uploadedUrls.length) {
      setUploadedAttachments((prev) => [...prev, ...uploadedUrls]); // Store uploaded attachments until message is sent
    }
    
  };
  
  const handleDeleteAttachment = (index) => {
    setUploadedAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleImageClick = (index, images) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const popupWidth = 1100;
    const popupHeight = 800;
    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;
  
    const newWindow = window.open("", "_blank", `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
    newWindow.document.title = "Image Viewer";
  
    const container = newWindow.document.createElement("div");
    newWindow.document.body.appendChild(container);
  
    const root = createRoot(container);
    root.render(
      <ImagePopup images={images} initialIndex={index} onClose={() => newWindow.close()} />
    );
  };
   
  const handleSendMessage = async () => {
    if (!newMessage.trim() && uploadedAttachments.length === 0) return;

    const messageData = {
      sender: userName || sender, // Now includes `companyName` for Contractors
      senderRole: userRole, // Store role separately for safety
      text: newMessage.trim() || null, 
      attachments: uploadedAttachments, 
      timestamp: serverTimestamp(),
    };
    
    try {
      const jobId = jobData.jobId;
      const commentsRef = doc(db, 'quotes', quoteId, 'chat', 'thread')
      const commentsSnap = await getDoc(commentsRef);

      if (commentsSnap.exists()) {
        // Document exists, update it
        await updateDoc(commentsRef, {
          comments: arrayUnion({
            ...messageData,
            timestamp: new Date(), // Use local timestamp for optimistic UI update
          }),
        });

        setLastSentMessage({
          ...messageData,
          timestamp: new Date(), // Match what was stored
        });
        
      } else {
        // Document does not exist, create it
        await setDoc(commentsRef, {
          jobId,
          comments: [
            {
              ...messageData,
              timestamp: new Date(), // Use local timestamp for optimistic UI update
            },
          ],
        });

        setLastSentMessage({
          ...messageData,
          timestamp: new Date(), // Match what was stored
        });
      }

      // Update local state optimistically and ensure it's formatted properly
      const newTimestamp = { seconds: Math.floor(Date.now() / 1000), nanoseconds: 0 };
      setMessages((prevMessages) => [...prevMessages, { ...messageData, timestamp: newTimestamp }]);
      
      // Clear ready for next message
      setNewMessage(""); 
      setUploadedAttachments([]);
      // setSelectedTarget(null);

      if (textareaRef.current) {
        textareaRef.current.style.height = "30px"; // Reset height to default
      }

      // Make sure the email is only sent if the commenter is NOT the Landlord
      if (userRole !== "Landlord") {
        // Send email
        const htmlBody = generateCommentEmailHTMLTemplate(jobData.jobId, landlordName, messageData.text);
        const plainTextBody = generateCommentEmailTextTemplate(jobData.jobId, landlordName, messageData.text);

        await sendNotificationEmail({
          to: jobData.landlordEmail,
          subject: `${globalConfig.sitename} New Comment - Job ${jobData.jobId}`,
          text: plainTextBody, 
          html: htmlBody, 
        });
      }

      // Create Site  Notifications
      const customMessage = `The ${sender} has made a quoted comment on  ${jobData.jobId}`;
      const notifAddress = `${propertyDetails.address} ${propertyDetails.city} ${propertyDetails.postcode}`; 
      await createNotification(
        jobData.jobId, 
        "new_quoted_comment", 
        {
          admin: true,
          landlord: jobData.landlordEmail,
        },
        notifAddress,
        "Comments",
        customMessage
      );

    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
  
  return (    
    <div className={styles.commentsContainerContractor}>
      <div className={styles.chatSidebarContractor}>
        
        {/* Left hand names list */}
        <div className={styles.chatUserList}>
          {[
            { name: 'CMH Assistance', description: 'Admin', role: 'admin' },
            { name: `${jobData.landlordFirstName} ${jobData.landlordLastName}`, description: 'Landlord', role: 'landlord' },
            ...(quote?.companyName ? [{ name: quote.companyName, description: 'Contractor', role: 'contractor' }] : [])
          ]
            .map((user) => ({ ...user, isDisabled: user.role === userRole.toLowerCase() }))
            .map(({ name, description, role }, index) => (
              <div key={role} className={`${styles.chatUserCardContractor} ${index % 2 === 0 ? styles.chatUserCardEven : styles.chatUserCardOdd}`} >
                <h4>{name}</h4>
                <p className={styles.role}>{description}</p>
              </div>
            ))}
        </div> 
      </div>
  
      {/* Right-hand chat panel */}
      <div className={styles.chatMainContractor}>
          <div className={styles.chatMessagesContractor}>
            {messages.map((message, index) => (
              <div key={index} className={`${styles.chatMessageContractor} ${message.sender?.toLowerCase().trim() === userName?.toLowerCase().trim() ? styles.chatMessageSent : styles.chatMessageReceived}`}>
              <div className={styles.chatBubbleContractor}>
                <p>
                  <strong>{message.sender}</strong><br />
                  {message.text?.split('\n').map((line, i) => (
                    <React.Fragment key={i}> {line}<br /> </React.Fragment>
                  ))}

                  {message.attachments && message.attachments.length > 0 && (
                    <div className={styles.chatAttachments}>
                      {message.attachments.map((url, index) => (
                        url.endsWith(".pdf") ? (
                          <a key={index} href={url} target="_blank" rel="noopener noreferrer">📄 PDF Attachment</a>
                        ) : (
                          <img key={index} src={url} alt="attachment" className={styles.chatThumbnail} onClick={() => handleImageClick(index, message.attachments)} />
                        )
                      ))}
                    </div>
                  )}

                  <span className={styles.chatMessageTimestampContractor}>
                    {message.timestamp ? formatTimestamp(message.timestamp) : ''}
                  </span>
                </p>
              </div>


              </div>
            ))}
            <div ref={endOfMessagesRef} />
          </div>

          {quote?.isRejected ? (
            <div className={styles.chatClosedBanner}>
              This quote has been closed. You can still view past discussion.
            </div>
          ) : (
            <div className={styles.chatInputContainerContractor}>
              <button className={styles.chatAttachButton} onClick={() => fileInputRef.current.click()}>+</button>
              <input type="file" ref={fileInputRef} className={styles.hiddenFileInput} onChange={handleFileSelect} accept="image/*,.pdf" multiple />

              {uploadedAttachments.length > 0 && (
                <div className={styles.chatAttachments}>
                  {uploadedAttachments.map((url, index) => (
                    <div key={index} className={styles.chatThumbnailContainer}>
                      {url.endsWith(".pdf") ? (
                        <a href={url} target="_blank" rel="noopener noreferrer" className={styles.pdfAttachment}>📄 PDF</a>
                      ) : (
                        <img
                          src={url}
                          alt="attachment"
                          className={styles.chatThumbnail}
                          onClick={() => handleImageClick(index, uploadedAttachments)}
                        />
                      )}
                      <button className={styles.deleteAttachment} onClick={() => handleDeleteAttachment(index)}>X</button>
                    </div>
                  ))}
                </div>
              )}

              <textarea
                className={styles.chatInputBoxContractor}
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setNewMessage((prev) => prev + "\n");
                    adjustTextareaHeight();
                  }
                }}
                ref={textareaRef}
              />
              <button
                className={styles.chatSendButton}
                onClick={handleSendMessage}
                disabled={!newMessage.trim() && uploadedAttachments.length === 0}
              >
                Send
              </button>
            </div>
          )}
        </div>

       {/* END commentsContainerContractor below */}
     </div>
  );  
};

export default ContractorQuoteComments;
