import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import GridPanel from '../components/GridPanel';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const StartDateOffered = ({ isSingleColumn, onRowSelectAction }) => {
  const [offeredJobs, setOfferedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const loadOfferedJobs = useCallback(async () => {
    try {
      const jobs = await fetchStartDateOfferedJobs();
      setOfferedJobs(jobs);
    } catch (error) {
      console.error("Error loading offered jobs:", error);
    }
  }, []);

  useEffect(() => {
    const handleRefresh = () => loadOfferedJobs();
    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadOfferedJobs]);

  useEffect(() => {
    loadOfferedJobs();
  }, [loadOfferedJobs]);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Appointments: 'readOnly',
      Quote: 'readOnly',
      Detail: 'readOnly',
      Comments: 'readWrite',
      Property: 'hide',
    };
    onRowSelectAction(jobData, tabModes, "Appointments");
  };

  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 1.0 },
    {
      headerName: "TRADE", field: "tradeName", flex: 1.7, filter: true,
      cellRenderer: TradeCellRenderer,
      valueGetter: (params) =>
        params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName,
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 2 },
    {
      headerName: "DATE OFFERED", field: "startDateOffered", flex: 1.2,
      valueFormatter: (params) => {
        if (!params.value) return "";
        const date = new Date(params.value);
        return isNaN(date) ? "" : date.toLocaleDateString("en-GB");
      }
    }
  ];

  return (
    <GridPanel
      title="Start Date Offered [admin]" // 4
      columnDefs={columnDefs}
      rowData={offeredJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default StartDateOffered;

const fetchStartDateOfferedJobs = async () => {
  const jobsQuery = query(
    collection(db, "jobs"),
    where("status", "==", 4)
  );

  const jobsSnapshot = await getDocs(jobsQuery);
  const results = [];

  for (const jobDoc of jobsSnapshot.docs) {
    const jobData = jobDoc.data();
    const jobId = jobDoc.id;

    const apptQuery = query(
      collection(db, "appointments"),
      where("jobId", "==", jobId)
    );

    const apptSnapshot = await getDocs(apptQuery);

    let earliestOfferedDate = null;

    apptSnapshot.forEach(doc => {
      const apptData = doc.data();
      const offeredDates = (apptData.dates || [])
      .map(entry => {
        const ts = entry?.date;
        if (!ts || typeof ts.toDate !== "function") return null;
        const parsed = ts.toDate();
        return isNaN(parsed.getTime()) ? null : parsed;
      })
      .filter(Boolean);
    
      if (offeredDates.length > 0) {
        const min = offeredDates.reduce((a, b) => (a < b ? a : b));
        if (!earliestOfferedDate || min < earliestOfferedDate) {
          earliestOfferedDate = min;
        }
      }
    });
    
    if (earliestOfferedDate) {
      results.push({ ...jobData, jobId, startDateOffered: earliestOfferedDate });
    }
  }

  return results;
};
