import React, { useEffect } from 'react';
import AgentPropertiesSubmitted from './AgentPropertiesSubmitted';
import AgentJobsAccepted from './AgentJobsAccepted';
import JobsCompleted from './AgentJobsCompleted';
import { db } from '../firebase';
import { doc, onSnapshot, } from 'firebase/firestore';

import '../styles/AgentManageProperties.css';

const AgentManageProperties = ({ agentData, onRowSelectAction }) => {

    useEffect(() => {
      const updatesRef = doc(db, "updates", "admin");
  
      const unsubscribe = onSnapshot(updatesRef, (snapshot) => {
        const data = snapshot.data();
        if (data?.lastUpdated) {
          // console.log("🔄 Agent manage properties detected update:", data.lastUpdated);
          window.dispatchEvent(new Event("refreshGrids"));
        }
      });
  
      return () => unsubscribe(); // Cleanup listener on unmount
    }, []);
  

  const handleRowClick = (jobData, tabModes) => {
    if (jobData.data) {
      onRowSelectAction(jobData.data,tabModes,);
    } else {
      onRowSelectAction(jobData,tabModes,);
    }  
  };
  
  return (
    <div className="manage-properties-container">
      <div className="row">
        <div className="grid-section">
          <AgentPropertiesSubmitted  onRowSelectAction={handleRowClick} />
        </div>
        <div className="grid-section">
          <AgentJobsAccepted onRowSelectAction={handleRowClick} />
        </div>

      </div>
      <div className="row full-width">
        <div className="grid-section">
          <JobsCompleted onRowSelectAction={handleRowClick} />
        </div>
      </div>
    </div>
  );
};

export default AgentManageProperties;
