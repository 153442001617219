import React, { useEffect, useState } from "react";
import { fetchNotifications, fetchUserByEmail, markNotificationAsRead, fetchJobById, deleteNotification } from "../services/dbService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import JobTabs from '../Job/JobTabs';
import { auth, db } from '../firebase';
import { useUser } from "../context/UserContext";
import { updateDoc, doc } from 'firebase/firestore'; 
import styles from "../styles/Notifications.module.css";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [tabModes, setTabModes] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      // console.log("USER UPDATED:", user);
    }
  }, [user]);

  useEffect(() => {
    const loadUser = async () => {
      const userData = await fetchUserByEmail(auth.currentUser.email);
      if (userData.role === 'Landlord') {
        setTabModes({
          Quote: "readOnly",
          Detail: "readWrite",
          Appointments: "readWrite",
          Comments: "readWrite",
          Property: "hide",
        });

      } else if(userData.role === 'Admin') {
        setTabModes({
          Quote: "readOnly",
          Detail: "readWrite",
          Appointments: "hide",
          Comments: "readWrite",
          Property: "hide",
        });
      } else {
        setTabModes({
          Detail: "readWrite",
          Appointments: "hide",
          Quote: "hide",
          Comments: "readWrite",
          Property: "hide",
        });

      }
    };

    loadUser();
  }, []);
  
  const handleToggleReadStatus = async (notificationId, isCurrentlyRead) => {
    await updateDoc(doc(db, "notifications", notificationId), {
      read: !isCurrentlyRead
    });
  
    // Manually update local state to force UI refresh
    setNotifications((prev) =>
      prev.map((notif) =>
        notif.id === notificationId ? { ...notif, read: !isCurrentlyRead } : notif
      )
    );
  };
  
  useEffect(() => {
    const loadNotifications = async () => {
      const data = await fetchNotifications();
      setNotifications(data);

      if (data.length > 0) {
        const firstNotification = data[0]; // Extract the first notification
        if (firstNotification?.displayTab) {
          setSelectedTab(firstNotification.displayTab);
          // console.log("Selected Tab set:", firstNotification.displayTab);
        } else {
          console.warn("displayTab is missing in the first notification:", firstNotification);
        }
      } else {
        console.warn("No notifications found");
      }
    };
    
    loadNotifications();
  }, []);

  const handleNotificationClick = async (notificationId, event) => {
    if (event.event.target.closest("button")) return; // Ignore button clicks properly
  
    try {
      // Find the notification in the already loaded state
      const notification = notifications.find((notif) => notif.id === notificationId);
      if (!notification) throw new Error("Notification not found in state");

      if (user.role === 'Agent') {   
        if (notification.eventType === 'quote_provided') {
          setTabModes({
            Quote: "readOnly",
            Detail: "readOnly",
            Comments: "readOnly",
            Appointments: "hide",
            Property: "hide",
          });
        }

        if (notification.eventType === 'pqw_requested') {
          setTabModes({
            Quote: "readOnly",
            Detail: "readOnly",
            Comments: "readOnly",
            Appointments: "hide",
            Property: "hide",
          });
        }

        if (notification.eventType === "quote_accepted") {
          tabModes.Quote = 'readOnly';
        }
      } 

      if (user.role === 'Admin') {
        if(notification.displayTab === 'Quote') {
          tabModes.Quote = 'readWrite';
        } 

        if (notification.eventType === "appointment_suggested" || notification.eventType === "landlord_accepted_dates") {
          setTabModes({
            Appointments: "readOnly",
            Quote: "readOnly",
            Detail: "readOnly",
            Comments: "readOnly",
            Property: "hide",
          });
        }
      }

      if (user.role === 'Contractor') {
        if (notification.eventType === "landlord_accepted_dates") {
          setTabModes({
            Appointments: "readOnly",
            Quote: "readOnly",
            Detail: "readOnly",
            Comments: "readOnly",
            Property: "hide",
          });
        }
      }

      // Mark notification as read
      await markNotificationAsRead(notificationId);
      setNotifications((prev) =>
        prev.map((notif) => (notif.id === notificationId ? { ...notif, read: true } : notif))
      );
  

      // Set tab display screen type based on `displayTab` from the notification
      setSelectedTab(notification.displayTab || "Detail");
  
      // Fetch job details and set JobTabs
      const jobData = await fetchJobById(event.data.jobId);
      setSelectedJob(jobData);
  
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };
  
  const gridOptions = {
    suppressRowClickSelection: true, // Prevent row selection from clicking inside cells
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      await deleteNotification(notificationId); // 🔥 Call Firestore delete function
      setNotifications((prev) => prev.filter((n) => n.id !== notificationId)); // ✅ Remove from UI
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };
  

  const columns = [
    { headerName: "JOB ID", field: "jobId", filter: true, flex: 0.4},
    { headerName: "ADDRESS", field: "address", filter: true, flex: 1.2},
    { headerName: "MESSAGE", field: "message", filter: true, flex: 2.0 },
    { headerName: "NOTIFICATION TIME", field: "createdAt", flex: 0.6},
    { headerName: "STATUS", field: "read", flex: 0.5,
      suppressClickEdit: true,
      cellRenderer: ({ value, data }) => {
        return (
          <button
            className={value ? styles.markAsUnreadButton : styles.markAsReadButton}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleReadStatus(data.id, value);
            }}
          >
            {value ? "Mark as Unread" : "Mark as Read"}
          </button>
        );        
      },
    },
    { 
      headerName: "DELETE", 
      field: "", 
      flex: 0.4,
      suppressClickEdit: true,
      cellRenderer: ({ data }) => (
        <button
          className={styles.deleteButton}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteNotification(data.id);
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  useEffect(() => {
    // console.log("Updated selectedTab:", selectedTab);
  }, [selectedTab]);

  const handleNotifcationMenuClick = () => {
    setSelectedJob(null); // Reset selectedJob to show top-level Dashboard
  };

  return (
    <div>
        {selectedJob ? (
          // TODO: FIX THE INTEGRITY OF THE PROPS (WITHIN JOB TABS ALSO)
          <JobTabs jobData={selectedJob} onReturnToDashboard={null} tab={selectedTab} tabModes={tabModes} onReturnToNotification={handleNotifcationMenuClick}/>
      ) : (
        <>
          <div className="ag-theme-alpine" style={{ height: "85vh", width: "100%" }}>
            <AgGridReact
              rowData={notifications}
              columnDefs={columns}
              rowSelection="single"
              gridOptions={gridOptions}
              suppressCellSelection={true} 
              onRowClicked={(event) => handleNotificationClick(event.data.id, event)}
              pagination={true} 
              paginationPageSize={20}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;
