// src/Settings/SettingsPreQuotedWorks.js

import React, { useState, useEffect } from 'react';
import {
    fetchPreQuotedWorks,
    addPreQuotedWork,
    updatePreQuotedWork,
    deletePreQuotedWork,
    fetchTrades,
} from '../services/preQuotedWorksService';
import FlashMessage from '../components/UI/FlashMessage';
import '../styles/SettingsPreQuotedWorks.css';

const SettingsPreQuotedWorks = () => {
    const [preQuotedWorks, setPreQuotedWorks] = useState([]);
    const [trades, setTrades] = useState([]); // State to store trades
    const [newPqw, setNewPqw] = useState({ title: '', trade: '', jobDetail: '', price: '', vatAmount: '', enabled: true });
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [flashMessage, setFlashMessage] = useState('');
    
    useEffect(() => {
        // Load Pre Quoted Works and Trades on component mount
        const loadData = async () => {
            const pqwList = await fetchPreQuotedWorks();
            const tradesList = await fetchTrades(); // Fetch trades
            setPreQuotedWorks(pqwList);
            setTrades(tradesList); // Store trades in state
        };
        loadData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPqw({ ...newPqw, [name]: value });
    };

    const handleAdd = async () => {
      const addedPqw = await addPreQuotedWork({
          ...newPqw,
          vatAmount: newPqw.vatAmount, // Store VAT
          total: newPqw.total, // Store total
      });
  
      setPreQuotedWorks([...preQuotedWorks, addedPqw]);
      setNewPqw({ title: '', trade: '', jobDetail: '', price: '', vatAmount: '', total: '', enabled: true });
      setFlashMessage('Pre Quoted Work added successfully');
      setTimeout(() => setFlashMessage(''), 3000);
  };
  
    const handleEdit = (pqw) => {
        handleVatChange(pqw.price); 
        setNewPqw(pqw);
        setEditId(pqw.id);
        setIsEditing(true);
    };

    const handleUpdate = async () => {
      const updatedPqw = await updatePreQuotedWork(editId, {
          ...newPqw,
          vatAmount: newPqw.vatAmount, // Store VAT
          total: newPqw.total, // Store total
      });
  
      setPreQuotedWorks(preQuotedWorks.map(pqw => (pqw.id === editId ? updatedPqw : pqw)));
      setIsEditing(false);
      setNewPqw({ title: '', trade: '', jobDetail: '', price: '', vatAmount: '', total: '', enabled: true });
      setEditId(null);
      setFlashMessage('Pre Quoted Work updated successfully');
      setTimeout(() => setFlashMessage(''), 3000);
  };
  
    const handleDelete = async (id) => {
        await deletePreQuotedWork(id);
        setPreQuotedWorks(preQuotedWorks.filter(pqw => pqw.id !== id));
        setFlashMessage('Pre Quoted Work deleted successfully');
        setTimeout(() => setFlashMessage(''), 3000);
    };

    const handleVatChange = (price) => {
      const netValue = parseFloat(price) || 0;
      const vatAmount = (netValue * 0.2).toFixed(2); // 20% VAT
      const total = (netValue + parseFloat(vatAmount)).toFixed(2);
  
      setNewPqw(prevState => ({
          ...prevState,
          price: netValue, 
          vatAmount, 
          total
      }));
  };
  
    return (
        <div className="settings-pre-quoted-works">
            <h2>Manage Pre Quoted Works</h2>
            <div className="feedback-line">
                {flashMessage && <FlashMessage message={flashMessage} />}
            </div>

            <form className="pqw-form" onSubmit={isEditing ? handleUpdate : handleAdd}>
              <div className="pqw-input-group">
                <label>
                    Work Title:
                    <input
                        type="text"
                        name="title"
                        value={newPqw.title}
                        onChange={handleInputChange}
                        placeholder="Enter the title of the work"
                    />
                </label>
                <label>
                    <select
                        name="trade"
                        value={newPqw.trade}
                        onChange={handleInputChange}
                    >
                        <option value="">Select a trade</option>
                        {trades.map((trade) => (
                            <option key={trade.id} value={trade.tradename}>
                                {trade.tradename}
                            </option>
                        ))}
                    </select>
                </label>
              </div>

              <div className="pqw-textarea-group">
                <label>
                    Job Detail:
                    <textarea
                        name="jobDetail"
                        value={newPqw.jobDetail}
                        onChange={handleInputChange}
                        placeholder="Provide detailed information about the job"
                        rows="4"
                    />
                </label>
              </div>

              <label>
                Price (£):
                <input
                    type="number"
                    name="price"
                    value={newPqw.price}
                    onChange={(e) => handleVatChange(e.target.value)}
                    placeholder="Enter the price of the work"
                    step="0.01" // Allows decimal values for price
                />
              </label>              

              <label>
                VAT (20%):
                <input
                    type="text"
                    name="vatAmount"
                    value={newPqw.vatAmount}
                    readOnly
                />
              </label>

              <label>
                Total (£):
                <input
                    type="text"
                    name="total"
                    value={newPqw.total}
                    readOnly
                />
              </label>

              <div className="pqw-button-group">
                <button type="button" onClick={isEditing ? handleUpdate : handleAdd}>
                    {isEditing ? 'Update' : 'Add'}
                </button>
              </div>
            </form>

            <ul className="pqw-list">
                {preQuotedWorks.map((pqw) => (
                    <li key={pqw.id}>
                        <span>
                          {pqw.title} - {pqw.trade} - {pqw.jobDetail} - 
                          £{parseFloat(pqw.price).toFixed(2)} + VAT £{parseFloat(pqw.vatAmount).toFixed(2)} = 
                          £{parseFloat(pqw.total).toFixed(2)}
                      </span>
                        <button onClick={() => handleEdit(pqw)}>Edit</button>
                        <button onClick={() => handleDelete(pqw.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SettingsPreQuotedWorks;
