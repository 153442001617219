import React, { useState, useEffect } from 'react';
import { doc, updateDoc, serverTimestamp, getDoc, runTransaction, collection, } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuthContext } from '../context/AuthContext';
import { fetchQuote, createQuote, fetchContractorQuoteById } from '../services/quoteService';
import { createNotification } from "../services/dbService";
import { SystemCommenter } from '../Shared/SystemCommenter';
import { generateQuoteEmailHTMLTemplate, generateQuoteEmailTextTemplate } from '../EmailTemplates/emailTemplates';
import { globalConfig } from '../config';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { createRoot } from "react-dom/client";
import { useUser } from "../context/UserContext";
import CostBreakdownTable from './CostBreakdownTable';
import ImagePopup from '../ImagePopup/ImagePopup';
import ContractorQuoteComments from '../Comments/ContractorQuoteComments';
import styles from '../styles/QuoteForm.module.css'; // Use CSS modules

const QuoteForm = ({ jobData, onClose, quoteId, readOnly = false, setIsActiveQuoteRejected, quoteNumber }) => {
  const [quoteDetails, setQuoteDetails] = useState('');
  const [quote, setQuote] = useState(null);
  const [remainingChars, setRemainingChars] = useState(4000);
  const [costRows, setCostRows] = useState([{ id: 1, type: 'Labour', quantity: '', unitPrice: '', vatAmount: '', net: 0, total: 0 }]);
  const [leadTime, setLeadTime] = useState('');
  const [duration, setDuration] = useState('');
  const [quoteExpiryDate, setQuoteExpiryDate] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('');
  const [quoteSubmitted, setQuoteSubmitted] = useState(false);
  const [totalQuoteAmount, setTotalQuoteAmount] = useState(0);
  const { user } = useAuthContext();
  const userX = useUser();  
  const [submitting, setSubmitting] = useState(false);
  const [isQuoteRejected, setIsQuoteRejected] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const removeUndefinedFields = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));
  

  useEffect(() => { // Load Quote information
    const loadData = async () => {
      if (!jobData || !userX?.user?.role) return;
  
      try {
        // const quoteData = await fetchQuote(jobData.jobId);
        let quoteData;

        if (quoteId) {
          quoteData = await fetchContractorQuoteById(quoteId);
        } else {
          quoteData = await fetchQuote(jobData.jobId);
        }
  
        if (userX.user.role === "Admin") {
          setQuoteSubmitted(!!quoteData);
        } else if (userX.user.role === "Contractor") {
          setQuoteSubmitted(prev => prev || quoteData?.submittedBy === userX.user.email);
        }

        if (quoteData) {
          setQuote(quoteData);
          setQuoteDetails(quoteData.quoteDetails);
          setIsQuoteRejected(quoteData.isRejected);

          if (setIsActiveQuoteRejected) {
            setIsActiveQuoteRejected(quoteData.isRejected || false);
          }

          setLeadTime(quoteData.leadTime);
          setDuration(quoteData.duration);
          setQuoteExpiryDate(
            quoteData.quoteExpiryDate
              ? new Date(quoteData.quoteExpiryDate).toISOString().split('T')[0]
              : null
          );
          // setCostRows(quoteData.costBreakdown || []);

          setCostRows(prevRows => {
            return quoteData.costBreakdown?.length > 0
              ? quoteData.costBreakdown
              : prevRows.length > 0
                ? prevRows
                : [{ id: Date.now(), type: 'Labour', description: '', quantity: '', unitPrice: '', vatAmount: '', net: 0, total: 0 }];
          });

        }
      } catch (error) {
        console.error('Error fetching quote details:', error);
      }
    };
    loadData();
  }, [jobData, userX, quoteId, setIsActiveQuoteRejected]);
  
  const handleQuoteDetailsChange = (e) => {
    if (!readOnly) {
      const text = e.target.value;
      setQuoteDetails(text);
      setRemainingChars(4000 - text.length);
    }
  };

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  const addCostRow = () => {
    if (!readOnly) {
      setCostRows([...costRows, { id: Date.now(), type: 'Labour', quantity: '', unitPrice: '', vatAmount: '', net: 0, total: 0 }]);
    }
  };

  const removeCostRow = (id) => {
    if (!readOnly) {
      setCostRows(costRows.filter((row) => row.id !== id));
    }
  };

  const handleRowChange = (id, field, value) => {
    if (!readOnly) {
      setCostRows((prevRows) => {
        const updatedRows = prevRows.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row, [field]: value };
            const net = parseFloat(updatedRow.net) || 0;
            const vatAmount = parseFloat(updatedRow.vatAmount) || 0;
            updatedRow.total = net + vatAmount;
            return updatedRow;
          }
          return row;
        });

        const newTotalAmount = updatedRows.reduce((sum, row) => sum + (parseFloat(row.total) || 0), 0);
        setTotalQuoteAmount(newTotalAmount);

        return updatedRows;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!readOnly) {
      const currentTime = serverTimestamp();
      const quoteData = {
        jobId: jobData.jobId,
        landlordEmail: jobData.landlordEmail,
        trade: jobData.tradeName,
        instructionNotes: jobData.instructionNotes,
        furtherDetails: jobData.furtherDetails,
        leadTime,
        duration,
        quoteExpiryDate: quoteExpiryDate ? new Date(quoteExpiryDate).getTime() : null,
        quoteDetails,
        costBreakdown: costRows,
        createdAt: currentTime,
        submittedBy: userX.user.email,
      };

      try {
        await createQuote(jobData.jobId, quoteData, 1);

        const jobRef = doc(db, 'jobs', jobData.jobId);
        await updateDoc(jobRef, {
          status: 1,
          quoteAmount: totalQuoteAmount,
          quoteTime: currentTime,
        });

        await SystemCommenter(jobData.jobId, 'Admin', 'A quote has been provided and sent for the job.');

        const plainTextBody = generateQuoteEmailTextTemplate(jobData.jobId, jobData.landlordFirstName);
        const htmlBody = generateQuoteEmailHTMLTemplate(jobData.jobId, jobData.landlordFirstName);

        await sendNotificationEmail({
          to: jobData.landlordEmail,
          subject: `${globalConfig.sitename} quote - Job ${jobData.jobId}`,
          text: plainTextBody,
          html: htmlBody,
        });

        // Fetch property details
        const propertyDetails = await fetchPropertyDetails(jobData.propertyId);
        const propertyAddress = propertyDetails.address + ", " + propertyDetails.city + ", " + propertyDetails.postcode;

        // Create Site Notifications
        const customMessage = `Admin has provide a quote for job ${jobData.jobId}`;
        await createNotification(
          jobData.jobId, 
          "quote_provided", {
            admin: true,
            agent: jobData.agentId, 
            landlord: jobData.landlordEmail,
          },
          propertyAddress, 
          "Quote",
          customMessage,
        );

        setSubmitMessage('Quote submitted successfully!');
        setQuoteSubmitted(true); // Optimistically update state
        await new Promise(resolve => setTimeout(resolve, 500)); // Allow Firestore to update
                setTimeout(() => {
          setSubmitMessage('');
          // onClose();
        }, 3000);
      } catch (error) {
        console.error('Error submitting quote:', error);
        setSubmitMessage('Error submitting quote. Please try again.');
      }
    }
  };

  const handleContractorSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (submitting) return; // guard against double click
    setSubmitting(true);

    if (!readOnly) {
      const currentTime = serverTimestamp();
      const jobId = jobData.jobId; // Extract job ID early
      const submittedBy = userX.user.email;
      const contractorId = userX.user.email;
      const companyName = userX.user.companyName;
  
      const quoteData = {
        jobId,
        landlordEmail: jobData.landlordEmail,
        trade: jobData.tradeName,
        instructionNotes: jobData.instructionNotes,
        furtherDetails: jobData.furtherDetails,
        leadTime,
        duration,
        quoteExpiryDate: quoteExpiryDate ? new Date(quoteExpiryDate).getTime() : null,
        quoteDetails,
        costBreakdown: costRows,
        createdAt: currentTime,
        submittedBy,
        contractorId, // Store contractor's email instead of UID
        companyName, // Store contractor's company name
      };
  
      console.log(`Submitting contractor quote for job: ${jobId} by contractor: ${user.email}`);
  
      const quotesRef = collection(db, "quotes");
      const newQuoteRef = doc(quotesRef); // Generates a unique ID
      const signpostRef = doc(quotesRef, jobId); // Signpost doc named after jobId
      
      try {
        await runTransaction(db, async (transaction) => {
          console.log("Transaction started: Creating signpost and quote");
        
          // Step 1: Read the signpost document FIRST
          const signpostSnapshot = await transaction.get(signpostRef);
          let contractorQuoteIds = [];
        
          if (signpostSnapshot.exists()) {
            const signpostData = signpostSnapshot.data();
            contractorQuoteIds = signpostData.contractorQuoteIds || [];
          }

          // Step 2: Only proceed if fewer than 3 quotes exist
          if (contractorQuoteIds.length <= 2) {
        
            // Step 3: Add new quote ID
            contractorQuoteIds.push(newQuoteRef.id);
        
            // Step 4: Write the contractor's quote
            console.log("About to write to Firestore:", newQuoteRef.id, quoteData);
            transaction.set(newQuoteRef, removeUndefinedFields(quoteData));
            console.log("Transaction set() executed");
        
            // Step 5: Write the updated signpost document
            transaction.set(signpostRef, {
              jobId,
              type: "contractor_quote_signpost",
              createdAt: serverTimestamp(),
              contractorQuoteIds,
            });
                    
            // Step 6: If this is the third quote, update job status
            if (contractorQuoteIds.length === 3) { 
              const jobRef = doc(db, "jobs", jobId);
              transaction.update(jobRef, { status: 1 }); // Move job to "Waiting on Landlord Acceptance status 1"
              console.log(`Job ${jobId} status updated to 1 (Waiting on Landlord Acceptance)`);
            }          
          }
          
          // Step 6: Success message for real submissions
          setSubmitMessage("Quote submitted successfully!");
          setQuoteSubmitted(true); // Optimistically update state
          await new Promise(resolve => setTimeout(resolve, 500)); // Allow Firestore to update
            setTimeout(() => {
            setSubmitMessage("");
            onClose();
          }, 3000);
        });
        
      } catch (error) {
        console.error("Error submitting contractor quote:", error);
        setSubmitMessage("Error submitting quote. " + error);
        setSubmitting(false); // allow retry on failure
      }
    }
  };
          
  const handleImageClick = (index, images) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const popupWidth = 1100;
    const popupHeight = 800;
    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;
  
    const newWindow = window.open("", "_blank", `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
    newWindow.document.title = "Image Viewer";
  
    const container = newWindow.document.createElement("div");
    newWindow.document.body.appendChild(container);
  
    const root = createRoot(container);
    root.render(
      <ImagePopup images={images} initialIndex={index} onClose={() => newWindow.close()} />
    );
  };
 
  return (
    <form className={styles.quoteForm} onSubmit={user?.role === 'Admin' ? handleSubmit : handleContractorSubmit}>
      <h2>{jobData.jobId}: Work Required</h2>

      {(user?.role === 'Landlord' || user?.role === 'Admin') && quoteId && (
      <>
        <div className={styles.contractorDetailsRow}>
          <span className={styles.contractorLabel}>Quote supplied by:</span>
          <span className={styles.contractorName}>{quote?.companyName || 'Unknown Contractor'}</span>
        </div>

        {isQuoteRejected && (
        <div className={styles.rejectedQuoteRow}>
          <span className={styles.rejectedMessage}>This quote has been closed</span>
        </div>
        )}  

      </>
      )}

      {/* Job Images Section */}
      {jobData?.photos?.length > 0 && (
        <div className={styles.imageSection}>
          <h3>Job Images</h3>
          <div className={styles.imageGrid}>
            {jobData.photos.map((photo, index) => (
              <div key={index} className={styles.imageContainer} onClick={() => handleImageClick(index, jobData.photos)}>
                <img src={photo} alt={`Job example ${index + 1}`} className={styles.jobImage} />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label>Lead time required</label>
          <input type="text" value={leadTime} onChange={(e) => setLeadTime(e.target.value)} disabled={readOnly} />
        </div>
        <div className={styles.formGroup}>
          <label>Est. duration</label>
          <input type="text" value={duration} onChange={(e) => setDuration(e.target.value)} disabled={readOnly} />
        </div>
        <div className={styles.formGroup}>
          <label>Date quote expires</label>
          <input type="date" value={quoteExpiryDate || ''} onChange={(e) => setQuoteExpiryDate(e.target.value)} disabled={readOnly} />
        </div>
      </div>

      <div className={styles.textareaGroup}>
        <label>Quote Detail</label>
        <textarea className={styles.textarea} rows="4" value={quoteDetails} onChange={handleQuoteDetailsChange} disabled={readOnly} />

        {!readOnly && (
          <div className={styles.textareaInfo}>
            <small>{remainingChars} characters remaining</small>
          </div>
        )}

      </div>

      {quoteId && (
        <div className={styles.chatTogglePanel}>
          <button onClick={() => setShowChat(prev => !prev)} className={styles.toggleChatButton}>
            {user?.role === "Contractor"
              ? showChat ? "Hide conversation" : "Show conversation"
              : showChat ? `Hide conversation for Quote ${quoteNumber}` : `Show conversation for Quote ${quoteNumber}`}
          </button>

          {showChat && (
            <div className={styles.quoteChatPanelContractor}>
              <ContractorQuoteComments quoteId={quoteId} user={userX.user} jobData={jobData} quote={quote} />
            </div>
          )}
        </div>
      )}

      <CostBreakdownTable
        costRows={costRows}
        onRowChange={handleRowChange}
        onAddRow={addCostRow}
        onRemoveRow={removeCostRow}
        readOnly={readOnly}
      />

      {submitMessage && <div className={styles.submitMessage}>{submitMessage}</div>}

      {!readOnly && !quoteSubmitted && 
        ((user?.role === 'Admin') || (user?.role === 'Contractor')) && (
          <div className={styles.submitBtn}>
            <button type="submit" disabled={readOnly || submitting}>
              {submitting ? "Submitting..." : "Submit Quote"}
            </button>
          </div>
      )}
    </form>
  );
};

export default QuoteForm;
