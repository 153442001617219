import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchInProgressJobs } from '../services/dbService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { formatDateOnly } from '../Utilities/dateUtils';
import { useUser } from "../context/UserContext";

const LandlordInProgress = ({ onRowSelectAction }) => {

  const userX = useUser();  
  const [inProgressJobs, setInProgressJobs] = useState([]);
  const loadInProgressJobs = useCallback(async () => {
    const options = { 
      landlordEmail: userX.user.email,
    };
    const jobs = await fetchInProgressJobs(options);
    setInProgressJobs(jobs);
    }, [userX.user.email]);

    useEffect(() => {
      const handleRefresh = () => {
        loadInProgressJobs(); 
      };
  
        window.addEventListener("refreshGrids", handleRefresh);
        return () => window.removeEventListener("refreshGrids", handleRefresh);
    }, [loadInProgressJobs]); 
  
    useEffect(() => {
      loadInProgressJobs(); 
    }, [loadInProgressJobs]);
  
    const columnDefs = [
      { headerName: "JOB ID", field: "jobId", flex: 0.7 },
      { headerName: "TRADE", 
        field: "tradeName",
        flex: 1.2,
        filter: true,
        cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
        valueGetter: (params) => {
          return params.data.tradeName === 'Other (please specify)' 
            ? params.data.instructionNotes || 'N/A' 
            : params.data.tradeName;
        }
      },
      { headerName: "DESCRIPTION", field: "jobDetail", flex: 1.2 },
      { headerName: "START DATE", field: "createdAt", flex: 0.8,
        valueFormatter: (params) => formatDateOnly(params.value) },
      { headerName: "STATUS", field: "status", flex: 1.0, valueGetter: () => "In Progress" }   
    ];

    const handleRowClick = (jobData) => {
      const tabModes = {
        Quote: 'readOnly',
        Detail: 'readOnly',
        Comments: 'readWrite',
        Appointments: 'readOnly',
        Property: 'hide',
      };
    
      if (jobData.data) {
        onRowSelectAction(jobData.data,tabModes,);
      } else {
        onRowSelectAction(jobData,tabModes,);
      }

    };

    return (
      <GridPanel
        title="In Progress [landlord]" // 5 (time controlled)
        rowData={inProgressJobs}
        columnDefs={columnDefs}
        onRowClick={handleRowClick}
      />
    );
};

export default LandlordInProgress;