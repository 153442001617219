import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import GridPanel from '../components/GridPanel';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { useUser } from "../context/UserContext";

const ContractorJobInProgress = ({ isSingleColumn, onRowSelectAction }) => {
  const [inProgressJobs, setInProgressJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const userX = useUser();  
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const columnDefsInProgressJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 1.0 },
    { 
      headerName: "TRADE", field: "tradeName", flex: 1.7, filter: true,
      cellRenderer: TradeCellRenderer,
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { 
      headerName: "IN PROGRESS SINCE", field: "startDate", flex: 1.5,
      valueFormatter: (params) => {
        if (!params.value) return "";
        const date = new Date(params.value);
        return isNaN(date) ? "" : date.toLocaleDateString("en-GB");
      }
    }
  ];

  const loadInProgressJobs = useCallback(async () => {
    try {
      const contractorEmail = userX?.user?.email;
      const jobs = await fetchInProgressJobsForContractor(contractorEmail);
      setInProgressJobs(jobs);  
    } catch (error) {
      console.error("Error fetching in-progress jobs: ", error)
    }
  }, [userX]);

  useEffect(() => {
    const handleRefresh = () => loadInProgressJobs();
    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadInProgressJobs]); 

  useEffect(() => {
    loadInProgressJobs(); 
  }, [loadInProgressJobs]);

  const handleRowClick = (event) => {
    const jobData = event.data;
    const tabModes =  {
      Detail: 'readOnly',
      Quote: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'readOnly',
      Property: 'hide',
    };
    onRowSelectAction(jobData, tabModes);
  };

  return (
    <GridPanel
      title="Job In Progress [contractor]" // 5
      columnDefs={columnDefsInProgressJobs}
      rowData={inProgressJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default ContractorJobInProgress;


const fetchInProgressJobsForContractor = async (contractorEmail) => {
  if (!contractorEmail) return [];

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const jobsQuery = query(
    collection(db, "jobs"),
    where("status", "==", 5),
    where("contractorEmail", "==", contractorEmail)
  );

  const jobsSnapshot = await getDocs(jobsQuery);
  const results = [];

  for (const jobDoc of jobsSnapshot.docs) {
    const jobData = jobDoc.data();
    const jobId = jobDoc.id;

    const apptQuery = query(
      collection(db, "appointments"),
      where("jobId", "==", jobId)
    );
    const apptSnapshot = await getDocs(apptQuery);

    let earliestStartDate = null;

    apptSnapshot.forEach(doc => {
      const data = doc.data();
      const confirmedDates = (data.landlordConfirmedDates || [])
        .map(entry => {
          const raw = entry?.date;
          const parsed = raw ? new Date(raw.includes("T") ? raw : `${raw}T00:00:00`) : null;
          return parsed && !isNaN(parsed.getTime()) ? parsed : null;
        })
        .filter(Boolean);

      if (confirmedDates.length > 0) {
        const min = confirmedDates.reduce((a, b) => a < b ? a : b);
        if (!earliestStartDate || min < earliestStartDate) {
          earliestStartDate = min;
        }
      }
    });

    if (earliestStartDate && earliestStartDate <= today) {
      results.push({ ...jobData, jobId, startDate: earliestStartDate });
    }
  }

  return results;
};

