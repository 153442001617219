import React, { useState } from "react";

const ImagePopup = ({ images, initialIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  return (
    <div style={{ textAlign: "center", backgroundColor: "#fff", height: "100vh", color: "#fff" }}>
      <button 
        style={{ position: "absolute", top: "50%", left: "20px", fontSize: "24px" }}
        onClick={() => setCurrentIndex((prev) => Math.max(prev - 1, 0))}
        disabled={currentIndex === 0}
      >
        ←
      </button>

      <img 
        src={images[currentIndex]} 
        alt="Property" 
        style={{ maxWidth: "90vw", maxHeight: "90vh", marginTop: "5vh" }} 
      />

      <button 
        style={{ position: "absolute", top: "50%", right: "20px", fontSize: "24px" }}
        onClick={() => setCurrentIndex((prev) => Math.min(prev + 1, images.length - 1))}
        disabled={currentIndex === images.length - 1}
      >
        →
      </button>

      <button 
        style={{ position: "absolute", top: "10px", right: "10px", fontSize: "18px", backgroundColor: "red", color: "white" }}
        onClick={onClose}
      >
        ✖ Close
      </button>
    </div>
  );
};

export default ImagePopup;
