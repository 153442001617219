import { db } from '../firebase';
import { doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';

/**
 * Add a system comment to the comments document for a given job ID.
 * @param {string} jobId - The job ID associated with the comments.
 * @param {string} sender - The sender of the comment (e.g., "Admin").
 * @param {string} text - The text of the comment.
 * @param {string} [recipient] - Optional recipient of the comment.
 * @param {string} [landlordName] - Optional landlord's name.
 * @param {string} [agentName] - Optional agent's name.

*Basic Usage
await addSystemComment(jobData.jobId, 'Admin', 'A quote has been provided and sent for this job.');


*With Recipient
await addSystemComment(
  jobData.jobId,
  'Admin',
  'This job requires your attention.',
  'Landlord'
);

*With Landlord and Agent Names
await addSystemComment(
  jobData.jobId,
  'Admin',
  'This is a system-generated comment.',
  'Landlord',
  'John Doe',
  'Jane Smith'
);
 */

export const SystemCommenter = async (jobId, sender, text, recipient = null, landlordName = null, agentName = null) => {
  if (!jobId || !sender || !text) {
    console.error('addSystemComment: Missing required parameters.');
    return;
  }

  const commentsRef = doc(db, 'comments', jobId);
  const timestamp = new Date(); // Use JS Date for consistent timestamp object

  try {
    const commentsDoc = await getDoc(commentsRef);

    // Build the comment object dynamically
    const comment = {
      sender,
      text,
      timestamp,
      ...(recipient && { recipient }),
      ...(landlordName && { landlordName }),
      ...(agentName && { agentName }),
    };

    if (commentsDoc.exists()) {
      // Add the comment to the existing document
      await updateDoc(commentsRef, {
        comments: arrayUnion(comment),
      });
    } else {
      // Create a new comments document if it doesn't exist
      await setDoc(commentsRef, {
        comments: [comment],
      });
    }

    // console.log('addSystemComment: Comment added successfully.');
  } catch (error) {
    console.error('addSystemComment: Error adding comment:', error);
  }
};
