import React, { useEffect, } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/LandlordDashboard.css';
import AwaitingPriceGrid from '../LandlordDashboard/AwaitingPriceGrid';
import AwaitingApproval from '../LandlordDashboard/AwaitingApproval';
import LandlordAwaitingContractorAllocation from './LandlordAwaitingContractorAllocation';
import LandlordInProgress from './LandlordInProgress';
import LandlordReadyForSignOff from './LandlordReadyForSignOff';
import LandlordCompleted from './LandlordCompleted';
import LandlordContractorAllocated from './LandlordContractorAllocated';
import { db } from '../firebase';
import { doc, onSnapshot, } from 'firebase/firestore';

const LandlordManageProperties = React.forwardRef(({ onRowSelectAction, propertyId }, ref) => {

  useEffect(() => {
    const updatesRef = doc(db, "updates", "admin");

    const unsubscribe = onSnapshot(updatesRef, (snapshot) => {
      const data = snapshot.data();
      if (data?.lastUpdated) {
        // console.log("🔄 Landlord dashboard detected update:", data.lastUpdated);
        window.dispatchEvent(new Event("refreshGrids"));
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const handleJobRowClick = (jobData, tabModes) => {
    if (jobData.data) {
      onRowSelectAction(jobData.data, tabModes);
    } else {
      onRowSelectAction(jobData, tabModes);
    }
  };

  return (
    <div className="manage-properties-container">
      <div className="row">
        <div className="grid-section">
          <AwaitingPriceGrid onRowSelectAction={handleJobRowClick} propertyId={propertyId} />
        </div>

        <div className="grid-section">
          <AwaitingApproval onRowSelectAction={handleJobRowClick} propertyId={propertyId} />
        </div>
      </div>

      <div className="row">
        <div className="grid-section">
          <LandlordAwaitingContractorAllocation onRowSelectAction={handleJobRowClick} isContractor={false} propertyId={propertyId} />
        </div>

        <div className="grid-section">
          <LandlordContractorAllocated onRowSelectAction={handleJobRowClick} propertyId={propertyId} />
        </div>
      </div>

      <div className="row">
        <LandlordInProgress onRowSelectAction={handleJobRowClick} />
        <br />
        <LandlordReadyForSignOff onRowSelectAction={handleJobRowClick} />
      </div>

      <div className="row">
        <LandlordCompleted onRowSelectAction={handleJobRowClick} />
      </div>

    </div>
  );
});

export default LandlordManageProperties;
