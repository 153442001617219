import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../styles/AvailabilityCalendar.css";

const AvailabilityCalendar = ({ onAvailabilityChange }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const today = new Date();
    const defaultAvailableDays = [];
  
    // Generate default available weekdays (green)
    for (let i = 1; i <= 30; i++) { // Start from tomorrow
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        defaultAvailableDays.push({
          title: "Not Busy",
          start: date.toISOString().split("T")[0],
          color: "green",
          interactive: false, // Prevents clicking on 'Available' days
        });
      }
    }
  
    setEvents(defaultAvailableDays); // Keep initial available days
  }, []);

  const handleDateClick = (info) => {
    const today = new Date().toISOString().split("T")[0];
    if (info.dateStr <= today) {
      return; // Do nothing if the date is today or earlier
    }
  
    setEvents((prevEvents) => {
      const existingEvent = prevEvents.find((event) => event.start === info.dateStr);
  
      let updatedEvents;
      if (existingEvent) {
        if (existingEvent.title === "Busy") {
          // Remove if it's already "Busy" (i.e., toggle it off)
          updatedEvents = prevEvents.filter((event) => event.start !== info.dateStr);
        } else {
          // Change "Available" to "Busy"
          updatedEvents = prevEvents.map((event) =>
            event.start === info.dateStr
              ? { ...event, title: "Busy", color: "red" }
              : event
          );
        }
      } else {
        // If no event exists, mark it as "Busy"
        updatedEvents = [...prevEvents, { title: "Busy", start: info.dateStr, color: "red" }];
      }
  
      // ✅ Only pass "Busy" dates to Firestore
      onAvailabilityChange(updatedEvents.filter(event => event.title === "Busy").map(event => event.start));
      return updatedEvents;
    });
  };  

  return (
    <div className="availability-calendar">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        selectable={true}
        dateClick={handleDateClick}
        events={events}
        height="100%" /* Ensures it takes full height */
        contentHeight="auto" /* Auto adjusts height */
        aspectRatio={10.0}
        firstDay={1}
      />
    </div>
  );
};

export default AvailabilityCalendar;
