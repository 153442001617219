import React, { useState } from "react";
import { updatePassword } from "firebase/auth";
import { auth } from "../firebase";
import styles from "../styles/ProfileEdit.module.css";
import { Eye, EyeOff } from "lucide-react"; // Icons for show/hide password

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  
  const handlePasswordChange = async () => {
    setPasswordMessage(""); // Clear previous messages

    if (newPassword !== confirmPassword) {
      setPasswordMessage("Passwords do not match.");
      setTimeout(() => setPasswordMessage(""), 3000);
      return;
    }

    try {
      const user = auth.currentUser;
      await updatePassword(user, newPassword);
      setPasswordMessage("Password updated successfully!");

      // Clear fields after success
      setNewPassword("");
      setConfirmPassword("");

      setTimeout(() => setPasswordMessage(""), 3000);
    } catch (error) {
      console.error("Error changing password:", error);
      setPasswordMessage("Failed to update password. Try again.");
      setTimeout(() => setPasswordMessage(""), 3000);
    }
  };

  return (
    <fieldset className={styles.profileEditFieldset}>
      <legend className={styles.profileEditLegend}>Change Password</legend>

      {/* Password Change Row */}
      <div className={styles.passwordChangeRow}>
        {/* New Password Input */}
        <div className={styles.passwordInputContainer}>
          <input 
            type={passwordVisible ? "text" : "password"} 
            className={styles.profileEditInput} 
            placeholder="New Password" 
            value={newPassword} 
            name="newPassword" 
            onChange={(e) => setNewPassword(e.target.value)} 
            required 
          />
          <button 
            type="button" 
            className={styles.passwordToggle} 
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? <EyeOff size={18} /> : <Eye size={18} />}
          </button>
        </div>

        {/* Confirm New Password Input */}
        <div className={styles.passwordInputContainer}>
          <input 
            type={confirmPasswordVisible ? "text" : "password"} 
            className={styles.profileEditInput} 
            placeholder="Confirm New Password" 
            value={confirmPassword} 
            name="confirmPassword" 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
          <button 
            type="button" 
            className={styles.passwordToggle} 
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          >
            {confirmPasswordVisible ? <EyeOff size={18} /> : <Eye size={18} />}
          </button>
        </div>

        {/* Change Password Button */}
        <button type="button" className={styles.profileEditButton} 
          onClick={handlePasswordChange} 
          disabled={!newPassword || !confirmPassword || newPassword !== confirmPassword}>
          Change Password
        </button>

      </div>

      {/* Helper Text for Confirm Password */}
      <div className={styles.helperText}>
        Please re-enter your new password to confirm.
      </div>

      {/* Live Validation Message */}
      {newPassword && confirmPassword && newPassword !== confirmPassword && (
        <div className={styles.passwordWarning}>⚠️ Passwords do not match.</div>
      )}

      {/* Success/Error Message */}
      {passwordMessage && <div className={styles.submitMessage}>{passwordMessage}</div>}
    </fieldset>
  );
};

export default ChangePassword;
