import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp, updateDoc, arrayUnion } from 'firebase/firestore';
import { generatePQWEmailTextTemplate, generatePQWEmailHTMLTemplate } from '../EmailTemplates/emailTemplates';
import { getNextJobId, insertPreApprovedJob, createNotification, uploadPhoto } from '../services/dbService';
import { auth } from '../firebase';
import { globalConfig } from '../config';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { createQuote } from '../services/quoteService';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ImagePopup from '../ImagePopup/ImagePopup';
import { createRoot } from "react-dom/client";
import styles from '../styles/PreQuotedWorkForm.module.css';

const PreQuotedWorkForm = ({ pqwData, propertyId, agentId, onReturnToDashboard }) => {
  const landlordEmail = auth.currentUser?.email;
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const today = new Date();
    const defaultAvailableDays = [];
  
    for (let i = 1; i <= 30; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        defaultAvailableDays.push({
          title: "Not Busy",
          start: date.toISOString().split("T")[0],
          color: "green",
          interactive: false,
        });
      }
    }
    setEvents(defaultAvailableDays);
  }, []);

  const handlePhotoUpload = async (files) => {
    const uploaded = [];
    for (let file of files) {
      const path = `jobs/${propertyId}/${Date.now()}_${file.name}`;  // Unique path for each photo
      const photoURL = await uploadPhoto(file, path);
      uploaded.push(photoURL);
    }
    setUploadedPhotos((prev) => [...prev, ...uploaded]); // Add uploaded URLs to state
  };

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  if (!pqwData) {
    return (
      <div>
        <p>Loading pre-quoted work details...</p>
      </div>
    );
  }

  const fetchLandlordDetails = async (landlordEmail) => {
    const userDocRef = doc(db, "users", landlordEmail); 
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        firstName: userData.firstName,
        lastName: userData.lastName,
      };
    } else {
      throw new Error("Landlord not found");
    }
  };

  const handleConfirm = async () => {
    try {
      // Fetch landlord details
      const landlordDetails = await fetchLandlordDetails(landlordEmail);
  
      // Fetch property details
      const propertyDetails = await fetchPropertyDetails(propertyId);

      // Fetch the next available job ID for the property
      const jobId = await getNextJobId(propertyId);
  
      const currentTime = serverTimestamp(); // Capture current time once

      // Calculate price and VAT
        const netPrice = parseFloat(pqwData.price) || 0;
        const vatAmount = (netPrice * 0.2).toFixed(2); // 20% VAT
        const totalPrice = (netPrice + parseFloat(vatAmount)).toFixed(2);
  
      const jobData = {
        jobDetail: pqwData.title,
        quoteAmount: pqwData.price,
        tradeName: pqwData.trade, 
        propertyId: propertyId,
        completedDate: null,
        contractorName: null,
        furtherDetails: pqwData.jobDetail,
        instructionNotes: null,
        jobId: jobId,
        landlordEmail,
        landlordFirstName: landlordDetails.firstName, 
        landlordLastName: landlordDetails.lastName, 
        paid: false,
        paymentId: null,
        quoteAccepted: true,
        quoteAcceptedAt: currentTime,
        quoteTime: null,
        raised: currentTime,
        tradeId: pqwData.id,
        isPQW: true,
        agentId: agentId,
        photos: uploadedPhotos.length > 0 ? uploadedPhotos : [],
        busyDates: events.filter(event => event.title === "Busy").map(event => event.start), 
        netPrice, 
        vatAmount,
      };
  
      // Insert the pre-approved job
      await insertPreApprovedJob(propertyId, jobId, jobData);

      // Save images to Firestore (updates existing job doc)
      const jobRef = doc(db, 'jobs', jobId);
      await updateDoc(jobRef, { 
        ...(jobData.busyDates?.length ? { busyDates: arrayUnion(...jobData.busyDates) } : {}),
        ...(uploadedPhotos?.length ? { photos: arrayUnion(...uploadedPhotos) } : {})
      });
              
      // Create and insert the quote record
      const quoteData = {
        jobId,
        landlordEmail,
        trade: pqwData.trade,
        instructionNotes: null,
        furtherDetails: pqwData.furtherDetails || null,
        quoteExpiryDate: null,
        quoteDetails: pqwData.jobDetail,
        costBreakdown: [{ 
          description: pqwData.title, 
          id: 1,
          net: netPrice,
          quantity: "",
          total: totalPrice,
          type: "Labour",
          unitPrice: "",
          vatAmount: vatAmount,
          vatRate: "20%",
        }],
        createdAt: currentTime,
      };
  
      setSubmissionStatus("Creating Job...");
      const status = 2;
      await createQuote(jobId, quoteData, status);
  
      // Add an initial comment for the job
      const initialComment = {
        sender: 'Admin',
        text: `Pre-quoted work confirmed for ${pqwData.jobDetail}`,
        timestamp: new Date(),
        recipient: null,
      };
  
      const commentsRef = doc(db, 'comments', jobId);
      await setDoc(commentsRef, {
        jobId,
        comments: [initialComment],
      });

      setSubmissionStatus("Sending email confirmation for Job...");

      // Send email notification to the landlord
      const plainTextBody = generatePQWEmailTextTemplate(jobData.jobId, jobData.landlordFirstName);
      const htmlBody = generatePQWEmailHTMLTemplate(jobData.jobId, jobData.landlordFirstName);
  
      await sendNotificationEmail({
        to: jobData.landlordEmail,
        subject: `${globalConfig.sitename} quote - Job ${jobData.jobId}`,
        text: plainTextBody,
        html: htmlBody,
      });

      const propertyAddress = propertyDetails.address + ", " + propertyDetails.city + ", " + propertyDetails.postcode;

      setSubmissionStatus("Creating notifications for Job...");

      // Create Site Notifications
      const customMessage = `Landlord ${landlordEmail} has requested Pre Quoted Work ${jobData.jobId}`;
      await createNotification(
        jobData.jobId, 
        "pqw_requested",
        {
          admin: true, // Notify admin
          agent: agentId, // Notify the agent
        }, 
        propertyAddress,
        "Quote",
        customMessage,
      );
  
      setSubmissionStatus("Request submitted successfully! - Returning to Dashboard");
      setTimeout(() => {
        onReturnToDashboard();
      }, 2500);

    } catch (error) {
      console.error('PreQuotedWorkForm: Error confirming pre-approved work:', error);
      alert('There was an error confirming the work. Please try again.');
    }
  };  

  const handleDateClick = (info) => {
    const today = new Date().toISOString().split("T")[0];
    if (info.dateStr <= today) return;
  
    setEvents((prevEvents) => {
      const existingEvent = prevEvents.find((event) => event.start === info.dateStr);
      let updatedEvents;
  
      if (existingEvent) {
        updatedEvents = existingEvent.title === "Busy"
          ? prevEvents.filter((event) => event.start !== info.dateStr)
          : prevEvents.map((event) =>
              event.start === info.dateStr ? { ...event, title: "Busy", color: "red" } : event
            );
      } else {
        updatedEvents = [...prevEvents, { title: "Busy", start: info.dateStr, color: "red" }];
      }
  
      return updatedEvents;
    });
  };
  
  const handleImageClick = (index, images) => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const popupWidth = 1100;
    const popupHeight = 800;
    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;
  
    const newWindow = window.open("", "_blank", `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
    newWindow.document.title = "Image Viewer";
  
    const container = newWindow.document.createElement("div");
    newWindow.document.body.appendChild(container);
  
    const root = createRoot(container);
    root.render(
      <ImagePopup images={images} initialIndex={index} onClose={() => newWindow.close()} />
    );
  };

  const handleDeletePhoto = (index) => setUploadedPhotos((prev) => prev.filter((_, i) => i !== index));
  
  return (
    <>
    <div className={styles.preQuotedReturnToDashboardRow}>
      <button className={styles.cancelBtn} onClick={onReturnToDashboard}>Cancel and Return to Dashboard</button>
    </div>


    <div className={styles.preQuotedWorkContainer}>
      <div className={styles.preQuotedWorkForm}>

        <h2>Pre Quoted Works: {pqwData.title}</h2>
        <h3>Description: {pqwData.jobDetail}</h3>
        <h3>Trade Type: {pqwData.trade}</h3>


        <div className={styles.jobDetailRow}>
          <div className={styles.formGroup}>
            <label className={styles.priceLabel}>Net Price:</label>
            <input type="text" value={`£${parseFloat(pqwData.price).toFixed(2)}`} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.priceLabel}>VAT (20%):</label>
            <input type="text" value={`£${(parseFloat(pqwData.price) * 0.2).toFixed(2)}`} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.priceLabel}>Total Price:</label>
            <input type="text" value={`£${(parseFloat(pqwData.price) * 1.2).toFixed(2)}`} readOnly />
          </div>
        </div>

        <div className={styles.photoUploadSection}>
          <h4>Upload photographs relating to the work</h4>
        </div>

        <div className={styles.photoUploadInputRow}>
          <button type="button" className={styles.uploadButton} onClick={() => document.getElementById("fileUpload").click()}>
            Choose photos
          </button>
          <input id="fileUpload" className={styles.hiddenFileInput} type="file" multiple onChange={(e) => handlePhotoUpload(e.target.files)} />
        </div>

        <div className={styles.uploadedPhotos}>
          {uploadedPhotos.map((photo, index) => (
            <div key={index} className={styles.uploadedPhotoContainer}>
              <img src={photo} alt={`Uploaded ${index + 1}`} className={styles.uploadedPhoto} onClick={() => handleImageClick(index, uploadedPhotos)} />
              <button type="button" className={styles.deletePhotoButton} onClick={() => handleDeletePhoto(index)}>✖</button>
            </div>
          ))}
        </div>

        {submissionStatus && (
          <p className={`${styles.submissionStatus} ${submissionStatus.includes("error") ? styles.error : submissionStatus.includes("success") ? styles.success : ""}`}>
            {submissionStatus}
          </p>
        )}

      </div>

      <div className={styles.calendarContainer}>
        <h3>Availability</h3>
        <FullCalendar 
          plugins={[dayGridPlugin, interactionPlugin]} 
          initialView="dayGridMonth" 
          dateClick={handleDateClick} 
          events={events} 
        />
      </div>

    </div>
    <div className={styles.preQuotedConfirmWorksRow}>
      <button className={styles.confirmBtn} onClick={handleConfirm}>Confirm Request</button>
    </div>

    </>
  );
};

export default PreQuotedWorkForm;
