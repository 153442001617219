import React, { useState, useEffect, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { formatDateOnly } from '../Utilities/dateUtils';
import { fetchPropertiesSubmittedByAgent } from '../services/dbService';
import { auth } from '../firebase';

const AgentPropertiesSubmitted = ({ onRowSelectAction }) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const columnDefs = [
    { headerName: "PROPERTY ID", field: "id", flex: 0.6 },
    { headerName: "ADDRESS", field: "address", flex: 1.5 },
    { headerName: "POSTCODE", field: "postcode", flex: 0.5 },
    { headerName: "INVITED", field: "invitationDate", flex: 0.5,
      valueFormatter: (params) => formatDateOnly(params.value) },
    { headerName: "ACCEPTED", field: "inviteAcceptedAt", flex: 0.5,
      valueFormatter: (params) => formatDateOnly(params.value) }
  ];

    const fetchProperties = useCallback(async () => {
      try {
        setLoading(true);
        const email = auth.currentUser?.email;
        const data = await fetchPropertiesSubmittedByAgent(email);
        setProperties(data);
      } catch (error) {
        console.error('Error fetching properties:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }, []);

      useEffect(() => {
        const handleRefresh = () => {
          fetchProperties(); 
        };
    
        window.addEventListener("refreshGrids", handleRefresh);
        return () => window.removeEventListener("refreshGrids", handleRefresh);
      }, [fetchProperties]); 
    
      
      useEffect(() => {
        fetchProperties(); 
      }, [fetchProperties]);
      

  const handleRowClick = (jobData) => {

    const tabModes = {
      Property: 'readOnly',
      Quote: 'hide',
      Detail: 'hide',
      Comments: 'hide',
      Appointments: 'hide',
    };
  
    if (jobData.data) {
      onRowSelectAction(jobData.data,tabModes, 'Property');
    } else {
      onRowSelectAction(jobData,tabModes, 'Property');
    }
  
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading properties: {error.message}</p>;

  return (
      <GridPanel title="Your Invited Properties [agent]" rowData={properties} columnDefs={columnDefs} onRowClick={handleRowClick} />
  );
};

export default AgentPropertiesSubmitted;
