import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { useUser } from "../context/UserContext";

const ContractorJobsAccepted = ({ isSingleColumn, onRowSelectAction}) => {
  const [acceptedJobs, setAcceptedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const userX = useUser();  

  const loadAcceptedJobs = useCallback(async () => {
    const options = { 
      status: 3,
      contractorEmail: userX.user.email, 
    };

    setSelectedTab("Appointments");
    options.contractorName = userX.user.companyName;
    options.contractorEmail = userX.user.email;
    const jobs = await fetchJobs(options);
      setAcceptedJobs(jobs);
  }, [userX]);

  useEffect(() => {
    const handleRefresh = () => {
      loadAcceptedJobs(); 
    };
  
    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadAcceptedJobs]); 
  
  useEffect(() => {
    loadAcceptedJobs(); 
  }, [loadAcceptedJobs]);

  const columnDefsAcceptedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    { headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.5,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "ACCEPTED DATE", field: "quoteAcceptedAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleRowClick = (event) => {
    const jobData = event.data;
    const tabModes =  {
        Quote: 'readOnly',
        Detail: 'readOnly',
        Comments: 'readWrite',
        Appointments: 'readWrite',
        Property: 'hide',
      }    
      onRowSelectAction(jobData, tabModes, selectedTab);
  };

  return (
    <GridPanel
      title="Jobs Accepted [contractor]" // 3
      columnDefs={columnDefsAcceptedJobs}
      rowData={acceptedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default ContractorJobsAccepted;
