import React, { useState, useEffect } from "react";
import { updateUserProfile,fetchUserByEmail } from "../services/dbService";
import ChangePassword from "../components/ChangePassword";
import styles from "../styles/ProfileEdit.module.css";

const AdminEditProfile = ({ userDetails }) => {
  const [submitMessage, setSubmitMessage] = useState("");
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [isPasswordChanging] = useState(false);

  const [formData, setFormData] = useState({
    allowEmail: userDetails?.allowEmail !== undefined ? userDetails.allowEmail : true,
    allowNotifications: userDetails?.allowNotifications !== undefined ? userDetails.allowNotifications : true,
    ...userDetails // Preserve other profile fields
  });
    
  useEffect(() => {
    const loadUser = async () => {
      if (!userDetails?.email) return;
  
      try {
        const freshUserData = await fetchUserByEmail(userDetails.email);
        if (freshUserData) {
          setFormData({
            ...freshUserData,
            allowEmail: freshUserData.allowEmail ?? true,
            allowNotifications: freshUserData.allowNotifications ?? true,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    loadUser();
  }, [userDetails?.email]);
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value, // Handles checkboxes properly
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage("");
  
    try {
      await updateUserProfile(formData.email, formData);
      setSubmitMessage("Profile updated successfully!");
      
      // Auto-clear message after 3 seconds
      setTimeout(() => setSubmitMessage(""), 3000);

    } catch (error) {
      setSubmitMessage("Failed to update profile." + error);

      setTimeout(() => setSubmitMessage(""), 3000);
    }
  };
  
  return (
    <div className={styles.container}>
      <h2>Edit Profile</h2>

      <div className={styles.profileEditSubheadingRow}>
        <div className={styles.profileEditSubHeading}>
          Make changes to your profile information, email sending, notifications, and password
        </div>
        <div className={styles.profileEditSubheadingButtonContainer}>
          <button 
            type="button" 
            className={styles.profileEditSmallButton} 
            onClick={() => setShowPasswordForm((prev) => !prev)}
          >
            {showPasswordForm ? "Cancel" : "Change Password"}
          </button>
        </div>
      </div>

      {showPasswordForm ? (
        <ChangePassword setShowPasswordForm={setShowPasswordForm} />
      ) : (

        <form onSubmit={handleSubmit} className={styles.form}>

          {/* Personal Information */}
          <fieldset className={styles.profileEditFieldset}>
            <legend className={styles.legend}>Personal Information</legend>
            <div className={`${styles.row} ${styles.fourColumns}`}>
              <div>
                <label className={styles.label}>Appearance Name (read only)</label>
                <input type="text" className={styles.profileInput} name="firstName" value={formData.firstName || ""} onChange={handleChange} required readOnly/>
              </div>
              <div>
                <label className={styles.label}>Email Address</label>
                <input type="email" className={styles.profileInput} name="email" value={formData.email || ""} onChange={handleChange} required/>
              </div>
            </div>
          </fieldset>

          {/* Terms and Conditions */}
          <div className={styles.notificationPreferences}>

            {/* Send Email */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowEmail" name="allowEmail" 
                checked={formData.allowEmail ?? true} onChange={handleChange} />
              <label htmlFor="allowEmail" className={styles.profileEditLabel}>
                Allow Email: <span className={styles.profileEditInfo}>The site will send you emails based on activity connected to you.</span>
              </label>
            </div>

            {/* Allow Notifications */}
            <div className={styles.profileEditCheckboxRow}>
              <input type="checkbox" id="allowNotifications" name="allowNotifications" 
                checked={formData.allowNotifications ?? true} onChange={handleChange} />
              <label htmlFor="allowNotifications" className={styles.profileEditLabel}>
                Allow Notifications: <span className={styles.profileEditInfo}>When there is new activity, a new notification in the website will appear.</span>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className={styles.profileEditsubmitContainer}>
            {submitMessage && <div className={styles.submitMessage}>{submitMessage}</div>}
            <button type="submit" className={styles.profileEditButton} 
              disabled={isPasswordChanging}>
              Save Changes
            </button>
          </div>

        </form>
      )}
    </div>
  );
};

export default AdminEditProfile;
