import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { STATUS_MAPPING } from '../services/dbService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { formatTimestamp } from '../Utilities/dateUtils';
import { useUser } from "../context/UserContext";

const LandlordReadyForSignOff = ({ onRowSelectAction }) => {
  
  const userX = useUser();  
  const [readyForSignOffJobs, setReadyForSignOffJobs] = useState([]);

  const loadReadyForSignOffJobs = useCallback(async () => {
    const options = { 
      status: 6,
      landlordEmail: userX.user.email, 
    };
    const jobs = await fetchJobs(options);
    setReadyForSignOffJobs(jobs);
  }, [userX.user.email]);

  useEffect(() => {
    const handleRefresh = () => {
      loadReadyForSignOffJobs(); 
    };

      window.addEventListener("refreshGrids", handleRefresh);
      return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadReadyForSignOffJobs]); 
  
  useEffect(() => {
    loadReadyForSignOffJobs(); 
  }, [loadReadyForSignOffJobs]);

  const handleRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };

    onRowSelectAction(jobData, tabModes);
  };

  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "TRADE", 
      field: "tradeName",
      flex: 1.2,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)' 
          ? params.data.instructionNotes || 'N/A' 
          : params.data.tradeName;
      }
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 1.5 },
    { headerName: "QUOTE DATE", field: "createdAt", flex: 1.2,
      valueFormatter: (params) => formatTimestamp(params.value) },
    { headerName: "STATUS", 
        field: "status", 
        flex: 0.7,
        valueFormatter: (params) => {
          return STATUS_MAPPING[String(params.value)]?.label || STATUS_MAPPING.default.label;
        }
    }   
  ];

  return (
    <GridPanel
      title="Completed, Ready for Sign Off [landlord]" // 6
      rowData={readyForSignOffJobs}
      columnDefs={columnDefs}
      onRowClick={handleRowClick}
    />
  );
};

export default LandlordReadyForSignOff;