import React from 'react';

const PropertyDetailsPanel = ({ propertyDetails }) => {
  return (
    <div className="property-details-panel">
      <div className="property-details-row">
        <div className="property-details-item">
          <span className="property-details-title">Address</span>
          <span className="property-details-value">
  {propertyDetails.address?.split(",").map((part, index) => (
    <React.Fragment key={index}>
      {part}
      <br />
    </React.Fragment>
  ))}

  {propertyDetails.companyCity && (
    <>
      {propertyDetails.companyCity}
      <br />
    </>
  )}

  {propertyDetails.postcode && (
    <>
      {propertyDetails.postcode}
    </>
  )}
</span>
        </div>
        <div className="property-details-item">
          <span className="property-details-title">Agency</span>
          <span className="property-details-value">{propertyDetails.agency}</span>
        </div>
        <div className="property-details-item">
          <span className="property-details-title">Landlord</span>
          <span className="property-details-value">{propertyDetails.user?.firstName} {propertyDetails.user?.lastName}</span>
        </div>
        <div className="property-details-item">
          <span className="property-details-title">Mobile</span>
          <span className="property-details-value">{propertyDetails.user?.mobileNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsPanel;
