import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import CPAdmin from "../CompleteProfile/CPAdmin";
import CPAgent from "../CompleteProfile/CPAgent";
import CPLandlord from "../CompleteProfile/CPLandlord";
import CPContractor from "../CompleteProfile/CPContractor";

const CompleteProfile = () => {
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRole = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get("email");

      if (!email) {
        console.warn("Unauthorized access to /complete-profile. Redirecting...");
        navigate("/login");
        return;
      }

      try {
        const userDocRef = doc(db, "users", email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.role) {
            setRole(userData.role);
          } else {
            console.error("No role found for user. Redirecting...");
            navigate("/login");
          }
        } else {
          console.error("User document not found. Redirecting...");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        navigate("/login");
      }
    };

    fetchRole();
  }, [navigate]);

  if (role === null) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ backgroundColor: "#112B1A", minHeight: "100vh", padding: "20px" }}>
      {role === "Admin" && <CPAdmin />}
      {role === "Agent" && <CPAgent />}
      {role === "Landlord" && <CPLandlord />}
      {role === "Contractor" && <CPContractor />}

      {/* <CPAdmin /> */}
      {/* <CPAgent /> */}
      {/* <CPLandlord /> */}
      {/* <CPContractor /> */}
    </div>
  );
};

export default CompleteProfile;
