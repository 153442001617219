import React, { useState, useEffect } from 'react';
import JobTabsDetail from './JobTabsDetail';
import Comments from '../Comments/Comments';
import QuoteForm from '../QuoteForm/QuoteForm';
import PropertyDetail from '../PropertyInformation/PropertyDetail';
import PropertyDetailsPanel from '../PropertyDetailsPanel/PropertyDetailsPanel'; 
import { fetchPropertyById, updateJobStatus, fetchUserByEmail, updateQuoteWithFeedback } from '../services/dbService';
import { assignJobToContractor, createNotification, fetchContractorsBySubscriptionType } from "../services/dbService";
import { markTabAsViewed } from "../services/dbService";
import ContractorAppointmentForm from '../AppointmentForm/ContractorAppointmentForm';
import ContractorAppointmentReadOnly from "../AppointmentForm/ContractorAppointmentReadOnly";
import LandlordAppointmentForm from '../AppointmentForm/LandlordAppointmentForm';
import { notifyLandlord, fetchContractorQuoteIds, renameAndStoreChosenQuote, createStandardQuoteFromAccepted, updateJobWithAcceptedQuoteDetails } from '../services/quoteService';
import { SystemCommenter} from '../Shared/SystemCommenter'
import { globalConfig } from '../config';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { generateAcceptanceEmailHTMLTemplate, generateAcceptanceEmailTextTemplate } from '../EmailTemplates/emailTemplates';
import '../styles/JobTabs.css';

const JobTabs = ({ jobData, onReturnToDashboard, tab, tabModes, onReturnToNotification}) => {

// JOB TABS ORIGINAL
debugger;

  const [propertyDetails, setPropertyDetails] = useState('');
  const [isAssignable, setIsAssignable] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isProvidingFeedback, setIsProvidingFeedback] = useState(false);
  const [contractors, setContractors] = useState([]);
  const [userData, setUserData] = useState(null);
  const [activeTab, setActiveTab] = useState(tab || "Detail");
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [remainingChars, setRemainingChars] = useState(4000);
  const isAdmin = userData?.role === "Admin";
  const isAgent = userData?.role === "Agent";
  const isLandlord = userData?.role === "Landlord";
  const isContractor = userData?.role === "Contractor";
  const [contractorQuoteIds, setContractorQuoteIds] = useState([]);
  const [unreadTabs, setUnreadTabs] = useState([]);
  
  // Loads property light and sets using setPropertyDetails
  useEffect(() => {
    const loadProperty = async () => {
      try {
        const propertyData = await fetchPropertyById(jobData.propertyId);
        if (propertyData) {
          setPropertyDetails(propertyData);
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    loadProperty();
  }, [jobData.propertyId]);

  // Loads the users details
  useEffect(() => {
    const loadUserData = async () => {
      try {
        const fetchedUser = await fetchUserByEmail(auth.currentUser.email);
        if (fetchedUser) {
          setUserData(fetchedUser);
  
          // Update unread tabs based on fresh user data
          const updatedUnreadTabs = [];
          if (fetchedUser.quoteNotif) updatedUnreadTabs.push("Quote");
          if (fetchedUser.commentNotif) updatedUnreadTabs.push("Comments");
          setUnreadTabs(updatedUnreadTabs);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    loadUserData();
  }, []);
  
  // CLEAN BOUNDARY

  // Function to handle tab selection
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);

    // If the tab has an unread flag, update Firestore & remove from `unreadTabs`
    if (unreadTabs.includes(tabName)) {
      setUnreadTabs((prev) => prev.filter((t) => t !== tabName)); // Remove the dot immediately

      if (userData) {
        if (tabName === "Quote" && userData.quoteNotif) {
          markTabAsViewed(userData.email, "quoteNotif", jobData.jobId);
        }
        if (tabName === "Comments" && userData.commentNotif) {
          markTabAsViewed(userData.email, "commentNotif", jobData.jobId);
        }
      }      
    }
  };
    
  const handleQuoteDetailsChange = (e) => {
      const text = e.target.value;
      setFeedbackDetails(text);
      setRemainingChars(4000 - text.length);
  };

  // Load Contractor Quotes
  useEffect(() => {
    const loadContractorQuotes = async () => {
      if (jobData.jobId) {
        const quoteIds = await fetchContractorQuoteIds(jobData.jobId);
        setContractorQuoteIds(quoteIds);
      }
    };
  
    loadContractorQuotes();
  }, [jobData.jobId]);
    
  const handleReturn = () => {
    if (onReturnToNotification) {
      onReturnToNotification();
    } else {
      onReturnToDashboard();
    }
  };

  // Loads a list of Platinum Contractors
  useEffect(() => {
    const loadContractors = async () => {
      try {
        const contractorsList = await fetchContractorsBySubscriptionType(0);
        setContractors(contractorsList);
      } catch (error) {
        alert("Error loading contractors: " + error.message);
      }
    };

    if (showDropdown) {
      loadContractors();
    }
  }, [showDropdown]);

  // Defines Assignable flag
  useEffect(() => {
    if (jobData?.status === 2) {
      setIsAssignable(true);
    }
  }, [jobData]);
  
  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        companyCity: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  const handleAcceptQuote = async () => {

    try {

      // Step 1: Is this a contractor-provided quote?
      if (contractorQuoteIds.length > 0) {
        try {
          const quoteIndex = contractorQuoteTabs.findIndex(tab => tab.name === activeTab);
          if (quoteIndex !== -1) {
            const selectedQuoteId = contractorQuoteIds[quoteIndex];
            // console.log('selectedQuoteId: ' + selectedQuoteId);
            // Step 2: Rename the existing contractor quote doc and store chosenQuoteId
            await renameAndStoreChosenQuote(jobData.jobId, selectedQuoteId);
            // console.log(`Contractor quote ${selectedQuoteId} renamed and stored.`);
      
            // Step 3: Create a standard quote doc (M1020-4)
            await createStandardQuoteFromAccepted(jobData.jobId, selectedQuoteId);
            // console.log(`Standard quote created for job ${jobData.jobId}`);
      
            // Step 4: Fetch contractor details from the selected quote (not M1020-4)
            const selectedQuoteRef = doc(db, "quotes", selectedQuoteId);
            const selectedQuoteSnap = await getDoc(selectedQuoteRef);
      
            if (!selectedQuoteSnap.exists()) {
              throw new Error(`Selected contractor quote ${selectedQuoteId} not found.`);
            }
      
            const selectedQuoteData = selectedQuoteSnap.data();
            if (!selectedQuoteData.contractorId || !selectedQuoteData.companyName) {
              throw new Error(`Missing contractor details in selected quote ${selectedQuoteId}`);
            }
      
            // console.log(`Assigning job to contractor: ${selectedQuoteData.companyName}, ${selectedQuoteData.contractorId}`);
      
            // Step 5: Assign the job to the contractor
            await assignJobToContractor({
              jobId: jobData.jobId,
              contractorName: selectedQuoteData.companyName,
              contractorEmail: selectedQuoteData.contractorId,
            });
      
            // Step 6: Update job record with financial details
            await updateJobWithAcceptedQuoteDetails(jobData.jobId, selectedQuoteId);
      
            // console.log(`Job ${jobData.jobId} fully updated with contractor quote details.`);
            onReturnToDashboard(); 
            return;
          }
        } catch (error) {
          console.error("Error processing contractor quote acceptance:", error);
          alert(`Error processing contractor quote: ${error.message}`);
          onReturnToDashboard(); 
          return; 
        }
      }
      
        // Step 2: Update job status to '2' (Accepted) after payment is confirmed
        await updateJobStatus(jobData.jobId, 2); // Status '2' represents an accepted quote.

        // Step 3: Notify the landlord or perform any other relevant actions
        notifyLandlord(jobData.landlordEmail, `Quote for Job ${jobData.jobId} has been accepted.`);
    
        // Step 4: Add system comment
        await SystemCommenter(
          jobData.jobId, 
          'Admin', 
          'The quote has been accepted for this job.'
        );

        // Fetch property details
        const propertyDetails = await fetchPropertyDetails(jobData.propertyId);
        const customMessage = `A quote has been accepted by ${jobData.landlordEmail} for ${jobData.jobId}`;
        const notifAddress = `${propertyDetails.address} ${propertyDetails.companyCity} ${propertyDetails.postcode}`; 
        await createNotification(
          jobData.jobId, 
          "quote_accepted", {
            admin: true, 
            agent: jobData.agentId,
          }, 
          notifAddress, 
          "Quote", 
          customMessage
        );
        
        // Step 5: Provide feedback and close the form
        onReturnToDashboard(); 
 
    } catch (error) {
      console.error('Error accepting the quote:', error);
      alert('Error accepting the quote: ' + error);
    }
  };

  const landlordData = {
    landlordFirstname: jobData.landlordFirstName,
    landlordLastname: jobData.landlordLastName,
    landlordEmail: jobData.landlordEmail,
  };
  
  // Sets the active tab as the first one defined in tabModes Array
  useEffect(() => {
    if (tabModes.length > 0) {
      setActiveTab(tabModes[0].name);
    }
  }, [tabModes]);

  const visibleTabs = Object.keys(tabModes).filter(key => tabModes[key] !== 'hide');
  let AppointmentComponent;

  if (isContractor) {
    AppointmentComponent = tabModes?.Appointments === "readOnly" ? ContractorAppointmentReadOnly : ContractorAppointmentForm;
  } else {
    AppointmentComponent = LandlordAppointmentForm;
  }

  if (isAdmin) {
    AppointmentComponent = ContractorAppointmentReadOnly
  }

  const contractorQuoteTabs = contractorQuoteIds.map((id, index) => ({
    name: `Quote ${index + 1}`,
    component: <QuoteForm jobData={jobData} quoteId={id} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} />,
  }));
  
  const tabs = [
    { name: 'Detail', component: <JobTabsDetail jobId={jobData.jobId} readOnly={tabModes?.Detail === 'readOnly'} /> },  
    ...(contractorQuoteIds.length > 0 
      ? contractorQuoteTabs // If contractor quotes exist, add them dynamically
      : [{ name: 'Quote', component: <QuoteForm jobData={jobData} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} landlord /> }]
    ),
    { name: 'Comments', component: <Comments jobData={jobData} propertyDetails={propertyDetails} isActive={activeTab === 'Comments'} readOnly={tabModes?.Comments === 'readOnly'} /> },
    { name: 'Appointments', component: <AppointmentComponent jobId={jobData.jobId} readOnly={tabModes?.Appointments === 'readOnly'} landlordData={landlordData} onReturnToDashboard={onReturnToDashboard} propertyId={propertyDetails.propertyId} /> },
    { name: 'Property', component: <PropertyDetail propertyId={jobData.propertyId} readOnly={tabModes?.Property === 'readOnly'} /> },
  ].filter(tab => visibleTabs.includes(tab.name) || contractorQuoteIds.some((_, index) => tab.name === `Quote ${index + 1}`));
  
  const handleContractorAcceptJob = async () => {
    // alert('handleContractorAcceptJob');
    try {
      const userData = await fetchUserByEmail(auth.currentUser.email);
      const options = {
        jobId: jobData.jobId,
        contractorName: userData.companyName,
        contractorEmail: userData.email,
      };

      await assignJobToContractor(options);

      // Update chat comments
      await SystemCommenter(jobData.jobId, 'Admin', `The Job has been accepted by contractors: ${userData.companyName}`);

      // Create site notification for admin, Landlord, agent
      const propertyDetails = await fetchPropertyDetails(jobData.propertyId);
      const propertyAddress = propertyDetails.address + ", " + propertyDetails.companyCity + ", " + propertyDetails.postcode;

      // Create Site  Notifications
      const customMessage = `Contractor ${userData.companyName} has accepted job ${jobData.jobId}`;
      await createNotification(
        jobData.jobId, 
        "job_accepted", 
        {
          admin: true,
          agent: jobData.agentId,
          landlord: jobData.landlordEmail,
        },
        propertyAddress,
        "Detail",
        customMessage,
      );

      // Send email to Landlord
      const plainTextBody = generateAcceptanceEmailTextTemplate(jobData.jobId, jobData.landlordFirstName);
      const htmlBody = generateAcceptanceEmailHTMLTemplate(jobData.jobId, jobData.landlordFirstName);

      await sendNotificationEmail({
        to: jobData.landlordEmail,
        subject: `${globalConfig.sitename} quote - Accepotance for Job ${jobData.jobId}`,
        text: plainTextBody,
        html: htmlBody,
      });

      onReturnToDashboard();
    } catch (error) {
      alert(error.message);
    }
  };

  const handleContractorMarkJobComplete = async () => {
    try {

      // Mark the job status as complete
      await updateJobStatus(jobData.jobId, 6);

      // TODO CREATE EMAILS

      // Create Site Notifications
      const customMessage = `Contractor ${jobData.contractorName} has marked the job ${jobData.jobId} as work finished, ready for sign off by Landlord`;
      const notifAddress = `${jobData.propertyDetails.address} ${jobData.propertyDetails.companyCity} ${jobData.propertyDetails.postcode}`; 
      await createNotification(
        jobData.jobId, 
        "landlord_accepted_dates", 
        {
          admin: true,
          landlord: landlordData.landlordEmail,
        },
        notifAddress,
        null,
        customMessage,
      );

      // Add Comment
      await SystemCommenter(
        jobData.jobId, 
        'Contractor', 
        'I have completed the work required for this job.'
      );

      onReturnToDashboard();
    } catch (error) {
      console.error('Error setting job as complete:', error);
      alert('Failed to mark job as completed and send invoice.' + error);
    }
  };

  const handleLandlordMarkJobComplete = async () => {
    try {
      await updateJobStatus(jobData.jobId, 7);

      // TODO CREATE EMAILS

      // Create Site Notifications
      const customMessage = `Landlord ${jobData.landlordEmail} has marked the job ${jobData.jobId} as signed off complete`;
      const notifAddress = `${propertyDetails.address} ${propertyDetails.companyCity} ${propertyDetails.postcode}`; 
      await createNotification(
        jobData.jobId, 
        "landlord_marked_complete", 
        {
          admin: true,
          contractor: jobData.contractorEmail,
          agent: jobData.agentId,
        },
        notifAddress,
        null,
        customMessage,
      );

      // Add Comment
      await SystemCommenter(
        jobData.jobId, 
        'Landlord', 
        'The work for the job is signed off as complete.'
      );


      onReturnToDashboard();
    } catch (error) {
      console.error('Error landlord setting job as complete:', error);
      alert('Failed Landlord to mark job as completed and signed off.' + error);
    }
  };

  const handleAssignInHouse = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };
  
  const handleRejectQuote = async () => {
    setIsProvidingFeedback(true);
  };

  const returnToQuote = async () => {
    // Logic to return to the quote view
    // console.log('Returning to quote...');
    setRemainingChars(4000);
    setFeedbackDetails('');
    setIsProvidingFeedback(false);
  };
  
  const ratherNotSay = async () => {
    // Logic for handling 'I'd Rather Not Say' action
    // console.log('User chose not to provide feedback.');
    await updateQuoteWithFeedback(jobData.jobId, 'The landlord chose not to leave any commnets');
    await updateJobStatus(jobData.jobId, -1);
    onReturnToDashboard();
  };
  
  const submitFeedback = async () => {
    try {
      await updateQuoteWithFeedback(jobData.jobId, feedbackDetails);
      await updateJobStatus(jobData.jobId, -1);
      onReturnToDashboard();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Error: Failed to submit feedback. Please try again.');
    }
  };

useEffect(() => {
  if (!userData || unreadTabs.length === 0) return;

  if (unreadTabs.includes(activeTab)) {
    // Mark as viewed immediately in Firestore
    if (activeTab === "Quote" && userData.quoteNotif) {
      markTabAsViewed(userData.email, "quoteNotif");
    }
    if (activeTab === "Comments" && userData.commentNotif) {
      markTabAsViewed(userData.email, "commentNotif");
    }

    // Delay the UI update to remove the dot after 1 second
    const timeout = setTimeout(() => {
      setUnreadTabs((prev) => prev.filter((t) => t !== activeTab));
    }, 1000);

    return () => clearTimeout(timeout); // Cleanup in case of quick unmount
  }
}, [activeTab, userData, unreadTabs]);

  return (
    <div className="job-tabs-container">
      {showDropdown && (
        <select style={{ marginTop: "10px" }}>
          <option value="">-- Select a Contractor --</option>
          {contractors.map((contractor) => (
            <option key={contractor.id} value={contractor.id}>
              {contractor.name}
            </option>
          ))}
        </select>
      )}

      <PropertyDetailsPanel propertyDetails={propertyDetails} />

      {/* NAVIGATION BUTTONS */}


      {isProvidingFeedback && isLandlord && (
      <div className='rejected-quote-feedback-panel'>

        <div className="feedback-panel">
          <h2>Please provide a reason why you are rejecting this quote</h2>
          <textarea id="feedback" rows="4" placeholder="Enter your feedback here..." onChange={handleQuoteDetailsChange}></textarea>
          <div className="textareaInfo"><small>{remainingChars} characters remaining</small>
                  </div>
          
          <div className="button-row">
          <button type="button" onClick={() => returnToQuote()}>Return to Quote</button>
          <button type="button" onClick={() => ratherNotSay()}>I'd Rather Not Say</button>
          <button type="button" onClick={() => submitFeedback()}>Submit Feedback</button>
          </div>
        </div>
      </div>
    )}





      <div className="job-tabs-button-group">
        <div className="tabs-left">
          {tabs.map(({ name }) => (
            <button
              key={name}
              onClick={() => handleTabClick(name)}
              className={`job-tabs-button ${activeTab === name ? 'job-tabs-button-active' : ''}`}
            >
              {name}
              {unreadTabs.includes(name) && <span className="unread-dot"></span>}
            </button>
          ))}
        </div>




        <div className="buttons-right">
          {(isAdmin || isLandlord || isContractor) && (
            <>
              {isLandlord && jobData.status === 1 && !isProvidingFeedback &&(
                <>
                  <button className="negative-button" onClick={handleRejectQuote}>Reject Quote</button>
                  <button className="positive-button" onClick={handleAcceptQuote}>Accept Quote and Pay</button>
                </>
              )}

              {isLandlord && jobData.status === 6 && (
                <button className="negative-button" onClick={handleLandlordMarkJobComplete} style={{ marginLeft: 'auto' }}>Mark Job As Signed Off Complete</button>
              )}


              {isAssignable && isAdmin && !isContractor && (
                <>
              {/* {isPlatinumAssignable && ( */}
                {/* <button className="negative-button" onClick={() => handleReleaseJob(jobData.jobId)} style={{ marginLeft: 'auto' }}>Release Job to Gold Contractors</button> */}
              {/* )}                 */}
                <button className="negative-button" onClick={handleAssignInHouse} style={{ marginLeft: 'auto' }}>Assign job to Platinum Contractor</button>
                </>
              )}
              
              {isContractor && jobData.status === 2 && (
                <button className="negative-button" onClick={handleContractorAcceptJob} style={{ marginLeft: 'auto' }}>Accept Job</button>
              )}


              {isContractor && jobData.status === 5 && (
                <button className="negative-button" onClick={handleContractorMarkJobComplete} style={{ marginLeft: 'auto' }}>Mark Job As Complete And Send Invoice</button>
              )}

              {/* <button className="negative-button" onClick={onReturnToDashboard} style={{ marginLeft: 'auto' }}>Return to Dashboard</button> */}

              {(isAdmin || isAgent || isLandlord || isContractor ) && (
                <button className="negative-button" onClick={handleReturn} style={{ marginLeft: 'auto' }}>
                  {onReturnToNotification ? "Return to Notifications" : "Return to Dashboard"}
                </button>
              )}
              </>
          )}

          {(isAgent) && onReturnToNotification && (
                <button className="negative-button" onClick={handleReturn} style={{ marginLeft: 'auto' }}>
                {onReturnToNotification ? "Return to Notifications" : "Return to Dashboard"}
              </button>
          )}
        </div>
      </div>

      {/* Tab Content */}
      <div className="job-tabs-content">
        {tabs.find(({ name }) => name === activeTab)?.component || <p>Invalid tab selected: {activeTab}</p>}
      </div>
    </div>
  );
};

export default JobTabs;
