import React from "react";
import AgentProfileAdminView from "./AgentProfileAdminView";
import LandlordProfileAdminView from "./LandlordProfileAdminView";
import ContractorProfileAdminView from "./ContractorProfileAdminView";

const ProfileForm = ({ user, onClose, role }) => {
  return (
    <div className="profile-form-container">
      {role === "Agent" && <AgentProfileAdminView userDetails={user} />}
      {role === "Landlord" && <LandlordProfileAdminView userDetails={user} />}
      {role === "Contractor" && <ContractorProfileAdminView userDetails={user} />}
    </div>
  );
};

export default ProfileForm;
