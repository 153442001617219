import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { collection, query, where, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const AwaitingPriceGrid = ({ onRowSelectAction, propertyId }) => {
  const [awaitingPriceJobs, setAwaitingPriceJobs] = useState([]);
  
  const loadJobsAwaitingPrice = useCallback(async () => {
    try {
      const jobs = await fetchJobsAwaitingQuoteByProperty(propertyId, 0);
      setAwaitingPriceJobs(jobs);
    } catch (error) {
      console.error(`Error loading jobs waiting to be priced: for landlord`, error);
    }
  }, [propertyId]);

    useEffect(() => {
      const handleRefresh = () => {
        loadJobsAwaitingPrice(); 
      };
  
      window.addEventListener("refreshGrids", handleRefresh);
      return () => window.removeEventListener("refreshGrids", handleRefresh);
    }, [loadJobsAwaitingPrice]); 

  useEffect(() => {
    loadJobsAwaitingPrice(); 
  }, [loadJobsAwaitingPrice]);


  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    { headerName: "TRADE", 
      field: "tradeName",
      flex: 1.6,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)' 
          ? params.data.instructionNotes || 'N/A' 
          : params.data.tradeName;
      }
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 2 },
    { headerName: "DATE CREATED", 
      field: "createdAt", 
      flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) 
    }
  ];

  const handleRowClick = (jobData) => {
    const tabModes = {
      Detail: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'hide',
      Quote: 'hide',
      Property: 'hide',
    };

    onRowSelectAction(jobData, tabModes, "Detail");
  };

  return (
    <GridPanel
      title="(Quote Requested) Awaiting Price [landlord]"
      rowData={awaitingPriceJobs}
      columnDefs={columnDefs}
      onRowClick={handleRowClick}
    />
  );
};

export default AwaitingPriceGrid;


/**
 * Fetch jobs for a specific property and status.
 * @param {string} propertyId - The ID of the property.
 * @param {number} status - The status of the jobs to fetch.
 * @returns {Promise<Array>} - Array of jobs matching the criteria.
 */
export const fetchJobsAwaitingQuoteByProperty = async (propertyId) => {
  if (!propertyId || typeof propertyId !== 'string') {
    throw new Error('Invalid propertyId: Must be a non-empty string.');
  }

  try {
    const jobsRef = collection(db, 'jobs');
    const jobsQuery = query(
      jobsRef,
      where('propertyId', '==', propertyId),
      where('status', '==', 0),
      orderBy('createdAt')
    );

    const querySnapshot = await getDocs(jobsQuery);
    const jobs = [];

    for (const docSnap of querySnapshot.docs) {
      const jobData = { jobId: docSnap.id, ...docSnap.data() };

      const signpostSnap = await getDoc(doc(db, 'quotes', jobData.jobId));

      // ❌ Only include jobs that do *not* have a signpost
      if (!signpostSnap.exists()) {
        jobs.push(jobData);
      }
    }

    return jobs;
  } catch (error) {
    console.error('Error fetching jobs awaiting quote:', error);
    return [];
  }
};
