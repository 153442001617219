import React, { useState, useEffect } from 'react';
import JobTabsDetail from './JobTabsDetail';
import Comments from '../Comments/Comments';
import QuoteForm from '../QuoteForm/QuoteForm';
import PropertyDetail from '../PropertyInformation/PropertyDetail';
import PropertyDetailsPanel from '../PropertyDetailsPanel/PropertyDetailsPanel'; 
import ContractorAppointmentForm from '../AppointmentForm/ContractorAppointmentForm';
import ContractorAppointmentReadOnly from "../AppointmentForm/ContractorAppointmentReadOnly";
import { fetchPropertyById, updateJobStatus, fetchUserByEmail } from '../services/dbService';
import { assignJobToContractor, createNotification } from "../services/dbService";
import { markTabAsViewed } from "../services/dbService";
import { fetchContractorQuoteIds } from '../services/quoteService';
import { SystemCommenter} from '../Shared/SystemCommenter'
import { globalConfig } from '../config';
import { auth } from '../firebase';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { generateAcceptanceEmailHTMLTemplate, generateAcceptanceEmailTextTemplate } from '../EmailTemplates/emailTemplates';
import '../styles/JobTabs.css';

const JobTabsContractor = ({ jobData, onReturnToDashboard, tab, tabModes, onReturnToNotification, isProvidingQuote = false, myQuoteId = null}) => {
  const [propertyDetails, setPropertyDetails] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(tab || "Detail");
  const [contractorQuoteIds, setContractorQuoteIds] = useState([]);
  const [unreadTabs, setUnreadTabs] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const visibleTabs = Object.keys(tabModes).filter(key => tabModes[key] !== 'hide');
  const AppointmentComponent = tabModes?.Appointments === "readOnly" ? ContractorAppointmentReadOnly : ContractorAppointmentForm;
  const contractorQuoteTabs = contractorQuoteIds.map((id, index) => ({
    name: `Quote ${index + 1}`,
    component: <QuoteForm jobData={jobData} quoteId={id}  quoteNumber={index + 1} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} />,
  }));
  const landlordData = { // Create object ready to pass to sub page
    landlordFirstname: jobData.landlordFirstName,
    landlordLastname: jobData.landlordLastName,
    landlordEmail: jobData.landlordEmail,
  };
  
  const tabs = [
    { name: 'Detail', component: <JobTabsDetail jobId={jobData.jobId} readOnly={tabModes?.Detail === 'readOnly'} /> },  
  
    ...(isProvidingQuote
      ? [{
          name: 'Quote',
          component: myQuoteId
            ? <QuoteForm jobData={jobData} quoteId={myQuoteId} readOnly onClose={onReturnToDashboard} />
            : <QuoteForm jobData={jobData} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} landlord />
        }]
      : contractorQuoteIds.length > 0
        ? contractorQuoteTabs
        : [{
            name: 'Quote',
            component: <QuoteForm jobData={jobData} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} landlord />
          }]
    ),
  
    { name: 'Comments', component: <Comments jobData={jobData} propertyDetails={propertyDetails} isActive={activeTab === 'Comments'} readOnly={tabModes?.Comments === 'readOnly'} /> },
    { name: 'Appointments', component: <AppointmentComponent jobId={jobData.jobId} readOnly={tabModes?.Appointments === 'readOnly'} landlordData={landlordData} onReturnToDashboard={onReturnToDashboard} propertyId={propertyDetails.propertyId} /> },
    { name: 'Property', component: <PropertyDetail propertyId={jobData.propertyId} readOnly={tabModes?.Property === 'readOnly'} /> },
  ].filter(tab => visibleTabs.includes(tab.name) || contractorQuoteIds.some((_, index) => tab.name === `Quote ${index + 1}`));

  
  // Loads property light and sets using setPropertyDetails
  useEffect(() => {
    const loadProperty = async () => {
      try {
        const propertyData = await fetchPropertyById(jobData.propertyId);
        if (propertyData) {
          setPropertyDetails(propertyData);
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    loadProperty();
  }, [jobData.propertyId]);

  // Loads the users details (realtime not persisted)
  useEffect(() => {
    const loadUserData = async () => {
      try {
        const fetchedUser = await fetchUserByEmail(auth.currentUser.email);
        if (fetchedUser) {
          setUserDetails(fetchedUser);
  
          // Update unread tabs based on fresh user data
          const updatedUnreadTabs = [];
          if (fetchedUser.quoteNotif) updatedUnreadTabs.push("Quote");
          if (fetchedUser.commentNotif) updatedUnreadTabs.push("Comments");
          setUnreadTabs(updatedUnreadTabs);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    loadUserData();
  }, []);
      
  // Load Contractor Quotes
  useEffect(() => {
    if (isProvidingQuote) return;
    const loadContractorQuotes = async () => {
      if (jobData.jobId) {
        const quoteIds = await fetchContractorQuoteIds(jobData.jobId);
        setContractorQuoteIds(quoteIds);
      }
    };
  
    loadContractorQuotes();
  }, [jobData.jobId, isProvidingQuote]);
      
  // Sets the active tab as the first one defined in tabModes Array
  useEffect(() => {
    if (tabModes.length > 0) {
      setActiveTab(tabModes[0].name);
    }
  }, [tabModes]);

  //  Unread Tabs 
  useEffect(() => {
    if (!userDetails || unreadTabs.length === 0) return;

    if (unreadTabs.includes(activeTab)) {
      // Mark as viewed immediately in Firestore
      if (activeTab === "Quote" && userDetails.quoteNotif) {
        markTabAsViewed(userDetails.email, "quoteNotif");
      }
      if (activeTab === "Comments" && userDetails.commentNotif) {
        markTabAsViewed(userDetails.email, "commentNotif");
      }

      // Delay the UI update to remove the dot after 1 second
      const timeout = setTimeout(() => {
        setUnreadTabs((prev) => prev.filter((t) => t !== activeTab));
      }, 1000);

      return () => clearTimeout(timeout); // Cleanup in case of quick unmount
    }
  }, [activeTab, userDetails, unreadTabs]);
    
  const handleContractorAcceptJob = async () => {
    try {
      const userData = await fetchUserByEmail(auth.currentUser.email);
      const options = {
        jobId: jobData.jobId,
        contractorName: userData.companyName,
        contractorEmail: userData.email,
      };

      await assignJobToContractor(options);

      // Update chat comments
      await SystemCommenter(jobData.jobId, 'Admin', `The Job has been accepted by contractors: ${userData.companyName}`);

      // Create site notification for admin, Landlord, agent
      const propertyAddress = `${propertyDetails.address || "Unknown Address"}, ${propertyDetails.companyCity || "Unknown Town/City"}, ${propertyDetails.postcode || "Unknown Postcode"}`;

      // Create Site  Notifications
      const customMessage = `Contractor ${userData.companyName} has accepted job ${jobData.jobId}`;
      await createNotification(
        jobData.jobId, 
        "job_accepted", 
        {
          admin: true,
          agent: jobData.agentId,
          landlord: jobData.landlordEmail,
        },
        propertyAddress,
        "Detail",
        customMessage,
      );

      // Send email to Landlord
      const plainTextBody = generateAcceptanceEmailTextTemplate(jobData.jobId, jobData.landlordFirstName);
      const htmlBody = generateAcceptanceEmailHTMLTemplate(jobData.jobId, jobData.landlordFirstName);

      await sendNotificationEmail({
        to: jobData.landlordEmail,
        subject: `${globalConfig.sitename} quote - Accepotance for Job ${jobData.jobId}`,
        text: plainTextBody,
        html: htmlBody,
      });

      // Screen Feedback
      setFeedbackMessage("Job accepted successfully!");
      setTimeout(() => {
        setFeedbackMessage(null);
        onReturnToDashboard();
      }, 3000);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleContractorMarkJobComplete = async () => {
    try {

      // Mark the job status as complete
      await updateJobStatus(jobData.jobId, 6);

      // TODO CREATE EMAILS

      // Create Site Notifications
      const customMessage = `Contractor ${jobData.contractorName} has marked the job ${jobData.jobId} as work finished, ready for sign off by Landlord`;
      const notifAddress = `${propertyDetails.address || "Unknown Address"} ${propertyDetails.companyCity || "Unknown Town/City"} ${propertyDetails.postcode || "Unknown Postcode"}`;
      await createNotification(
        jobData.jobId, 
        "contractor_marked_complete", 
        {
          admin: true,
          landlord: landlordData.landlordEmail,
        },
        notifAddress,
        null,
        customMessage,
      );

      // Add Comment
      await SystemCommenter(
        jobData.jobId, 
        'Contractor', 
        'I have completed the work required for this job.'
      );

      onReturnToDashboard();
    } catch (error) {
      console.error('Error setting job as complete:', error);
      alert('Failed to mark job as completed and send invoice.' + error);
    }
  };

  const handleTabClick = (tabName) => { // Function to handle tab selection
    setActiveTab(tabName);

    // If the tab has an unread flag, update Firestore & remove from `unreadTabs`
    if (unreadTabs.includes(tabName)) {
      setUnreadTabs((prev) => prev.filter((t) => t !== tabName)); // Remove the dot immediately

      if (userDetails) {
        if (tabName === "Quote" && userDetails.quoteNotif) {
          markTabAsViewed(userDetails.email, "quoteNotif", jobData.jobId);
        }
        if (tabName === "Comments" && userDetails.commentNotif) {
          markTabAsViewed(userDetails.email, "commentNotif", jobData.jobId);
        }
      }      
    }
  };
  
  const handleReturn = () => { // Decide where to return to as navigation
    if (onReturnToNotification) {
      onReturnToNotification();
    } else {
      onReturnToDashboard();
    }
  };

  return (
    <div className="job-tabs-container">


      <PropertyDetailsPanel propertyDetails={propertyDetails} />

    {/* SCFREEN FEEBACK */}
    {feedbackMessage && (
      <div className='screen-feedback-row'>
        <span className='screen-feedback-message'>{feedbackMessage}</span>
      </div>
    )}

      {/* NAVIGATION BUTTONS */}
      <div className="job-tabs-button-group">
        <div className="tabs-left">
          {tabs.map(({ name }) => (
            <button
              key={name}
              onClick={() => handleTabClick(name)}
              className={`job-tabs-button ${activeTab === name ? 'job-tabs-button-active' : ''}`}
            >
              {name}
              {unreadTabs.includes(name) && <span className="unread-dot"></span>}
            </button>
          ))}
        </div>

        <div className="buttons-right">
            <>
              {jobData.status === 2 && (
                <button className="negative-button" onClick={handleContractorAcceptJob} style={{ marginLeft: 'auto' }}>Accept Job</button>
              )}

              {jobData.status === 5 && (
                <button className="negative-button" onClick={handleContractorMarkJobComplete} style={{ marginLeft: 'auto' }}>Mark Job As Complete And Send Invoice</button>
              )}

                <button className="negative-button" onClick={handleReturn} style={{ marginLeft: 'auto' }}>
                  {onReturnToNotification ? "Return to Notifications" : "Return to Dashboard"}
                </button>
            </>

        </div>
      </div>

      {/* Tab Content */}
      <div className="job-tabs-content">
        {tabs.find(({ name }) => name === activeTab)?.component || <p>Invalid tab selected: {activeTab}</p>}
      </div>
    </div>
  );
};

export default JobTabsContractor;
