import React, { useEffect, useState } from "react";
import { useUser } from "../context/UserContext";
import AdminEditProfile from './AdminEditProfile.js';
import AgentEditProfile from './AgentEditProfile.js';
import LandlordEditProfile from './LandlordEditProfile.js';
import ContractorEditProfile from './ContractorEditProfile.js';

const ProfileEdit = () => {
  const userX = useUser();
  const [userDetails, setUserDetails] = useState(userX?.user || null);

  useEffect(() => {
    setUserDetails(userX.user);
  }, [userX]);

  if (!userDetails) return null;  
    return (   
      <>
      {userDetails.role === "Admin" && <AdminEditProfile userDetails={userDetails}/>}
      {userDetails.role === "Agent" && <AgentEditProfile userDetails={userDetails}/>}
      {userDetails.role === "Landlord" && <LandlordEditProfile userDetails={userDetails}/>}
      {userDetails.role === "Contractor" && <ContractorEditProfile userDetails={userDetails}/>}
      </>
    );
};

export default ProfileEdit;
