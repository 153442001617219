import React, { useEffect, useState, useCallback } from 'react';
import { fetchJobs } from '../services/jobService';
import GridPanel from '../components/GridPanel';
import { formatDateOnly } from '../Utilities/dateUtils';
import { STATUS_MAPPING } from '../services/dbService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { useUser } from "../context/UserContext";

const LandlordAwaitingContractorAllocation = ({ onRowSelectAction }) => {

    const userX = useUser();  
    const [awaitingContractorJobs, setAwaitingContractorJobs] = useState([]);

    const loadAwaitingContractorJobs = useCallback(async () => {
      const options = { 
        status: 2,
        landlordEmail: userX.user.email,
      };
      const jobs = await fetchJobs(options);
      setAwaitingContractorJobs(jobs);
    }, [userX.user.email]);

    useEffect(() => {
      const handleRefresh = () => {
        loadAwaitingContractorJobs(); 
      };
  
        window.addEventListener("refreshGrids", handleRefresh);
        return () => window.removeEventListener("refreshGrids", handleRefresh);
      }, [loadAwaitingContractorJobs]); 

      useEffect(() => {
        loadAwaitingContractorJobs(); 
      }, [loadAwaitingContractorJobs]);


  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.8 },
    { headerName: "TRADE", 
      field: "tradeName",
      flex: 1.2,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)' 
          ? params.data.instructionNotes || 'N/A' 
          : params.data.tradeName;
      }
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 1.3 },
    { headerName: "QUOTE DATE", field: "createdAt", flex: 0.8,
      valueFormatter: (params) => formatDateOnly(params.value) },
    { headerName: "STATUS", 
        field: "status", 
        flex: 0.9,
        valueFormatter: (params) => {
          return STATUS_MAPPING[String(params.value)]?.label || STATUS_MAPPING.default.label;
        }
    }   
  ];
  
  const handleRowClick = (jobData) => {
    const tabModes = {
      Detail: 'readOnly',
      Quote: 'readOnly',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };
  
    if (jobData.data) {
      onRowSelectAction(jobData.data,tabModes,);
    } else {
      onRowSelectAction(jobData,tabModes,);
    }
  };

  return (
    <GridPanel
      title="Job Accepted Awaiting Contractor Allocation [landlord]" // 2
      rowData={awaitingContractorJobs}
      columnDefs={columnDefs}
      onRowClick={handleRowClick}
    />
  );
};

export default LandlordAwaitingContractorAllocation;
