import React, { useEffect, useState, useCallback } from 'react';
import GridPanel from '../components/GridPanel';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { collection, query, where, orderBy, getDocs, } from 'firebase/firestore';
import { db } from '../firebase';

const JobsToPrice = ({ isSingleColumn, onRowSelectAction }) => {
  const [jobsToPrice, setJobsToPrice] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Quote');

  // 🔹 Function to fetch grid data (kept stable using useCallback)
  const loadJobsToPrice = useCallback(async () => {
    try {
      const jobs = await fetchJobsForAdminToPrice();
      setJobsToPrice(jobs);
    } catch (error) {
      console.error('JobsToPrice: Error fetching jobs to price:', error);
    }
  }, []);

  useEffect(() => {
    const handleRefresh = () => {
      loadJobsToPrice(); 
    };

    window.addEventListener("refreshGrids", handleRefresh);
    return () => window.removeEventListener("refreshGrids", handleRefresh);
  }, [loadJobsToPrice]); 

  useEffect(() => {
    loadJobsToPrice(); 
  }, [loadJobsToPrice]);

  const columnDefsJobsToPrice = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    { 
      headerName: "TRADE",
      field: "tradeName",
      flex: 1.7,
      filter: true,
      cellRenderer: TradeCellRenderer,
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { 
      headerName: "DATE CREATED",
      field: "createdAt",
      flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) 
    }
  ];

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readWrite',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };

    setSelectedTab('Quote');
    onRowSelectAction(jobData, tabModes, selectedTab);
  };

  // Local handlers for panel behavior
  const toggleLayout = () => setIsCollapsed(false);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Jobs To Quote [admin]"
      columnDefs={columnDefsJobsToPrice}
      rowData={jobsToPrice}
      height={panelHeight}
      onResize={handleResize}
      isSingleColumn={isSingleColumn}
      toggleLayout={toggleLayout}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick}
    />
  );
};

export default JobsToPrice;

/**
 * Fetches jobs for admins to price (status = 0), only if no contractor quotes exist yet.
 *
 * @returns {Promise<Array>} - Jobs needing admin quotes.
 */
export const fetchJobsForAdminToPrice = async () => {
  const jobsCollection = collection(db, "jobs");

  try {
    const jobQueryRef = query(
      jobsCollection,
      where("status", "==", 0),
      orderBy("createdAt")
    );
    const jobSnapshot = await getDocs(jobQueryRef);

    const jobs = [];

    for (const jobDoc of jobSnapshot.docs) {
      const jobId = jobDoc.id;
      const jobData = { jobId, ...jobDoc.data() };

      // 🔎 Check if any quote for this job has contractorQuoteIds
      const quotesRef = collection(db, "quotes");
      const quotesQuery = query(quotesRef, where("jobId", "==", jobId));
      const quotesSnapshot = await getDocs(quotesQuery);

      const hasContractorQuotes = quotesSnapshot.docs.some(q => {
        const data = q.data();
        return Array.isArray(data.contractorQuoteIds) && data.contractorQuoteIds.length > 0;
      });

      if (!hasContractorQuotes) {
        jobs.push(jobData);
      }
    }

    return jobs;
  } catch (error) {
    console.error("Error fetching jobs for admin:", error);
    throw error;
  }
};

